import React, { useState, useEffect } from "react";
import "../css/AllTickets.css";
import Header from "./Header";
import ic_ticket from "../img/ic_ticket.png";
import axios from "axios";

function AllTickets() {

    const [cubixTaskTicketStaffNameArrayState, setCubixTaskTicketStaffNameArray] = useState(null);
    const [cubixTaskTicketCompanyNameArrayState, setCubixTaskTicketCompanyNameArray] = useState(null);
    const [cubixTaskTicketTicketTypeArrayState, setCubixTaskTicketTicketTypeArray] = useState(null);
    const [closedTicketArrayState, setClosedTicketArrayState] = useState(null);

    const getAllClosedTickets = () => {
        axios.get(`${process.env.REACT_APP_BASE_URL}/get-all-closed-ticket.php`).
            then((res) => {
                console.log(" all closed tickets res is ", res);
                if (res.data.status == "get_closed_tickets_success") {
                    setClosedTicketArrayState(res.data.closed_tickets_array);
                }
            }).
            catch((err) => {
                console.log("err is ", err);
            })
    }

    const getFromLocalStorageStaffArray = () => {
        let cubixTaskTicketStaffNameArray = sessionStorage.getItem("cubix_task_ticket_staff_name_array");
        setCubixTaskTicketStaffNameArray(JSON.parse(cubixTaskTicketStaffNameArray))
    }

    const getFromLocalStorageCompanyNameArray = () => {
        let cubixTaskTicketCompanyNameArray = sessionStorage.getItem("cubix_task_ticket_company_name_array");
        setCubixTaskTicketCompanyNameArray(JSON.parse(cubixTaskTicketCompanyNameArray))
    }

    const getFromLocalStorageTicketTypeArray = () => {
        let cubixTaskTicketTicketTypeArray = sessionStorage.getItem("cubix_task_ticket_type_of_ticket_array");
        setCubixTaskTicketTicketTypeArray(JSON.parse(cubixTaskTicketTicketTypeArray))
    }

    useEffect(() => {

        getAllClosedTickets();
        getFromLocalStorageStaffArray();
        getFromLocalStorageCompanyNameArray();
        getFromLocalStorageTicketTypeArray();

    }, [])

    return (
        <div className="AllTickets-root-container">
            <Header />
            <div className="AllTickets-root-container-top-panel">
                <img src={ic_ticket} />
                <h4 className="mt-2">Closed Tickets</h4>
            </div>


            <a href="/admindashboard/allticketsfromcustomers" className="AllTickets-closed-tickets-from-customer ms-4">
                Closed Tickets From Customer
            </a>

            <div className="AllTickets-closed-container">

                <div className="AllTickets-right-child-list-container">

                    {/* single item */}
                    {closedTicketArrayState != null ?
                        closedTicketArrayState.length > 0 ?
                            closedTicketArrayState.map((item) => {

                                return (

                                    <div className="AllTickets-right-child-list-single-item mt-2">

                                        <div className="AllTickets-right-child-list-single-item-date-mark-container">
                                            <label className="AllTickets-label-bg">
                                                Ticket {item.id}
                                            </label>
                                        </div>

                                        <div className="AllTickets-right-child-list-single-item-full-description-container">

                                            <div>
                                                <label>Ticket Description</label>
                                                <p>{item.ticket_description}</p>
                                            </div>

                                            <label>
                                                Company name
                                            </label>
                                            <p>
                                                {
                                                    item.company_full_name.length > 0 ?

                                                        item.company_full_name

                                                        :

                                                        cubixTaskTicketCompanyNameArrayState != null ?
                                                            cubixTaskTicketCompanyNameArrayState.length > 0 ?
                                                                cubixTaskTicketCompanyNameArrayState.filter((array_item) => {
                                                                    return (array_item.id == item.company_name);
                                                                }).length > 0 ?
                                                                    cubixTaskTicketCompanyNameArrayState.filter((array_item) => {
                                                                        return (array_item.id == item.company_name);
                                                                    })[0].company_name
                                                                    :
                                                                    "--xx--"
                                                                :
                                                                "-----"
                                                            :
                                                            "--"
                                                }
                                            </p>
                                            <label>
                                                Remarks
                                            </label>
                                            <p>
                                                {item.ticket_remarks.length > 0 ? item.ticket_remarks : "--xx--"}
                                            </p>

                                            <label>
                                                Ticket Type
                                            </label>
                                            <p>
                                                {
                                                    cubixTaskTicketTicketTypeArrayState != null ?
                                                        cubixTaskTicketTicketTypeArrayState.length > 0 ?
                                                            cubixTaskTicketTicketTypeArrayState.filter((array_item) => {
                                                                return (array_item.id == item.ticket_type);
                                                            }).length > 0 ?
                                                                cubixTaskTicketTicketTypeArrayState.filter((array_item) => {
                                                                    return (array_item.id == item.ticket_type);
                                                                })[0].ticket_type_name
                                                                :
                                                                "--xx--"
                                                            :
                                                            "-----"
                                                        :
                                                        "--"
                                                }
                                            </p>

                                            <div className="AllTickets-right-child-list-single-assign-details">
                                                <div>
                                                    <label>
                                                        Assigned To
                                                    </label>
                                                    <p>
                                                        {
                                                            cubixTaskTicketStaffNameArrayState != null ?
                                                                cubixTaskTicketStaffNameArrayState.length > 0 ?
                                                                    cubixTaskTicketStaffNameArrayState.filter((array_item) => {
                                                                        return (array_item.id == item.assign_to);
                                                                    }).length > 0 ?
                                                                        cubixTaskTicketStaffNameArrayState.filter((array_item) => {
                                                                            return (array_item.id == item.assign_to);
                                                                        })[0].staff_name
                                                                        :
                                                                        "ALL"
                                                                    :
                                                                    "-----"
                                                                :
                                                                "--"
                                                        }
                                                    </p>
                                                </div>
                                                <div>
                                                    <label>
                                                        Assigned By
                                                    </label>
                                                    <p>
                                                        {
                                                            cubixTaskTicketStaffNameArrayState != null ?
                                                                cubixTaskTicketStaffNameArrayState.length > 0 ?
                                                                    cubixTaskTicketStaffNameArrayState.filter((array_item) => {
                                                                        return (array_item.id == item.assigned_by);
                                                                    }).length > 0 ?
                                                                        cubixTaskTicketStaffNameArrayState.filter((array_item) => {
                                                                            return (array_item.id == item.assigned_by);
                                                                        })[0].staff_name
                                                                        :
                                                                        "--xx--"
                                                                    :
                                                                    "-----"
                                                                :
                                                                "--"
                                                        }
                                                    </p>
                                                </div>
                                                <div>
                                                    <label>
                                                        Assigned On
                                                    </label>
                                                    <p>
                                                        {new Date(item.ticket_created_on).getDate() +
                                                            " / " + (new Date(item.ticket_created_on).getMonth() + 1) +
                                                            " / " + (new Date(item.ticket_created_on).getFullYear())
                                                        }
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="AllTickets-right-child-list-single-time-details">

                                                <div>
                                                    <label>
                                                        Ticket Status Changed On
                                                    </label>
                                                    <p>

                                                        {new Date(item.ticket_status_changed_on).getDate() +
                                                            " / " + (new Date(item.ticket_status_changed_on).getMonth() + 1) +
                                                            " / " + (new Date(item.ticket_status_changed_on).getFullYear())
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                            <div>
                                                <label>
                                                    Ticket Status Changed by
                                                </label>
                                                <p>
                                                    {
                                                        cubixTaskTicketStaffNameArrayState != null ?
                                                            cubixTaskTicketStaffNameArrayState.length > 0 ?
                                                                cubixTaskTicketStaffNameArrayState.filter((array_item) => {
                                                                    return (array_item.id == item.ticket_status_changed_by);
                                                                }).length > 0 ?
                                                                    cubixTaskTicketStaffNameArrayState.filter((array_item) => {
                                                                        return (array_item.id == item.ticket_status_changed_by);
                                                                    })[0].staff_name
                                                                    :
                                                                    "--xx--"
                                                                :
                                                                "-----"
                                                            :
                                                            "--"
                                                    }
                                                </p>
                                            </div>


                                        </div>

                                    </div>
                                )

                            })
                            :
                            <label className="ms-4 text-white">No Data to show</label>

                        :
                        null
                    }

                    {/* single item */}

                </div>
            </div>
        </div>
    )
}

export default AllTickets;