import React, { useEffect, useState, useCallback, useRef } from "react";
import "../css/ChatBox.css";
import ic_expand_chat_box from "../img/ic_expand_chat_box.png";
import ic_collapse_chat_box from "../img/ic_collapse_chat_box.png";
import axios from "axios";


function ChatBox(props) {

    let { actionToDo } = props;
    let refAfterLoading = useRef(null);

    const chatBoxBodyRef = useCallback((node) => {
        console.log("node is ", node);
        if (node !== null) {
            refAfterLoading.current = node;
        }
    }, [])

    const inputchatBoxBodyRef = useCallback((node) => {
        console.log("node is ", node);
        if (node !== null) {

            if (actionToDo != null) {
                if (actionToDo == "reply") {
                    node.focus();
                }
            }

        }
    })

    const [loadingScreen, setLoadingScreen] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true);
    const { ticketId, senderType, ticketStatus } = props;

    const [minimizeClicked, setMinimizeClicked] = useState(false);

    const initialObjectToSendToChatApi = {
        typed_message: "",
        sender_type: senderType,
        ticket_id_of_chat: ticketId
    }
    const [objectToSendToChatApi, setObjectToSendToChatApi] = useState(initialObjectToSendToChatApi);

    const [chatMessages, setChatMessages] = useState([]);

    const handleInput = (e) => {

        setObjectToSendToChatApi({ ...objectToSendToChatApi, typed_message: e.target.value })
    }

    const getPreviousChats = () => {

        if (initialLoad) {
            setLoadingScreen(true);
        }
        axios.get(`${process.env.REACT_APP_BASE_URL}/get-previous-chats.php?id=${ticketId}`, objectToSendToChatApi).then((res) => {
            console.log("res is ", res);
            setChatMessages(res.data.previous_chat_array);
            setLoadingScreen(false);
        }).catch((err) => {
            console.log("err is ", err);
            setLoadingScreen(false);
        })


    }

    const sendChatMessageToApi = () => {

        if (objectToSendToChatApi.typed_message.length > 0) {
            axios.post(`${process.env.REACT_APP_BASE_URL}/send-chat.php`, objectToSendToChatApi).then((res) => {
                console.log("res is ", res);
                setObjectToSendToChatApi({ ...objectToSendToChatApi, typed_message: "" })
                getPreviousChats();

            }).catch((err) => {
                console.log("err is ", err);
            })
        }
    }

    const scrollToLatestchat = () => {

        console.log("chatBoxBodyRef is >> ", refAfterLoading)

        if (refAfterLoading != null) {
            if (refAfterLoading.current != null) {

                console.log("chatBoxBodyRef is not >>> null ")

                refAfterLoading.current.scrollTo({
                    top: refAfterLoading.current.getBoundingClientRect().height,
                    left: 0,
                    behavior: "smooth",
                });

                refAfterLoading.current.scrollTo({ left: 0, top: refAfterLoading.current.scrollHeight, behavior: "smooth" });
            }

        } else {
            console.log("chatBoxBodyRef is null ")
        }
    }


    useEffect(() => {

        if (chatMessages != null) {
            if (chatMessages.length > 0) {
                console.log("scrollToLatestchat active ")
                scrollToLatestchat();
            }
        }
    }, [chatMessages])

    useEffect(() => {
        getPreviousChats();
    }, [])

    return (
        <div className="ChatBox-root-container">
            <div className={minimizeClicked ? "ChatBox-minimized-header" : "Chatbox"}>
                <div className="Chatbox-header-container">
                    <label className="Chatbox-header-label" title={`Ticket ${ticketId}`}>Ticket {ticketId}</label>
                    <div className="ChatBox-minimize-maximize-button-container">
                        <button onClick={() => setMinimizeClicked(true)} className="ChatBox-header-image-button ms-4"><img className="ChatBox-header-image" src={ic_collapse_chat_box} /></button>
                        <button onClick={() => setMinimizeClicked(false)} className="ChatBox-header-image-button ms-2"><img className="ChatBox-header-image" src={ic_expand_chat_box} /></button>
                    </div>
                </div>

                <div ref={chatBoxBodyRef} className={minimizeClicked ? "ChatBox-invisible-element" : "Chatbox-body-container"}>

                    {
                        chatMessages != null ?
                            chatMessages.length > 0 ?
                                chatMessages.map((item) => {
                                    return (
                                        <div>
                                            {
                                                item.message_send_by == "support_agent" ?
                                                    <div className="bubble-left">
                                                        <div className="bubble-left-inner-container">{item.chat_message}</div>
                                                    </div> :
                                                    <div className="bubble-right-outer">
                                                        <div className="bubble-right">
                                                            <div className="bubble-right-inner-container">{item.chat_message}</div>
                                                        </div>
                                                    </div>
                                            }

                                        </div>
                                    )
                                })
                                :
                                <p className="p-2">No messages to show</p>
                            :
                            <p className="p-2">Something went wrong... Please check after some time</p>
                    }

                    {
                        loadingScreen &&
                        <div className="ChatBox-loading-screen">
                            <p className="p-2">Loading Previous Messages</p>
                        </div>
                    }

                </div>
                {
                    ticketStatus != 2 ?

                        <div className={minimizeClicked ? "ChatBox-invisible-element" : "ChatBox-reply-container"}>
                            <div className="ChatBox-input-send-button-container">
                                <span className="ChatBox-input-container-left" >
                                    <input ref={inputchatBoxBodyRef} onChange={(e) => handleInput(e)} type="text" placeholder="type message" className="ChatBox-message-typing-input"
                                        value={objectToSendToChatApi.typed_message} />
                                </span>
                                <span className="ChatBox-send-container-right">
                                    <button onClick={sendChatMessageToApi} className="ChatBox-send-button">Send</button>
                                </span>
                            </div>
                        </div>
                        :
                        null

                }


            </div>
        </div >
    )
}

export default ChatBox;