import React from "react";
import Header from "./Header";
import "../css/AnalyticsPageAdmin.css";
import { useState } from "react";
import ic_bar_chart_disabled from "../img/ic_bar_chart_disabled.png";
import ic_bar_chart_enabled from "../img/ic_bar_chart_enabled.png";
import svg_inside_rect_dots from "../img/svg_rect_dots.svg";



function AnalyticsPageAdmin() {

    let default_bar_length_multiplier = 5;
    let default_bar_length = 100 * default_bar_length_multiplier;
    let default_bar_step_unit = 0.1 * default_bar_length;

    const [showSplittedBarContainer, setShowSplittedBarContainer] = useState(false);
    const [ticketListArray, setTicketListArray] = useState([
        {
            company_name: "International Diesel Services",
            open_ticket_count: 12,
            assigned_ticket_count: 24,
            escalated_ticket_count: 14,
            closed_ticket_count: 45,
            total_ticket_count: 95
        },
        {
            company_name: "Premier Auto Parts",
            open_ticket_count: 10,
            assigned_ticket_count: 10,
            escalated_ticket_count: 10,
            closed_ticket_count: 40,
            total_ticket_count: 70
        },
        , {
            company_name: "Al Rahb Auto Parts",
            open_ticket_count: 20,
            assigned_ticket_count: 20,
            escalated_ticket_count: 20,
            closed_ticket_count: 40,
            total_ticket_count: 100
        },

        {
            company_name: "Romana Auto Parts",
            open_ticket_count: 30,
            assigned_ticket_count: 30,
            escalated_ticket_count: 30,
            closed_ticket_count: 20,
            total_ticket_count: 110
        },

    ]);

    return (
        <div className="AnalyticsPageAdmin-root-container">

            <Header />

            <div className="AnalyticsPageAdmin-inner-container">

                <div className="AnalyticsPageAdmin-inner-heading-container">
                    <h4>Analytics</h4>
                    {
                        showSplittedBarContainer ?
                            <span className="AnalyticsPageAdmin-inner-heading-splitview-container splitview-enabled" onClick={() => setShowSplittedBarContainer(prev => !prev)}><img src={ic_bar_chart_enabled} className="me-2" />Split View</span>
                            :
                            <span className="AnalyticsPageAdmin-inner-heading-splitview-container" onClick={() => setShowSplittedBarContainer(prev => !prev)}><img src={ic_bar_chart_disabled} className="me-2" />Split View</span>
                    }

                </div>


                <div className="AnalyticsPageAdmin-chart-box-step-unit-container-top">
                    <label>{default_bar_step_unit * 0}</label>
                    <label>{default_bar_step_unit * 1}</label>
                    <label>{default_bar_step_unit * 2}</label>
                    <label>{default_bar_step_unit * 3}</label>
                    <label>{default_bar_step_unit * 4}</label>
                    <label>{default_bar_step_unit * 5}</label>
                    <label>{default_bar_step_unit * 6}</label>
                    <label>{default_bar_step_unit * 7}</label>
                    <label>{default_bar_step_unit * 8}</label>
                    <label>{default_bar_step_unit * 9}</label>
                    <label>{default_bar_step_unit * 10}</label>
                </div>

                <div className="AnalyticsPageAdmin-chart-box">


                    <div className="AnalyticsPageAdmin-chart-box-company-list-container">
                        {ticketListArray.map((item) => {
                            return (
                                <div className="AnalyticsPageAdmin-chart-box-single-row">
                                    <div className="AnalyticsPageAdmin-company-name">
                                        <label className="AnalyticsPageAdmin-company-name-chip">
                                            {item.company_name}
                                        </label>
                                    </div>

                                    <div className="AnalyticsPageAdmin-company-ticket-bar-count-container">
                                        <svg class="chart" width="100%" height={showSplittedBarContainer ? "100" : "20"} aria-labelledby="title desc" role="img">



                                            <g className="AnalyticsPageAdmin-chart-box-bar-container">

                                                {
                                                    showSplittedBarContainer &&

                                                    <g className={showSplittedBarContainer ? "AnalyticsPageAdmin-splitted-bar-container" : "AnalyticsPageAdmin-splitted-bar-container-hidden"}>

                                                        <g class="bar" >
                                                            <defs>
                                                                <pattern id="myPattern">
                                                                    <path stroke="black" />
                                                                </pattern>
                                                            </defs>

                                                            <title>opened tickets {item.open_ticket_count}</title>
                                                            <rect fill="url(#myPattern)"  width={`${(item.open_ticket_count / default_bar_length_multiplier)}%`} height="19" >

                                                            </rect>
                                                            <text x={`${(item.open_ticket_count / default_bar_length_multiplier)}%`} y="9.5" dx=".5em" dy=".35em" >{item.open_ticket_count}</text>
                                                        </g>
                                                        <g class="bar">
                                                            <title>assigned tickets {item.assigned_ticket_count}</title>
                                                            <rect width={`${(item.assigned_ticket_count / default_bar_length_multiplier)}%`} height="19" y="20"></rect>
                                                            <text x={`${(item.assigned_ticket_count / default_bar_length_multiplier)}%`} y="28" dx=".5em" dy=".35em">{item.assigned_ticket_count} </text>
                                                        </g>
                                                        <g class="bar">
                                                            <title>escalated tickets {item.escalated_ticket_count}</title>
                                                            <rect width={`${(item.escalated_ticket_count / default_bar_length_multiplier)}%`} height="19" y="40"></rect>
                                                            <text x={`${(item.escalated_ticket_count / default_bar_length_multiplier)}%`} y="48" dx=".5em" dy=".35em">{item.escalated_ticket_count}</text>
                                                        </g>
                                                        <g class="bar">
                                                            <title>completed tickets {item.closed_ticket_count}</title>
                                                            <rect width={`${(item.closed_ticket_count / default_bar_length_multiplier)}%`} height="19" y="60"></rect>

                                                            <text x={`${(item.closed_ticket_count / default_bar_length_multiplier)}%`} y="68" dx=".5em" dy=".35em">{item.closed_ticket_count}</text>
                                                        </g>

                                                    </g>
                                                }

                                                <g class="bar">
                                                    <title>total tickets {item.total_ticket_count}</title>
                                                    <rect width={`${(item.total_ticket_count / default_bar_length_multiplier)}%`} height="19" y={showSplittedBarContainer ? "80" : "0"}
                                                        onClick={() => setShowSplittedBarContainer(prev => !prev)}></rect>
                                                    <text x={`${(item.total_ticket_count / default_bar_length_multiplier)}%`} y={showSplittedBarContainer ? "88" : "8"} dx=".5em" dy=".35em">{item.total_ticket_count}</text>
                                                </g>

                                            </g>

                                        </svg>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    <div className="AnalyticsPageAdmin-footer">
                        <div className="AnalyticsPageAdmin-chart-box-graph-line-container">
                            <svg className="AnalyticsPageAdmin-chart-box-graph-line-svg" width="100%" height="100%">
                                <g>
                                    <line x1="10%" y1="0" x2="10%" y2="100%" stroke="#c1c1c1" stroke-width="1" stroke-dasharray="5,5,5,5" />
                                </g>
                                <g>
                                    <line x1="20%" y1="0" x2="20%" y2="100%" stroke="#c1c1c1" stroke-width="1" stroke-dasharray="5,5,5,5" />
                                </g>
                                <g>
                                    <line x1="30%" y1="0" x2="30%" y2="100%" stroke="#c1c1c1" stroke-width="1" stroke-dasharray="5,5,5,5" />
                                </g>
                                <g>
                                    <line x1="40%" y1="0" x2="40%" y2="100%" stroke="#c1c1c1" stroke-width="1" stroke-dasharray="5,5,5,5" />
                                </g>
                                <g>
                                    <line x1="50%" y1="0" x2="50%" y2="100%" stroke="#c1c1c1" stroke-width="1" stroke-dasharray="5,5,5,5" />
                                </g>
                                <g>
                                    <line x1="60%" y1="0" x2="60%" y2="100%" stroke="#c1c1c1" stroke-width="1" stroke-dasharray="5,5,5,5" />
                                </g>
                                <g>
                                    <line x1="70%" y1="0" x2="70%" y2="100%" stroke="#c1c1c1" stroke-width="1" stroke-dasharray="5,5,5,5" />
                                </g>
                                <g>
                                    <line x1="80%" y1="0" x2="80%" y2="100%" stroke="#c1c1c1" stroke-width="1" stroke-dasharray="5,5,5,5" />
                                </g>
                                <g>
                                    <line x1="90%" y1="0" x2="90%" y2="100%" stroke="#c1c1c1" stroke-width="1" stroke-dasharray="5,5,5,5" />
                                </g>
                                <g>
                                    <line x1="100%" y1="0" x2="100%" y2="100%" stroke="#c1c1c1" stroke-width="1" stroke-dasharray="5,5,5,5" />
                                </g>

                            </svg>
                        </div>


                    </div>

                    <div className="AnalyticsPageAdmin-chart-box-step-unit-container mt-4">
                        <label>{default_bar_step_unit * 0}</label>
                        <label>{default_bar_step_unit * 1}</label>
                        <label>{default_bar_step_unit * 2}</label>
                        <label>{default_bar_step_unit * 3}</label>
                        <label>{default_bar_step_unit * 4}</label>
                        <label>{default_bar_step_unit * 5}</label>
                        <label>{default_bar_step_unit * 6}</label>
                        <label>{default_bar_step_unit * 7}</label>
                        <label>{default_bar_step_unit * 8}</label>
                        <label>{default_bar_step_unit * 9}</label>
                        <label>{default_bar_step_unit * 10}</label>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default AnalyticsPageAdmin;