import React from "react";

function formatDateHyphen(dateValueReceived){

    const getMonth2Digits = (date) => {

        // 👇️ Add 1, because getMonth is 0-11
         const month = date.getMonth() + 1;
    
        if (month < 10) {
          return '0' + month;
        }
    
        return month;
      }
    
      const getDay2Digits = (date) => {
        const day = date.getDate();
    
        if (day < 10) {
          return '0' + day;
        }
    
        return day;
      }
    
      const getCurrentDate = (dateFromDb) => {
    
        if (dateFromDb != null) {
    
          let today = new Date(dateFromDb);
          let date =  today.getFullYear() + '-' + getMonth2Digits(today) +'-' +  getDay2Digits(today)  ;
          console.log("getCurrentDate is ", date);
          return date;
        }
      }

      return getCurrentDate(dateValueReceived);

}

export default formatDateHyphen;

