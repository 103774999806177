import React, { useEffect, useState , useRef} from "react";
import {useSearchParams, useNavigate} from "react-router-dom";
import logo_cubix from "../img/logo_cubix.png";
import ic_admin_icon from "../img/ic_admin_icon.png";
import ic_logout from "../img/ic_person_logout.png";
import "../css/AdminCompanyReport.css";
import axios from "axios";

function AdminCompanyReport(){
   
    const base_url = "http://185.140.249.224:3001";
   //const base_url = "http://localhost:3001";

   const initialInput = {input_original_amc_date:"" , input_amc_invoice_date:"", input_amc_amount:""};
   const [inputCompanyDetails, setInputCompanyDetails] = useState(initialInput);
   const[dd, setDD] = useState(null);
   const[ddInvoice, setDDInvoice] = useState(null);
   const[amcAmount, setAmcAmount] = useState(null);
   const[totalNumberOfTickets, settoTalNumberOfTickets] = useState(null);
   const[pendingNumberOfTickets, setPendingNumberOfTickets] = useState(null);
   const[openedNumberOfTickets, setOpenedNumberOfTickets] = useState(null);
   const[closedNumberOfTickets, setClosedNumberOfTickets] = useState(null);
   const[notInAmcNumberOfTickets, setNotInAmcNumberOfTickets] = useState(null);
   const[companyName, setCompanyName] = useState(null);

   const navigate = useNavigate();

   const [searchParams] = useSearchParams(); // Unpacking and retrieve id

   console.log( "hello id " + searchParams.get('company_id') );

   let companyId = searchParams.get('company_id');

   const logOutUser = ()=>{
    // console.log("log out admin clicked ");
    localStorage.setItem("cubix_admin_isAuthenticated", false);
    navigate("/admin")
  }

  const saveCompanyDetails = ()=>{
    console.log("input original date",inputCompanyDetails.input_original_amc_date );
    console.log("input amc invoice date",inputCompanyDetails.input_amc_invoice_date );
    console.log("input amc amount ",inputCompanyDetails.input_amc_amount );

    axios.post(`${base_url}/save-company-details`, {companyDetails:inputCompanyDetails, companyId: companyId}).
    then((res)=>{
            console.log("res is ", res);
            window.location.reload(false);
    }).catch((err)=> console.log("error is ", err))
  }

  const handleInput = (event)=>{
    setInputCompanyDetails({...inputCompanyDetails, [event.target.name]:event.target.value})
  }

  useEffect(()=>{
    axios.post(`${base_url}/get-company-report`,{companyId:companyId}).then((res)=>{
        console.log("res of company report",res);
        setDD( new Date(res.data.details[0].original_amc_date));
        setDDInvoice(  new Date(res.data.details[0].amc_invoice_date))
        setAmcAmount(res.data.details[0].amc_amount)

        //
      
        let ddOriginalInputField = new Date(res.data.details[0].original_amc_date);
       // setInputCompanyDetails({...inputCompanyDetails, input_original_amc_date:ddOriginalInputField.getFullYear() +"-"+  (ddOriginalInputField.getMonth() + 1) +"-" + ddOriginalInputField.getDate()})

        let ddInvoiceInputField = new Date(res.data.details[0].amc_invoice_date);
        //setInputCompanyDetails({...inputCompanyDetails, input_amc_invoice_date:ddInvoiceInputField.getFullYear() +"-"+  (ddInvoiceInputField.getMonth() + 1) +"-" + ddInvoiceInputField.getDate()})
        
        
        
        let tempObjectForInputCompanyDetails = {input_original_amc_date:ddOriginalInputField.getFullYear() +"-"+  (ddOriginalInputField.getMonth() + 1) +"-" + ddOriginalInputField.getDate(),
                                                input_amc_invoice_date:ddInvoiceInputField.getFullYear() +"-"+  (ddInvoiceInputField.getMonth() + 1) +"-" + ddInvoiceInputField.getDate(),
                                                input_amc_amount:res.data.details[0].amc_amount
                                            }
        setInputCompanyDetails(tempObjectForInputCompanyDetails)
        //

        settoTalNumberOfTickets(res.data.countOfTotalTickets[0].row_count_of_tickets)
        setPendingNumberOfTickets(res.data.pendingTicketCount[0].count_of_pending_tickets)
        setOpenedNumberOfTickets(res.data.openedTicketCount[0].count_of_opened_tickets)
        setClosedNumberOfTickets(res.data.closedTicketCount[0].count_of_closed_tickets)
        setNotInAmcNumberOfTickets(res.data.notInAmcTicketCount[0].count_of_not_in_amc_tickets)
        setCompanyName(res.data.details[0].company_name)

    }).catch((err)=> console.log("error while taking company report"));
  },[])

    return(
        
        <div className="AdminCompanyReport-root-container">

           <div className="AdminHome-logo-container">
                <div>
                    <a href="/admindashboard" >
                            <img src={logo_cubix} className="AdminHome_company_logo" />
                    </a>
                    <img src={ic_admin_icon} className="ms-4 AdminHome_admin_logo"/>
                    <label className="ms-2">Admin</label>
                </div>

                <div className="me-4 AdminHome-logout-container" onClick={logOutUser}>
                    <img src={ic_logout} className="me-2"/> <span>Logout</span>
                </div>
          </div>
          
          <div className="AdminCompanyReport-body-container">
            <div className="AdminCompanyReport-body-summary-container mt-4">
                <div className="AdminCompanyReport-body-summary-container-child">
                    <label>Company Name</label> 
                    <div className="AdminCompanyReport-body-container-value">{companyName}</div>
                </div>
                <div className="AdminCompanyReport-body-summary-container-child">
                    <label>Total Tickets</label> 
                    <div className="AdminCompanyReport-body-container-value">{totalNumberOfTickets}</div>
                </div>
                <div className="AdminCompanyReport-body-summary-container-child">
                    <label>Pending Tickets</label> 
                    <div className="AdminCompanyReport-body-container-value">{pendingNumberOfTickets}</div>
                </div>
                <div className="AdminCompanyReport-body-summary-container-child">
                    <label>Open Tickets</label> 
                    <div className="AdminCompanyReport-body-container-value">{openedNumberOfTickets}</div>
                </div>
                <div className="AdminCompanyReport-body-summary-container-child">
                    <label>Closed Tickets</label> 
                    <div className="AdminCompanyReport-body-container-value">{closedNumberOfTickets}</div>
                </div>
                <div className="AdminCompanyReport-body-summary-container-child">
                    <label>Not in AMC Tickets</label> 
                    <div className="AdminCompanyReport-body-container-value">{notInAmcNumberOfTickets}</div>
                </div>
             </div>
             <div className="AdminCompanyReport-body-amc-container mt-4">
                 <div>
                    <label>AMC Amount</label>
                    <div className="AdminCompanyReport-body-container-value">{amcAmount}</div>
                 </div>
                 <div>
                    <label>Original AMC date</label>
                    <div className="AdminCompanyReport-body-container-value">
                        {dd != null ? <span>  <p>{dd.getDate()} / {dd.getMonth() + 1} / {dd.getFullYear()}  </p></span> : null }
                    
                    </div>
                 </div>
                 <div>
                    <label>AMC invoice date</label>
                    <div className="AdminCompanyReport-body-container-value">
                        {ddInvoice != null ? <span>  <p>{ddInvoice.getDate()} / {ddInvoice.getMonth() + 1} / {ddInvoice.getFullYear()}  </p></span> : null }
                    </div>
                 </div>
             </div>

             <div className="AdminCompanyReport-enter-details">
                 <div className="mt-4">
                    <label>Enter Original AMC Date</label>
                    <input type="date" name="input_original_amc_date" value={inputCompanyDetails.input_original_amc_date != null ? inputCompanyDetails.input_original_amc_date : ""} onChange={(event)=>{handleInput(event)}}  className="ms-4"></input>
                </div>
                <div className="mt-2">
                    <label>Enter AMC Invoice Date</label>
                    <input type="date" name="input_amc_invoice_date" value={inputCompanyDetails.input_amc_invoice_date}  onChange={(event)=>{handleInput(event)}} className="ms-4"></input>
                </div>
                <div className="mt-2">
                    <label>Enter AMC Amount</label>
                    <input type="text" name="input_amc_amount" value={inputCompanyDetails.input_amc_amount} onChange={(event)=>{handleInput(event)}} className="ms-4"></input>
                </div>
                <div>
                    <button className="mt-4" onClick={saveCompanyDetails}>Save</button>
                </div>
             </div>
            
          </div>
        </div>
    )

}

export default AdminCompanyReport;