import React, { useEffect, useState } from "react";
import "../css/CompletionPopup.css";
import ic_daily_task from "../img/ic_daily_task.png";
import ic_ticket from "../img/ic_ticket.png";
import axios from "axios";
import LoadingLayout from "./LoadingLayout";
import ic_time_taken from "../img/ic_time_taken.png";

function CompletionPopup(props) {

    const [showLoadingLayout, setShowLoadingLayout] = useState(false);
    const [currentlyClickedStatus, setCurrentlyClickedStatus] = useState(0);
    const [errorMessageState, setErrorMessageState] = useState("");
    const { showHideCompletionPopup,
        showHideCompletionPopupFromCustomer,
        completionDetails,
        ticketFromCustomer } = props;

    // console.log("completionDetailsRef ", completionDetails);

    const [cubixTaskTicketStaffNameArrayState, setCubixTaskTicketStaffNameArray] = useState(null);
    const [showStaffNameDropDown, setShowStaffNameDropDown] = useState(false);

    const initialInputState = {
        input_remarks: "",
        input_staff_name: 0,
        input_id_number: completionDetails.current.completion_number,
        input_completed_on: "",
        input_selected_status: 0,
        input_time_taken_in_minutes: 0,
    }
    const [inputState, setInputState] = useState(initialInputState);

    const clickedOnStatus = (clickedOnStatus, e) => {

        console.log("clickedOnStatus e ", clickedOnStatus, e.target)

        setCurrentlyClickedStatus(clickedOnStatus);
        setInputState({ ...inputState, input_selected_status: clickedOnStatus });

    }
    const getFromLocalStorageStaffArray = () => {
        let cubixTaskTicketStaffNameArray = sessionStorage.getItem("cubix_task_ticket_staff_name_array");
        setCubixTaskTicketStaffNameArray(JSON.parse(cubixTaskTicketStaffNameArray))
    }

    const markTaskAsCompletedApi = () => {

        console.log("inputState ", inputState);

        if (completionDetails.current.completion_type == "task") {

            if (inputState.input_staff_name > 0 && inputState.input_selected_status > 0) {

                setShowLoadingLayout(true);

                axios.post(`${process.env.REACT_APP_BASE_URL}/change-task-status.php`, inputState).
                    then((res) => {
                        console.log(" task completion res is ", res);

                        setShowLoadingLayout(false);

                        if (res.data.status = "task_completion_success") {

                            window.location.reload();
                            showHideCompletionPopup();
                        }
                    }).
                    catch((err) => {

                        setShowLoadingLayout(false);
                        console.log("err is ", err);
                    })
            } else {
                setErrorMessageState("Either task completed or marked as done by not filled")
            }
        }

        if (completionDetails.current.completion_type == "ticket") {

            if (inputState.input_staff_name > 0 && inputState.input_selected_status > 0) {

                axios.post(`${process.env.REACT_APP_BASE_URL}/change-ticket-status-support.php`, inputState).
                    then((res) => {
                        console.log(" ticket completion res is :)))) ", res);
                        if (res.data.status = "ticket_completion_success") {

                            // window.location.reload();
                            showHideCompletionPopup();
                        }
                    }).
                    catch((err) => {
                        console.log("err is ", err);
                    })
            } else {
                setErrorMessageState("Either Status on or Staf name not filled");
            }
        }
    }

    const markTaskAsCompletedApiFromCustomer = () => {

        console.log("inputState markTaskAsCompletedApiFromCustomer", inputState);

        if (inputState.input_staff_name > 0 && inputState.input_selected_status > 0) {

            if (currentlyClickedStatus !== 3) {

                if (inputState.input_time_taken_in_minutes > 0) {
                    axios.post(`${process.env.REACT_APP_BASE_URL}/change-ticket-status-from-customer.php`, inputState).
                        then((res) => {
                            console.log(" ticket completion res is xxxx ", res);
                            if (res.data.status = "ticket_completion_success") {

                                window.location.reload();
                                showHideCompletionPopupFromCustomer();
                            }
                        }).
                        catch((err) => {
                            console.log("err is ", err);
                        })
                } else {
                    setErrorMessageState("Please enter time taken in minutes");
                }


            } else {
                axios.post(`${process.env.REACT_APP_BASE_URL}/change-ticket-status-from-customer.php`, inputState).
                    then((res) => {
                        console.log(" ticket completion res is xxxx ", res);
                        if (res.data.status = "ticket_completion_success") {

                            window.location.reload();
                            showHideCompletionPopupFromCustomer();
                        }
                    }).
                    catch((err) => {
                        console.log("err is ", err);
                    })
            }


        } else {
            setErrorMessageState("Either Status on or Staf name not filled");
        }

    }

    const handleInput = (e) => {
        setInputState({ ...inputState, [e.target.name]: e.target.value });
        setErrorMessageState("")
    }



    useEffect(() => {
        getFromLocalStorageStaffArray();
    })

    return (

        completionDetails != null ?
            <div className="CompletionPopup-root-container">

                {completionDetails.current.completion_type == "task" ?
                    <img className="CompletionPopup-side-clip-image" src={ic_daily_task} />
                    :
                    <img className="CompletionPopup-side-clip-image" src={ic_ticket} />
                }

                <div className="CompletionPopup-inner-card">

                    {completionDetails.current.completion_type == "task" ?
                        <div className="CompletionPopup-top-header">
                            <h6>Change status of this Task</h6>
                            <label>Task number {completionDetails.current.completion_number} </label>
                        </div>
                        :
                        <div className="CompletionPopup-top-header">
                            <h6>Change status of this Ticket {ticketFromCustomer == true ? "From Customer" : ""}</h6>
                            <label>Ticket number {completionDetails.current.completion_number} </label>
                        </div>
                    }


                    {/* <div className="CompletionPopup-task_completed-on mt-2">
                            <label className="mt-2">Completed on</label>
                            <span>
                                <input className="mt-2" type="datetime-local" name="input_completed_on" value={inputState.input_completed_on} onChange={handleInput}></input>
                            </span>
                        </div> */}

                    <div className="CreateTaskPopupContainer-status-container mt-4">
                        <p className="CompletionPopup-select-text">
                            1. Select Status
                        </p>
                        <div className="CreateTaskPopupContainer-status-selection-option">
                            <div className={currentlyClickedStatus === 3 ? " active_status_button" : ""} onClick={(e) => clickedOnStatus(3, e)}>
                                <span></span>
                                <label className="ms-2">Assigned</label>
                            </div>
                            <div className={currentlyClickedStatus === 4 ? "active_status_button" : ""} onClick={(e) => clickedOnStatus(4, e)}>
                                <span></span>
                                <label className="ms-2">Escalated</label>
                            </div>
                            <div className={currentlyClickedStatus === 2 ? "active_status_button" : ""} onClick={(e) => clickedOnStatus(2, e)}>
                                <span></span>
                                <label className="ms-2">Completed</label>
                            </div>
                        </div>
                    </div>

                    <div className="CreateTaskPopupContainer-staff-dropdown-anchorand-list-container ">
                        <label className="mt-4 CompletionPopup-select-text">2. Select Staff Name</label>
                        <div className="CreateTaskPopupContainer-dropdown-picker mt-2" onClick={() => setShowStaffNameDropDown(prev => !prev)} >

                            {
                                cubixTaskTicketStaffNameArrayState != null ?
                                    cubixTaskTicketStaffNameArrayState.length > 0 ?

                                        cubixTaskTicketStaffNameArrayState.filter((item) => {
                                            return (
                                                item.id == inputState.input_staff_name
                                            )
                                        }).length > 0 ?
                                            cubixTaskTicketStaffNameArrayState.filter((item) => {
                                                return (
                                                    item.id == inputState.input_staff_name
                                                )
                                            })[0].staff_name
                                            :
                                            null
                                        :
                                        null
                                    :
                                    null
                            }
                        </div>

                        {showStaffNameDropDown &&
                            <div className="CreateTaskPopupContainer_staff_name_dropdown_item_container">
                                {
                                    cubixTaskTicketStaffNameArrayState != null ?
                                        cubixTaskTicketStaffNameArrayState.length > 0 ?
                                            cubixTaskTicketStaffNameArrayState.map((item) => {
                                                return (
                                                    <span key={item.id} className="CreateTaskPopupContainer_staff_name_dropdown_item" id={item.id} onClick={() => { setErrorMessageState(""); setInputState({ ...inputState, input_staff_name: item.id }); setShowStaffNameDropDown(prev => !prev) }}>{item.staff_name}</span>
                                                )
                                            })
                                            :
                                            null
                                        :
                                        null
                                }

                            </div>
                        }
                    </div>

                    <label className="mt-4">Enter remarks (max 255 letters)</label>
                    <input className="mt-2" type="text" name="input_remarks" onChange={handleInput} />

                    {
                        (currentlyClickedStatus !== 3 && currentlyClickedStatus !== 0) ?
                            <div>
                                <label className="mt-4"><img src={ic_time_taken} /> Enter time taken  (in minutes)</label>
                                <input className="mt-2 ms-1 CompletionPopup-time-input" type="number" name="input_time_taken_in_minutes" onChange={handleInput} />
                            </div>
                            :
                            null
                    }


                    <div className="CreateTaskPopupContainer-error-message-container">
                        <p className="text-danger ">{errorMessageState}</p>
                    </div>

                    <div className="CompletionPopup-cancel-save-container mt-4">
                        <button className="CompletionPopup-cancel-button me-4" onClick={ticketFromCustomer == true ? showHideCompletionPopupFromCustomer : showHideCompletionPopup}>Cancel</button>
                        <button className="CompletionPopup-save-button" onClick={ticketFromCustomer == true ? markTaskAsCompletedApiFromCustomer : markTaskAsCompletedApi}>Save</button>
                    </div>

                </div>

                {
                    showLoadingLayout && <LoadingLayout />
                }

            </div>
            :
            <div className="CompletionPopup-root-container">
                <p className="text-alert">Something went wrong. Please try later</p>
            </div>


    )

}

export default CompletionPopup;