import React, { useEffect, useState } from "react";
import Header from "./Header";
import "../css/Checklist.css";
import ic_checkbox from "../img/ic_check_mark.png";
import ic_normal_checkbox from "../img/ic_normal_checkbox.png";
import axios from "axios";
import { getReadableDate, getReadableTime } from "../components/utils";

function PreviousChecklist() {

    const [selectedRow, setSelectedRow] = useState(null)
    const [showDetailPopup, setShowDetailPopup] = useState(false)
    const [arrayOfNodes, setArrayOfNodes] = useState(null)


    const callAPI = () => {
        axios.get(`${process.env.REACT_APP_BASE_URL}/previous_checklist.php`
        ).then((res) => {
            console.log("res is ", res);
            setArrayOfNodes(res.data.data_array)

        }).catch(err => {

        });
    }

    useEffect(() => {
        callAPI()
    }, [])

    return (
        <div>

            <Header />
            

            <div className="container checklist_table_container">

                <h4 className="mt-2">Previous Checklist</h4>
                <table className="checklist_table">
                    <thead>
                        <tr>
                            <td>Staff</td>
                            <td>Company</td>
                            <td>Created on</td>
                            <td>Action</td>
                        </tr>
                    </thead>
                    {
                        arrayOfNodes != null &&
                        arrayOfNodes.map((item) => {
                            return (
                                <>
                                    <tbody>
                                        <tr className="row-of-staff">

                                            <td>{item.name_of_staff}</td>
                                            <td>{item.name_of_company}</td>
                                            <td>{getReadableDate(item.created_at)} {getReadableTime(item.created_at)}</td>
                                            <td> <button className="btn btn-warning" onClick={() => { setShowDetailPopup(prev => !prev); setSelectedRow(JSON.parse(item.checklist_json_string)) }}>View</button></td>
                                        </tr>
                                    </tbody>
                                </>

                            )
                        })
                    }
                </table>
            </div>


            {

                showDetailPopup &&
                <div className="popup-outer">

                    <div className="popup-inner">

                        <div className="d-flex">
                            <button className="btn btn-dark" onClick={() => setShowDetailPopup(prev => !prev)}>Close</button>
                        </div>

                        <div className="mt-2 ">

                            <table className="w-100">
                                {
                                    selectedRow != null &&
                                    selectedRow.map((staff_checklist) => {
                                        return (
                                            <>
                                                <tbody>
                                                    <tr >
                                                        <td colSpan="3" className="node_name">
                                                            {
                                                                staff_checklist.node_name
                                                            }
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tbody>
                                                    {
                                                        staff_checklist.node_items.map((itemInsideNode) => {
                                                            return (
                                                                <tr className="checklist_table_single_row">
                                                                    <td >
                                                                        {itemInsideNode.checkbox_status ? <img className="checkbox_image_in_previous_list" src={ic_checkbox} /> : <img className="checkbox_image_in_previous_list" src={ic_normal_checkbox} />}
                                                                    </td>
                                                                    <td>
                                                                        {itemInsideNode.name}
                                                                    </td>
                                                                    <td>
                                                                        {itemInsideNode.remarks}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }

                                                </tbody>

                                            </>
                                        )
                                    })
                                }

                            </table>

                        </div>
                    </div>

                </div>
            }



        </div>
    )
}

export default PreviousChecklist;