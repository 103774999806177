import React from "react";
import "../css/SideDrawer.css";
import ic_close from "../img/ic_close.png";
import ic_analytics from "../img/ic_analytics.png";
import ic_staff from "../img/ic_staff.png";
import ic_shutdown from "../img/ic_shutdown.png";

function SideDrawer(props) {

    const {logout, showHideSideDrawer} = props;

    const clickedOnLogout = ()=>{
        logout();
        showHideSideDrawer();
    }

    return (
        <div className="SideDrawer-root-container">
            
            <div className="SideDrawer-inner-container">
                <div className="SideDrawer-inner-container-heading-container p-4">
                    <label>Options</label>
                    <img src={ic_close} onClick={showHideSideDrawer}/>
                </div>
                <div className="SideDrawer-inner-container-menu-container">
                    <div className="SideDrawer-inner-container-menu-child mt-2" onClick={showHideSideDrawer}><a href="/admindashboard"> Home</a></div>
                    <div className="SideDrawer-inner-container-menu-child mt-2" onClick={showHideSideDrawer}><a href="/admindashboard/view_reports"> View Reports</a></div>
                    <div className="SideDrawer-inner-container-menu-child mt-2" onClick={showHideSideDrawer}><a href="/sales_and_marketing"> Sales &amp; Marketing</a></div>
                    <div className="SideDrawer-inner-container-menu-child mt-2" onClick={showHideSideDrawer}><a href="/projects_and_products"> Project &amp; Product Manager</a></div>
                    <div className="SideDrawer-inner-container-menu-child mt-2" onClick={showHideSideDrawer}><a href="/customer-details"> Customer Details</a></div>
                    <div className="SideDrawer-inner-container-menu-child mt-2" onClick={showHideSideDrawer}><a href="/analytics"> Analytics</a></div>
                    <div className="SideDrawer-inner-container-menu-child mt-2" onClick={showHideSideDrawer}><a href="/staff"> Staff</a></div>
                    <div className="SideDrawer-inner-container-menu-child logout-container mt-4" onClick={clickedOnLogout}><img src={ic_shutdown} />Logout</div>

                </div>

            </div>
        </div>
    )

}

export default SideDrawer;