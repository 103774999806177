import React from "react";
import { Navigate } from "react-router-dom";

    function ProtectedRouteAdmin({ children }) {

        const authed = localStorage.getItem("cubix_support_admin_isAuthenticated");

        return JSON.parse(authed) ? children : <Navigate to="/admin_login" />;
    }

  export default ProtectedRouteAdmin;