import "../css/ClientRegistration.css";
import {useNavigate} from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";

import logo_cubix from "../img/logo_cubix.png";
import side_banner from "../img/side_banner_cubix_login.png";
import bottom_circle from "../img/bottom_circle_cubix.png";
import bottom_circle_2 from "../img/bottom_circle_2_cubix.png";
import bottom_circle_3 from "../img/side_dot_panel.png";

function ClientRegistration(){

    const base_url = "http://185.140.249.224:3001";
     //const base_url = "http://localhost:3001";

    const navigate = useNavigate();

    const [errorMsg, setErrorMsg] = useState("");
    const initialInput = {company_name:"", company_email: "", password: ""};
    const [inputUserRegistrationState, setInputUserRegistrationState] = useState(initialInput);

    const handleInput = (event)=>{
        setInputUserRegistrationState({...inputUserRegistrationState, [event.target.name]: event.target.value});
        setErrorMsg("");
    }

    const emailIsValid = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    }
      
    const validateInput = () => {
        if (inputUserRegistrationState.company_name.length > 0 && inputUserRegistrationState.company_email.length > 0 && inputUserRegistrationState.password.length > 0) {
            if(emailIsValid(inputUserRegistrationState.company_email)){
                setErrorMsg("");
                return true;
            }else{
                setErrorMsg("Please enter valid email");
                return false;
            }
        
        } else {

          console.log("username password empty");
          setErrorMsg("Please enter details in all input fields");
          return false;

        }
      };

     const callRegistrationAPI = ()=>{

        if(validateInput()){
            setErrorMsg("");
            console.log("inside callRegistrationAPI inputUserRegistrationState", inputUserRegistrationState);

            axios.post(`${base_url}/client-registration`, inputUserRegistrationState).
            then((res)=>{
                console.log("registration result is ", res);
                if(res.data.isRegistrationSuccess){
                    setErrorMsg("");
                    navigate("/");
                }else{
                    setErrorMsg(res.data.errorMessage);
                }
            }).catch(err => setErrorMsg("Registration Request failed ... Please contact admin " ) );
        }
     }

    return(
        <div className="ClientRegistration-root-container">
            
            <div className="ClientRegistration-left-container">
                <img src={logo_cubix} className="ClientRegistration-cubix-logo"/>
                <img src={side_banner} className="ClientRegistration-side-banner-img" />
            </div>

            <div className="ClientRegistration-top-layer-container">
                <div>
                    <h1 className="mt-0">Cubix Support Center</h1>
                    <p className="text-center">Enter details to create company account.</p>
                </div>
                
                <div>
                   
                    <div className="mt-4 ClientRegistration-form-container">
                        <label>Company Name</label>
                        <input className="mt-2" type="text" name="company_name"  value={inputUserRegistrationState.company_name} onChange={(event)=>{handleInput(event)}}/>
                        <label>Company Email</label>
                        <input className="mt-2" type="email" name="company_email"  value={inputUserRegistrationState.company_email} onChange={(event)=>{handleInput(event)}}/>
                        <label className="mt-2">Password</label>
                        <input className="mt-2" type="password" name="password" value={inputUserRegistrationState.password} onChange={(event)=>{handleInput(event)}}/>
                        <button className="mt-3 btn btn-primary ClientRegistration-login-button " onClick={callRegistrationAPI} >Register</button>
                        <div className="ClientRegistration-registration-error-container">{errorMsg}</div>
                    </div>
                    
                    <div className="ClientRegistration-already-have-an-account">
                        <label>if you already have an account </label>
                        <button onClick={()=> navigate(-1)}>Login</button>
                    </div>
                </div>

                <img className="ClientRegistration-side-dot-panel" src={bottom_circle_3}/>
                <img className="ClientRegistration-top-dot" src={bottom_circle_2}/>
                <img className="ClientRegistration-bottom-dot" src={bottom_circle}/>
                
            </div>
        </div>
    )

}

export default ClientRegistration;