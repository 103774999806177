import React from "react";
import "../css/SideSmallDrawer.css";
import ic_cubix_logo from "../img/logo_cubix.png";
import icsettings from "../img/ic_settings_side_drawer.png";
import ic_round_settings from "../img/ic_settings_round_icon.png";

import ic_home from "../img/ic_home_black.png";
import ic_amc from "../img/ic_amc_black.png";
import ic_sales from "../img/ic_sales_black.png";
import ic_customers from "../img/ic_customers_black.png";
import ic_logout from "../img/ic_logout_black.png";
import ic_analysis from "../img/ic_analysis.png";
import { useNavigate } from "react-router-dom";

function SideSmallDrawer(props) {

    let navigate = useNavigate();

    const logout = () => {
        localStorage.setItem("cubix_support_admin_isAuthenticated", false);
        navigate("/admin_login");
    }

    const clickedOnLogout = () => {
        logout();
    }

    return (
        <div className="SideSmallDrawer-root-container">

            <div className="SideSmallDrawer-option-container">

                <div className="SideSmallDrawer-icon-name-container mt-4 ms-3 me-3">
                    <a href="/admindashboard">
                        <img title="Home" className="small-side-drawer-option-images" src={ic_home} />
                    </a>

                </div>

                <div className="SideSmallDrawer-icon-name-container mt-4 ms-3 me-3">
                    <a href="/admindashboard/amc-details">
                        <img title="AMC" className="small-side-drawer-option-images" src={ic_amc} />
                    </a>

                </div>

                {/* <div className="SideSmallDrawer-icon-name-container mt-4 ms-3 me-3">
                    <a href="/admindashboard/customer-details">
                        <img title="Customers" className="small-side-drawer-option-images" src={ic_customers} />
                    </a>

                </div> */}

                <div className="SideSmallDrawer-icon-name-container mt-4 ms-3 me-3">
                    <a href="/admindashboard/view_reports">
                        <img title="Analysis" className="small-side-drawer-option-images" src={ic_analysis} />
                    </a>

                </div>


                <div className="SideSmallDrawer-icon-name-container mt-4 ms-3 me-3">
                    <a href="/admindashboard/sales_and_marketing">
                        <img title="Sales" className="small-side-drawer-option-images" src={ic_sales} />
                    </a>

                </div>

                {/* <div>Analytics</div> */}
                {/* <div>Staff</div> */}
                {/* <div>Project and Products</div> */}

                <div className="SideSmallDrawer-icon-name-container mt-4 ms-3 me-3">

                    <img onClick={clickedOnLogout} title="Logout" className="small-side-drawer-option-images" src={ic_logout} />

                </div>

            </div>

        </div>
    )
}

export default SideSmallDrawer;