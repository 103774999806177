import React, { useEffect, useState } from "react";
import Header from "./Header";
import ic_projects from "../img/ic_projects.png"
import "../css/ProjectProductManager.css";
import CreateNewProjectProductPopupContainer from "./CreateNewProjectProductPopup";
import axios from "axios";
import LoadingLayout from "./LoadingLayout";
import project_pointer_arrow from "../img/ic_right_project.png";
import ic_checkmark_delete from "../img/ic_checkmark_delete.png";

function ProjectProductManager() {

    const [activityListOfAProjectState, setActivityListOfAProjectState] = useState(null);
    const [errorMessageState, setErrorMessageState] = useState("");
    const [cubixTaskTicketStaffNameArrayState, setCubixTaskTicketStaffNameArray] = useState(null);
    const [showAssignedByDropDown, setShowAssignedByDropDown] = useState(false);
    const [showLoadingLayout, setShowLoadingLayout] = useState(false);
    const initialAddActivityInputState = {
        project_product_id: 0,
        staff_name: 0,
        activity_details: "",
    }
    const [addActivityInputState, setAddActivityInputState] = useState(initialAddActivityInputState);
    const [currentPointerState, setCurrentPointerState] = useState(0);
    const [showCreateTicketPopup, setshowCreateTicketPopup] = useState(false);
    const [projectProductArrayState, setProjectProductArrayState] = useState(null);

    // dummy content
    const showHideCreateNewTicketPopup = () => {
        setshowCreateTicketPopup(prev => !prev)
    }

    // const getAllProjectsProducts = () => {
    //     axios.get(`${process.env.REACT_APP_BASE_URL}/get-all-projects-products.php`).
    //         then((res) => {
    //             console.log(" all closed tickets res is ", res);
    //             if (res.data.status == "get_project_product_success") {
    //                 setProjectProductArrayState(res.data.project_product_array);
    //             }
    //         }).
    //         catch((err) => {
    //             console.log("err is ", err);
    //         })
    // }

    const getAllProjectsProductsPercentage = () => {
        axios.get(`${process.env.REACT_APP_BASE_URL}/get-all-projects-products-percentage.php`).
            then((res) => {
                console.log(" project activity count  ", res);
                if (res.data != null) {
                    setProjectProductArrayState(res.data);
                }

            }).
            catch((err) => {
                console.log("err is ", err);
            })
    }

    const addActivity = () => {

        setShowLoadingLayout(true);
        sendActivityToAPI();

    }

    const sendActivityToAPI = () => {

        if (addActivityInputState.activity_details.length > 0 && addActivityInputState.staff_name > 0) {

            console.log("addActivityInputState ", addActivityInputState);

            axios.post(`${process.env.REACT_APP_BASE_URL}/add-activity-to-timeline.php`, addActivityInputState).
                then((res) => {
                    console.log(" activity creation res is ", res);
                    setShowLoadingLayout(false);
                    if (res.data.status = "creation_success") {

                        setAddActivityInputState({ ...initialAddActivityInputState, project_product_id: addActivityInputState.project_product_id })

                        // append addActivityInputState to the activity list of project project_product_id
                        getActivityListOfAProject(addActivityInputState.project_product_id)
                    }
                }).
                catch((err) => {
                    setShowLoadingLayout(false);
                    console.log("err is ", err);
                })
        } else {
            console.log("addActivityInputState empty ", addActivityInputState);

            setErrorMessageState("Either Description or staff name not filled")
        }
    }

    const getActivityListOfAProject = (projetIdToGetActivityList) => {
        axios.get(`${process.env.REACT_APP_BASE_URL}/get-activity-list-of-a-project.php?project_product_id=${projetIdToGetActivityList}`).
            then((res) => {
                console.log(" get list ", res);
                setShowLoadingLayout(false);
                setActivityListOfAProjectState(res.data.activity_list_array);

            }).
            catch((err) => {
                setShowLoadingLayout(false);
                console.log("err is ", err);
            })

    }

    const handleInputAddactivity = (e) => {
        setErrorMessageState("");

        setAddActivityInputState({ ...addActivityInputState, [e.target.name]: e.target.value });
    }

    const setCurrentProjectValues = (indexValueFromClick) => {
        setCurrentPointerState(indexValueFromClick);
        getActivityListOfAProject(projectProductArrayState[0].project_product_array[indexValueFromClick].id);
    }

    // first time page laoding or whenever data changes, we select first item so index will be 0
    useEffect(() => {
        if (projectProductArrayState != null) {
            if (projectProductArrayState.length > 0) {

                console.log("prPro not null currentPointerState is ", currentPointerState)
                setAddActivityInputState({ ...addActivityInputState, project_product_id: projectProductArrayState[0].project_product_array[currentPointerState].id });

                getActivityListOfAProject(projectProductArrayState[0].project_product_array[currentPointerState].id);
            }
        }

    }, [projectProductArrayState])

    // now whenever user select an item so index will be currentPointerState
    useEffect(() => {
        if (projectProductArrayState != null) {
            if (projectProductArrayState.length > 0) {

                console.log("projectProductArrayState not null currentPointerState is ", currentPointerState)
                setAddActivityInputState({ ...addActivityInputState, project_product_id: projectProductArrayState[0].project_product_array[currentPointerState].id });
            }
        }

    }, [currentPointerState])

    const clickOnActivityListItem = (projectProductIdFromClick, activityIdFromClick, item_current_status) => {

        console.log("projectProductIdFromClick, activityIdFromClick ", projectProductIdFromClick, activityIdFromClick);

        if (item_current_status != "COMPLETED") {

            setShowLoadingLayout(true);

            axios.post(`${process.env.REACT_APP_BASE_URL}/click-activity-of-project.php`, { project_product_id: projectProductIdFromClick, activity_id: activityIdFromClick }).
                then((res) => {
                    console.log(" click result ", res);
                    setShowLoadingLayout(false);

                    if (res.data.status == "activity_updation_success") {
                        setAddActivityInputState({ ...initialAddActivityInputState, project_product_id: projectProductIdFromClick })
                        getActivityListOfAProject(projectProductIdFromClick);
                    }

                }).
                catch((err) => {
                    setShowLoadingLayout(false);
                    console.log("err is ", err);
                })
        }

    }

    const getFromLocalStorageStaffArray = () => {
        let cubixTaskTicketStaffNameArray = sessionStorage.getItem("cubix_task_ticket_staff_name_array");
        setCubixTaskTicketStaffNameArray(JSON.parse(cubixTaskTicketStaffNameArray))
    }


    useEffect(() => {

        getAllProjectsProductsPercentage();
        getFromLocalStorageStaffArray();
    }, [])

    return (
        <div className="ProjectProductManager-root-container">

            <Header />

            <div className="TicketsInHomeComponent-header mt-3">
                <img src={ic_projects} />
                <div className="TicketsInHomeComponent-header-right-section ms-2 me-4">
                    <h4 className="mt-2">Project and Product Manager</h4>
                    <div className="mt-2 TicketsInHomeComponent-menu-cta-container">
                        <button type="button" onClick={showHideCreateNewTicketPopup}>
                            Create New Project / Product
                        </button>

                    </div>
                </div>
            </div>

            <div className="TicketsInHomeComponent-content mt-3">

                <div className="TicketsInHomeComponent-left-sidebar">

                    {/* left sidebar */}
                    {projectProductArrayState != null ?
                        projectProductArrayState.length > 0 ?
                            projectProductArrayState[0].project_product_array.map((item, index) => {

                                return (
                                    <div key={item.id} id={item.id} className="TicketsInHomeComponent-left-sidebar-single-item" onClick={() => setCurrentProjectValues(index)}>

                                        <span className="TicketsInHomeComponent-left-sidebar-single-item-name-bar-container">
                                            <label>
                                                {item.project_product_name}
                                            </label>
                                            <div className="TicketsInHomeComponent-work-complete-percentage-bar-and-value-container">
                                                <div className="TicketsInHomeComponent-work-complete-percentage-bar mt-2">
                                                </div>
                                                <div className="TicketsInHomeComponent-work-complete-percentage-bar-value ">
                                                </div>
                                                <div className="TicketsInHomeComponent-work-complete-percentage-value ">
                                                    <label className="ms-1">
                                                    {
                                                            projectProductArrayState[2].project_activity_completed_count_array.filter((countItemCompleted) => {
                                                                return item.id == countItemCompleted.id;
                                                            })[0].completed_activity
                                                        }
                                                        /
                                                        {
                                                            projectProductArrayState[1].project_activity_count_array.filter((countItem) => {
                                                                return item.id == countItem.id;
                                                            })[0].total_activity
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                        </span>

                                        {
                                            currentPointerState != null ?
                                                projectProductArrayState[0].project_product_array[currentPointerState].id == item.id ?
                                                    <img src={project_pointer_arrow} />
                                                    :
                                                    <img className="invisible-element" src={project_pointer_arrow} />
                                                :
                                                null
                                        }

                                    </div>
                                )

                            })
                            :
                            <label className="ms-4 text-white">No Data to show</label>

                        :
                        null
                    }

                    {/* left sidebar */}
                </div>

                <div className="TicketsInHomeComponent-right-detail">

                    {projectProductArrayState != null ?
                        projectProductArrayState.length > 0 ?

                            currentPointerState != null ?

                                <div className="TicketsInHomeComponent-right-detail-content">

                                    <div className="TicketsInHomeComponent-right-detail-content-label">
                                        Name
                                    </div>

                                    <div className="TicketsInHomeComponent-right-detail-content-values">
                                        {projectProductArrayState[0].project_product_array[currentPointerState].project_product_name}
                                    </div>

                                    <div className="TicketsInHomeComponent-right-detail-content-label mt-4">
                                        Created On
                                    </div>

                                    <div className="TicketsInHomeComponent-right-detail-content-values">

                                        {new Date(projectProductArrayState[0].project_product_array[currentPointerState].created_on).getDate() +
                                            " / " + (new Date(projectProductArrayState[0].project_product_array[currentPointerState].created_on).getMonth() + 1) +
                                            " / " + (new Date(projectProductArrayState[0].project_product_array[currentPointerState].created_on).getFullYear())
                                        }

                                    </div>

                                    <div className="TicketsInHomeComponent-right-detail-content-label mt-4">
                                        Created By
                                    </div>

                                    <div className="TicketsInHomeComponent-right-detail-content-values">

                                        {
                                            cubixTaskTicketStaffNameArrayState != null ?
                                                cubixTaskTicketStaffNameArrayState.length > 0 ?
                                                    cubixTaskTicketStaffNameArrayState.filter((array_item) => {
                                                        return (array_item.id == projectProductArrayState[0].project_product_array[currentPointerState].created_by);
                                                    }).length > 0 ?
                                                        cubixTaskTicketStaffNameArrayState.filter((array_item) => {
                                                            return (array_item.id == projectProductArrayState[0].project_product_array[currentPointerState].created_by);
                                                        })[0].staff_name
                                                        :
                                                        "--xx--"
                                                    :
                                                    "-----"
                                                :
                                                "--"
                                        }
                                    </div>

                                    <div className="TicketsInHomeComponent-right-detail-content-label mt-4">
                                        Details
                                    </div>

                                    <div className="TicketsInHomeComponent-right-detail-content-values">
                                        {projectProductArrayState[0].project_product_array[currentPointerState].details}
                                    </div>

                                    <div className="TicketsInHomeComponent-right-detail-content-label mt-4">
                                        Expected Completion Date
                                    </div>

                                    <div className="TicketsInHomeComponent-right-detail-content-values">

                                        {new Date(projectProductArrayState[0].project_product_array[currentPointerState].expected_completion_date).getDate() +
                                            " / " + (new Date(projectProductArrayState[0].project_product_array[currentPointerState].expected_completion_date).getMonth() + 1) +
                                            " / " + (new Date(projectProductArrayState[0].project_product_array[currentPointerState].expected_completion_date).getFullYear())
                                        }
                                    </div>

                                    <div className="mt-4">

                                        <div className="ProjectProductManager-add-activity-form-container">

                                            <div className="TicketsInHomeComponent-right-detail-content-label mt-4">
                                                Enter activity details
                                            </div>
                                            <div>
                                                <input type="text" name="activity_details" value={addActivityInputState.activity_details} onChange={(e) => handleInputAddactivity(e)} />
                                            </div>

                                            <div className="TicketsInHomeComponent-right-detail-content-label mt-4">
                                                Select Staff
                                            </div>

                                            <div className="FilterPopupContainer-select-staff-name-container">

                                                <div className="CreateTaskPopupContainer-dropdown-picker mt-2" onClick={() => setShowAssignedByDropDown(prev => !prev)} >

                                                    {
                                                        cubixTaskTicketStaffNameArrayState != null ?
                                                            cubixTaskTicketStaffNameArrayState.length > 0 ?
                                                                cubixTaskTicketStaffNameArrayState.filter((item) => {
                                                                    return (
                                                                        item.id == addActivityInputState.staff_name
                                                                    )
                                                                }).length > 0 ?

                                                                    cubixTaskTicketStaffNameArrayState.filter((item) => {
                                                                        return (
                                                                            item.id == addActivityInputState.staff_name
                                                                        )
                                                                    })[0].staff_name
                                                                    :
                                                                    null
                                                                :
                                                                null
                                                            :
                                                            null
                                                    }
                                                </div>

                                                {showAssignedByDropDown &&
                                                    <div className="CreateTaskPopupContainer_staff_name_dropdown_item_container">
                                                        {
                                                            cubixTaskTicketStaffNameArrayState != null ?
                                                                cubixTaskTicketStaffNameArrayState.length > 0 ?
                                                                    cubixTaskTicketStaffNameArrayState.map((item) => {
                                                                        return (
                                                                            <span key={item.id} className="CreateTaskPopupContainer_staff_name_dropdown_item" id={item.id} onClick={() => { setErrorMessageState(""); setAddActivityInputState({ ...addActivityInputState, staff_name: item.id }); setShowAssignedByDropDown(prev => !prev) }}>{item.staff_name}</span>
                                                                        )
                                                                    })
                                                                    :
                                                                    null
                                                                :
                                                                null
                                                        }

                                                    </div>
                                                }
                                            </div>

                                            <button className="mt-2" onClick={() => addActivity()}>Add Activity</button>

                                        </div>

                                    </div>

                                    {/* activity list */}
                                    <div className="ProjectProductManager-activity-list-container mt-3">
                                        <table className="ProjectProductManager-activity-list-container-table">
                                            <thead>
                                                <tr>
                                                    <th>Sl. no.</th>
                                                    <th>Mark As Complete</th>
                                                    <th>Activity Details</th>
                                                    <th>Done By</th>
                                                    <th>Created On</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    activityListOfAProjectState != null ?

                                                        activityListOfAProjectState.map((item, index) => {
                                                            return (
                                                                <tr>
                                                                    <td>{activityListOfAProjectState.length - index}</td>
                                                                    <td>
                                                                        <div>
                                                                            <span className={

                                                                                item.current_status == "COMPLETED" ?
                                                                                    "HomePageAdmin-select-panel-to-delete active_status_button"
                                                                                    :
                                                                                    "HomePageAdmin-select-panel-to-delete"

                                                                            } onClick={(e) => clickOnActivityListItem(item.project_product_id, item.id, item.current_status)}>
                                                                                <span className=""><img src={ic_checkmark_delete} className={

                                                                                    item.current_status == "COMPLETED" ?
                                                                                        "show-element"
                                                                                        :
                                                                                        "hide-element"

                                                                                } /></span>
                                                                            </span>
                                                                        </div>

                                                                    </td>
                                                                    <td>{item.activity_details}</td>
                                                                    <td>{


                                                                        cubixTaskTicketStaffNameArrayState != null ?
                                                                            cubixTaskTicketStaffNameArrayState.length > 0 ?
                                                                                cubixTaskTicketStaffNameArrayState.filter((array_item) => {
                                                                                    return (array_item.id == item.staff_name);
                                                                                }).length > 0 ?
                                                                                    cubixTaskTicketStaffNameArrayState.filter((array_item) => {
                                                                                        return (array_item.id == item.staff_name);
                                                                                    })[0].staff_name
                                                                                    :
                                                                                    "--xx--"
                                                                                :
                                                                                "-----"
                                                                            :
                                                                            "--"


                                                                    }</td>
                                                                    <td>
                                                                        {new Date(item.created_on).getDate() +
                                                                            " / " + (new Date(item.created_on).getMonth() + 1) +
                                                                            " / " + (new Date(item.created_on).getFullYear())
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })

                                                        :
                                                        "--xx--"
                                                }
                                            </tbody>
                                        </table>
                                    </div>

                                </div>

                                :
                                "--"

                            : "--xx--"
                        : "--"
                    }

                </div>

            </div>


            {
                showCreateTicketPopup &&
                <CreateNewProjectProductPopupContainer showHideCreateNewTicketPopup={showHideCreateNewTicketPopup} />
            }

            {
                showLoadingLayout && <LoadingLayout />
            }

        </div>
    )
}

export default ProjectProductManager;