import React, {useState, useRef, useEffect} from "react";
import axios from "axios";
import "../css/CustomerCreateTickets.css";
import ic_check_mark from "../img/ic_check_mark.png"; 

function CustomerCreateTickets(){

    
    const base_url = "http://185.140.249.224:3001";
     //const base_url = "http://localhost:3001";


    // related to input form validation
    const [isAllRequiredInputAreFilled, setIsAllRequiredInputAreFilled] = useState(false);
    const initialInputError = {
        person_name: "",
        person_email: "",
        person_mobile:"",
        issue:"",
    }
    
    const[initialInputErrorState, setInitialInputErrorState] = useState(initialInputError)

    const firstFileRef = useRef(null);
    //const secondFileRef = useRef(null);

    const [file, setFile] = useState();
    // const [fileTwo, setFileTwo] = useState(null);
    const idOfClient =  localStorage.getItem("cubix_support_idOfClient") ;
    const [errorMsg, setErrorMsg] = useState("");
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const initialInput = { person_name: "", person_email: "",   person_mobile:"", issue:"", idOfClient: idOfClient, ticket_status:1 , file: file};
    const [inputCreateTicketState, setInputCreateTicketState] = useState(initialInput);
    
    const handleInput = (event)=>{
        setErrorMsg("");
        setInitialInputErrorState({...initialInputErrorState, [event.target.name]: ""});
        setInputCreateTicketState({...inputCreateTicketState, [event.target.name]: event.target.value});
    }

    const sendTicketApi = async ()=>{
        
        setErrorMsg("");
        console.log("inside sendTicketApi Multiform data", inputCreateTicketState);

        const ticketFormData = new FormData();
        ticketFormData.append("person_name",inputCreateTicketState.person_name);
        ticketFormData.append("person_email",inputCreateTicketState.person_email);
        ticketFormData.append("person_mobile",inputCreateTicketState.person_mobile);
        ticketFormData.append("issue",inputCreateTicketState.issue);
        ticketFormData.append("idOfClient",inputCreateTicketState.idOfClient);
        ticketFormData.append("ticket_status",inputCreateTicketState.ticket_status);
    // file contains FileList object , so to use forEach we have to convert it to array 
    // if we append to same key it will become an array, so we can catch this array name in backend and
    // loop throught it
    if(file != null){
        [...file].forEach(image => {
            ticketFormData.append("ticket_images_array", image);
        });
    }
      

    try{
        
      axios({
            method: "post",
            url : `${base_url}/create-ticket`,
            data : ticketFormData,
            headers : {"Content-Type": "multipart/form-data"}
        }).
            then((res)=>{
                console.log("login result is ", res);
                if(res.data.isCreationSuccess){
                    console.log("ticket created successfuly");
                    // clear input fields and files
                    setInputCreateTicketState(initialInput);
                   firstFileRef.current.value = null; 
                   //secondFileRef.current.value = null;

                    //show popup
                    setShowSuccessPopup(prev => !prev);

                    //set isAllRequiredInputAreFilled to false 
                    // else if we type and send ticket immediately after the first ticket
                    // since IsAllRequiredInputAreFilled is already true nothing happens 
                    //ie useEffect code wont work,
                    // so we set to false
                    setIsAllRequiredInputAreFilled(false);
                    
                }else{
                    setErrorMsg(res.data.errorMessage);
                    console.log("login failed reason ", res.data);
                }
            }).catch(err => {
                setErrorMsg("Create Request Failed... Please contact admin");
                console.log("err on front end ", err);
            });

    }catch(err){
            console.log("error got from try catch on create ticket post", err)
    };
        
    }

    
    useEffect(()=>{

        if(isAllRequiredInputAreFilled){
            sendTicketApi();
        }else{
            console.log(" inside else isAllRequiredInputAreFilled is ", isAllRequiredInputAreFilled);
        }
        
    }, [isAllRequiredInputAreFilled])

    const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };

    const validateInputFields = ()=>{
        if(inputCreateTicketState.person_name.length >  0 && inputCreateTicketState.person_mobile.length > 0 && inputCreateTicketState.person_email.length > 0 && inputCreateTicketState.issue.length > 0 ){
            
            // first check if there is any text in email field, if there is atleast one character 
            // show email error paragraph
            if(inputCreateTicketState.person_email.length > 0){

                console.log("email length greater than zero")

                if(validateEmail(inputCreateTicketState.person_email)){
                    
                    console.log("email length is valid");
                    
                    setInitialInputErrorState(prev=> ({...prev, person_email: ""}));

                    return(true);

                }else{
                    
                    console.log("email format is not valid");
                    setInitialInputErrorState(prev=> ({...prev, person_email: "please enter a valid email"}));

                    return(false);
                }

            }else{
                console.log("inside else email less than zero" );
                setInitialInputErrorState(prev=> ({...prev, person_email: ""})); 
                
                return(true);
            }
            
        }
        else{

            if(! inputCreateTicketState.person_name.length > 0 ){
                setInitialInputErrorState(prev=> ({...prev, person_name: "please enter name"}));
            }
            if(! inputCreateTicketState.person_email.length > 0 ){
                setInitialInputErrorState(prev=> ({...prev, person_email: "please enter email"}));
            }
            if(! inputCreateTicketState.person_mobile.length > 0 ){
                setInitialInputErrorState(prev=> ({...prev, person_mobile: "please enter mobile number"}));
            }
            if(! inputCreateTicketState.issue.length > 0){
                setInitialInputErrorState(prev=> ({...prev, issue: "please enter issue"}));
            }
            return(false);
        }    
    }

    const sendButtonClicked = (event)=>{
        event.preventDefault();
        console.log("sendButtonClicked clicked isAllRequiredInputAreFilled is ", isAllRequiredInputAreFilled);
        // validate input 
        // if ok set everyinput filled to true
        // on useeffect call sendTicketApi

        let resultAfterValidation =  validateInputFields();

        if(resultAfterValidation){
            console.log("sendButtonClicked clicked resultAfterValidation true");
            setIsAllRequiredInputAreFilled(true);
        }else{
            console.log("sendButtonClicked clicked resultAfterValidation false");
            setIsAllRequiredInputAreFilled(false);
        }
    }

    const onFileSelected = (e)=>{
        setFile(e.target.files);
    }

    return(
        <div className="CustomerCreateTickets-root-outer-container">

            <div className="CustomerCreateTickets-root-container">

                <form onSubmit={sendButtonClicked}>
                    <div className="CustomerCreateTickets-person-details-container d-flex">
                            <span>
                                <input name="person_name" placeholder="Name*" type="text"  value={inputCreateTicketState.person_name} onChange={ (event)=>{handleInput(event)} }/>
                                <p className="CustomerCreateTickets-error-paragraph">{initialInputErrorState.person_name}</p> 
                            </span>
                            <span>
                                <input name="person_email" placeholder="Email*" type="email" value={inputCreateTicketState.person_email} onChange={ (event)=>{handleInput(event)} } />
                                <p className="CustomerCreateTickets-error-paragraph">{initialInputErrorState.person_email}</p> 
                            </span>
                            <span>
                                <input name="person_mobile" placeholder="Mobile*" type="number" value={inputCreateTicketState.person_mobile} onChange={ (event)=>{handleInput(event)} }/>   
                                <p className="CustomerCreateTickets-error-paragraph">{initialInputErrorState.person_mobile}</p> 
                            </span>
                    </div>
                    <div>
                        <textarea name="issue" className="CustomerCreateTickets-details-about-issue" placeholder="Please enter some details about the issue*" rows="4" cols="100" value={inputCreateTicketState.issue} onChange={ (event)=>{handleInput(event)} }></textarea>
                        <p className="CustomerCreateTickets-error-paragraph">{initialInputErrorState.issue}</p> 
                    </div>
                    <div>
                        <span className="CustomerCreateTickets-attach-image-bg">Attach Image</span> <span className="CustomerCreateTickets-attach-img-note ms-2">(Maximum 2 images. File size below 100kb )</span>

                        <input type="file" id="customFile" onChange={onFileSelected} ref={firstFileRef} multiple/>
                        
                        
                    </div>
                    <div>
                        <button type="submit" className="CustomerCreateTickets-create-button mt-4" onSubmit={sendButtonClicked}>Create Ticket</button>
                        <div className="ClientRegistration-registration-error-container py-2">{errorMsg}</div>
                    </div>
                </form>
            </div>


            {/* <img src={illustrationCreateTicket} className="CustomerCreateTickets-bottom-illustration"/> */}

            {/* success popup starts */}
                {showSuccessPopup  && (<div className="AdminHome-work-entry-successPopup-outer">
                <div className="AdminHome-work-entry-successPopup-inner">
                    <div><img src={ic_check_mark}/><span>Success</span></div> 
                    <div><p>Ticket Created Successfully.</p></div>   
                    <button onClick={() => setShowSuccessPopup(prev => !prev)}>OK</button>          
                </div>
                </div>)
                }
            
{/* success popup starts */}
        </div>

        
    )

}

export default CustomerCreateTickets;