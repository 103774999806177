import React, { useState } from "react";
import ic_hamburger_menu from "../img/ic_hamburger_menu.png";
import ic_cubix_logo from "../img/logo_cubix.png";
import "../css/Header.css";
import { useNavigate } from "react-router-dom";
import SideDrawer from "./SideDrawer";
import ic_search from "../img/ic_search_customer.png";
import axios from "axios";
import { useEffect } from "react";

function Header(props) {

    const { funcFromParent, filterCompany } = props;
    const [searchBoxCompanyState, setSearchBoxCompanyState] = useState([]);
    let timeInterval = null;
    const [isCallingApi, setIsCallingApi] = useState(false);
    const [showHideSearchListContainer, setShowHideSearchListContainer] = useState(false);
    const [arrayOfSearchResults, setArrayOfSearchResults] = useState(null)


    const callApi = (searchingText) => {

        axios.get(`https://185.241.124.171:122/api/CustomerDetails/CUST_SER/${searchingText}`).then((res) => {

            console.log("companys search res is ", res.data);
            setIsCallingApi(false);

            if (res.status == 204) {
                setArrayOfSearchResults([]);
            } else {
                setArrayOfSearchResults(res.data);
            }

        }).catch(err => {
            setIsCallingApi(false);
            console.log("error is ", err)
        })

    }

    const handleInput = (e) => {

        clearInterval(timeInterval);

        if (e.target.value.length > 0) {

            setShowHideSearchListContainer(true);

            timeInterval = setTimeout(() => {

                console.log(" searchtext changed is", e.target.value);
                if (timeInterval) {
                    console.log("timer is not null we are clearing it")
                    clearInterval(timeInterval);
                }

                callApi(e.target.value);
                setIsCallingApi(true);


            }, 500)

        } else {
            setShowHideSearchListContainer(false)
        }

    }

    let navigate = useNavigate();

    const [showSideDrawerState, setshowSideDrawerState] = useState(false);

    const showHideSideDrawer = () => {
        setshowSideDrawerState(prev => !prev)
    }

    const logout = () => {
        localStorage.setItem("cubix_support_admin_isAuthenticated", false);
        navigate("/admin_login");
    }

    const clickedOnHeaderSearchBoxListItem = (indexOnCompanyArray) => {

        console.log("clicked on ", arrayOfSearchResults[indexOnCompanyArray]);
        setSearchBoxCompanyState(arrayOfSearchResults[indexOnCompanyArray])
        setShowHideSearchListContainer(false)
        if(filterCompany != null){
            filterCompany()
        }
    }

    const removeButtonClicked = () => {
        console.log("clicked on removeButtonClicked")
        setSearchBoxCompanyState([])
    }

    useEffect(() => {

        if (searchBoxCompanyState != null) {

            if (funcFromParent != null) {
                funcFromParent(searchBoxCompanyState);
            }

        }

    }, [searchBoxCompanyState])



    return (
        <div className="Header-root-container">
            <div className="Header-panel">

                <div>
                    <a href="/admindashboard" className="ms-4 Header-panel-company-logo">
                        <img src={ic_cubix_logo} />
                    </a>
                </div>

                <div className="Header-company-search-box-remove-and-input-container">

                    {
                        searchBoxCompanyState != null ?
                            searchBoxCompanyState.length != 0 ?
                                <span className="Header-company-search-box-remove-chip" onClick={removeButtonClicked}>remove</span>
                                :
                                null
                            :
                            null
                    }


                    <div className="Header-company-search-box-and-list-container ms-2">
                        <img src={ic_search} />
                        <input type="text" placeholder="search company name" onChange={handleInput} />

                        {
                            showHideSearchListContainer &&
                            <div className="Header-company-search-result-list-container">

                                <table className="Header-company-table-after-search">
                                    <thead>
                                        <tr>
                                            <th><div>Name</div></th>
                                            <th><div>Category</div></th>
                                            <th><div>Remarks</div></th>
                                            <th><div>AMC Date</div></th>
                                            <th><div>Balance</div></th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {
                                            isCallingApi &&

                                            <tr>
                                                <td>

                                                    <div className="Header-searching-text-container">

                                                        <div class="spinner-border" role="status">
                                                        </div>

                                                        <label className="ms-4">Searching ....</label>

                                                    </div>
                                                </td>
                                            </tr>
                                        }

                                        {
                                            arrayOfSearchResults != null ?

                                                arrayOfSearchResults.length == 0 ?

                                                    <tr>
                                                        <td>
                                                            <div className="Header-searching-text-container">
                                                                <label> No results found</label>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    :

                                                    arrayOfSearchResults.map((item, index) => {
                                                        return (
                                                            <tr className="Header-company-search-result-list-single-row" onClick={() => clickedOnHeaderSearchBoxListItem(index)}>
                                                                <td>
                                                                    <div className="Header-company-search-result-list-single-item">{item.accdesc}</div>
                                                                </td>
                                                                <td>
                                                                    <div className="Header-company-search-result-list-single-item">{item.category}</div>
                                                                </td>
                                                                <td>
                                                                    <div className="Header-company-search-result-list-single-item">{item.remarks}</div>
                                                                </td>
                                                                <td>
                                                                    <div className="Header-company-search-result-list-single-item">{item.amcdate}</div>
                                                                </td>
                                                                <td>
                                                                    <div className="Header-company-search-result-list-single-item">{item.balance}</div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })

                                                :
                                                null
                                        }

                                    </tbody>

                                </table>
                            </div>
                        }

                    </div>
                </div>

                {/* <span className="Header-support-bg-text-as-bg">Support Center</span> */}

            </div>

            {
                showSideDrawerState && <SideDrawer logout={logout} showHideSideDrawer={showHideSideDrawer} />
            }
        </div>
    )

}

export default Header;