import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../css/AdminLogin.css";
import logo_cubix from "../img/logo_cubix.png";
import side_banner from "../img/side_banner_cubix_login.png";
import bottom_circle from "../img/bottom_circle_cubix.png";
import bottom_circle_2 from "../img/bottom_circle_2_cubix.png";
import bottom_circle_3 from "../img/side_dot_panel.png";
import ic_admin_icon from "../img/ic_admin_icon.png";
import LoadingLayout from "./LoadingLayout";

function AdminLogin() {

    const navigate = useNavigate();

    const initialInput = { username: "", password: "" };
    const [inputUserLoginState, setInputUserLoginState] = useState(initialInput);
    const [showLoadingLayout, setShowLoadingLayout] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const handleInput = (event) => {

        setErrorMessage("");
        setInputUserLoginState({ ...inputUserLoginState, [event.target.name]: event.target.value });
    }

    const callLoginAPI = () => {
        //after validation send to api 

        axios.post(`${process.env.REACT_APP_BASE_URL}/login-admin.php`, inputUserLoginState).
            then((res) => {
                console.log("login result is ", res);
                if (res.data.status == "login_success") {

                    localStorage.setItem("cubix_support_admin_isAuthenticated", true);
                    localStorage.setItem("cubix_support_admin_username", inputUserLoginState.username);

                    if (inputUserLoginState.username == "admin_tv_monitor") {
                        navigate(`/admindashboard_tv_monitor`);
                    } else {
                        navigate(`/admindashboard`);
                    }


                } else {
                    console.log("login failed reason ", res.data);

                    setErrorMessage(res.data.message);
                }
            }).catch(err => console.log("error is ", err));

    }

    const checkWhetherAlreadyLoggedIn = () => {

        setShowLoadingLayout(true);

        const userName = localStorage.getItem("cubix_support_admin_username"); 
        const authed = localStorage.getItem("cubix_support_admin_isAuthenticated");
        if (JSON.parse(authed)) {

            setShowLoadingLayout(false);

            if(userName != null){
                if (userName == "admin_tv_monitor") {
                    navigate(`/admindashboard_tv_monitor`);
                } else {
                    navigate(`/admindashboard`);
                }
            }else{
                navigate(`/admindashboard`);
            }
            

        } else {
            setShowLoadingLayout(false);
        }
    }

    useEffect(() => {

        checkWhetherAlreadyLoggedIn();

    }, [])


    return (
        <div className="AdminLogin-root-container">

            <div className="AdminLogin-left-container hidden-in-mobile">
                <img src={logo_cubix} className="AdminLogin-cubix-logo" />
                <img src={side_banner} className="AdminLogin-side-banner-img" />
            </div>

            <div className="AdminLogin-top-layer-container">

                <div className="AdminLogin-person-icon-heading-container">
                    <img src={ic_admin_icon} />
                    <h1 className="AdminLogin-heading">Cubix Support Center - AdminPanel</h1>
                </div>

                <div>

                    <div className="mt-4 AdminLogin-form-container">
                        <label>Email</label>
                        <input className="mt-2" type="email" name="username" value={inputUserLoginState.username} onChange={(event) => { handleInput(event) }} />
                        <label className="mt-2">Password</label>
                        <input className="mt-2" type="password" name="password" value={inputUserLoginState.password} onChange={(event) => { handleInput(event) }} />
                        <button className="mt-3 btn btn-primary AdminLogin-login-button " onClick={callLoginAPI} >Login</button>
                        <p className="mt-2 AdminLogin-errorMessage">{errorMessage}</p>
                    </div>
                </div>

            </div>

            {
                showLoadingLayout && <LoadingLayout />
            }
        </div>
    )

}

export default AdminLogin;