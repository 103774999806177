import React, { useState, useEffect, useRef } from "react";
import "../css/CreateTicketPopupContainer.css";
import ic_projects from "../img/ic_projects.png"
import axios from "axios";
import LoadingLayout from "./LoadingLayout";

function CreateNewProjectProductPopupContainer(props) {


    const [showLoadingLayout, setShowLoadingLayout] = useState(false);
    const [errorMessageState, setErrorMessageState] = useState("");
    const [showAssignedByDropDown, setShowAssignedByDropDown] = useState(false);
    const [cubixTaskTicketStaffNameArrayState, setCubixTaskTicketStaffNameArray] = useState(null);

    const { showHideCreateNewTicketPopup } = props;

    const initialInputState = {
        input_name: "",
        input_details: "",
        input_created_by: "",
        input_expected_completion_date: "",
        input_category: 1
    }
    const [inputState, setTicketInputState] = useState(initialInputState);

    const handleInput = (e) => {
        setErrorMessageState("");
        setTicketInputState({ ...inputState, [e.target.name]: e.target.value });
    }

    const sendDataToApi = () => {

        console.log("inputState ", inputState);

        if (inputState.input_name.length > 0 && inputState.input_created_by > 0) {

            setShowLoadingLayout(true);

            axios.post(`${process.env.REACT_APP_BASE_URL}/create-project-product.php`, inputState).
                then((res) => {
                    console.log(" task creation res is ", res);
                    setShowLoadingLayout(false);
                    if (res.data.status = "creation_success") {

                        window.location.reload();
                        showHideCreateNewTicketPopup();
                    }
                }).
                catch((err) => {
                    setShowLoadingLayout(false);
                    console.log("err is ", err);
                })

        } else {
            setErrorMessageState("Either Description or assigned by not filled")
        }

    }

    const clickedOnStatus = (clickedOnStatus, e) => {

        console.log("clickedOnStatus e ", clickedOnStatus, e.target)
        setTicketInputState({ ...inputState, input_category: clickedOnStatus });
    }

    const getFromLocalStorageStaffArray = () => {
        let cubixTaskTicketStaffNameArray = sessionStorage.getItem("cubix_task_ticket_staff_name_array");
        setCubixTaskTicketStaffNameArray(JSON.parse(cubixTaskTicketStaffNameArray))
    }

    useEffect(() => {
        getFromLocalStorageStaffArray();
    }, [])

    return (
        <div className="CreateTicketPopupContainer-root-container">

            <img className="CreateTicketPopupContainer-side-clip-image" src={ic_projects} />
            <div className="CreateTicketPopupContainer-inner-card">
                <h6>Create New Project / Product</h6>

                <div className="CreateTaskPopupContainer-status-container mt-4">
                    <p className="CompletionPopup-select-text">
                        Select category
                    </p>
                    <div className="CreateTaskPopupContainer-status-selection-option ProjectProductManager-category-container">
                        <div className={inputState.input_category === 1 ? " active_status_button" : ""} onClick={(e) => clickedOnStatus(1, e)}>
                            <span></span>
                            <label className="ms-2 me-2">Project</label>
                        </div>
                        <div className={inputState.input_category === 2 ? "active_status_button ms-2" : "ms-2"} onClick={(e) => clickedOnStatus(2, e)}>
                            <span></span>
                            <label className="ms-2 me-2">Product</label>
                        </div>
                    </div>
                </div>

                <label className="mt-4">Enter name</label>
                <input className="mt-2" type="text" name="input_name" onChange={handleInput} />

                <label className="mt-4">Enter details</label>
                <input className="mt-2" type="text" name="input_details" onChange={handleInput} />

                <label className="mt-4">Expected Completion Date</label>
                <span className="mt-2">
                    <input type="date" value={inputState.input_expected_completion_date} name="input_expected_completion_date" onChange={handleInput} />
                </span>
                <div className="CreateTaskPopupContainer-staff-dropdown-anchorand-list-container ">
                    <label className="mt-4">Created By</label>

                    <div className="FilterPopupContainer-select-staff-name-container">

                        <div className="CreateTaskPopupContainer-dropdown-picker mt-2" onClick={() => setShowAssignedByDropDown(prev => !prev)} >

                            {
                                cubixTaskTicketStaffNameArrayState != null ?
                                    cubixTaskTicketStaffNameArrayState.length > 0 ?
                                        cubixTaskTicketStaffNameArrayState.filter((item) => {
                                            return (
                                                item.id == inputState.input_created_by
                                            )
                                        }).length > 0 ?

                                            cubixTaskTicketStaffNameArrayState.filter((item) => {
                                                return (
                                                    item.id == inputState.input_created_by
                                                )
                                            })[0].staff_name
                                            :
                                            null
                                        :
                                        null
                                    :
                                    null
                            }
                        </div>

                        {showAssignedByDropDown &&
                            <div className="CreateTaskPopupContainer_staff_name_dropdown_item_container">
                                {
                                    cubixTaskTicketStaffNameArrayState != null ?
                                        cubixTaskTicketStaffNameArrayState.length > 0 ?
                                            cubixTaskTicketStaffNameArrayState.map((item) => {
                                                return (
                                                    <span key={item.id} className="CreateTaskPopupContainer_staff_name_dropdown_item" id={item.id} onClick={() => { setErrorMessageState(""); setTicketInputState({ ...inputState, input_created_by: item.id }); setShowAssignedByDropDown(prev => !prev) }}>{item.staff_name}</span>
                                                )
                                            })
                                            :
                                            null
                                        :
                                        null
                                }

                            </div>
                        }
                    </div>

                </div>

                <div className="CreateTaskPopupContainer-error-message-container">
                    <p className="text-danger ">{errorMessageState}</p>
                </div>

                <div className="CreateTicketPopupContainer-cancel-save-container">
                    <button className="CreateTicketPopupContainer-cancel-button me-4" onClick={showHideCreateNewTicketPopup}>Cancel</button>
                    <button className="CreateTicketPopupContainer-save-button" onClick={sendDataToApi}>Save</button>
                </div>

            </div>

            {
                showLoadingLayout && <LoadingLayout />
            }

        </div>
    )

}

export default CreateNewProjectProductPopupContainer;