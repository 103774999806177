import React from "react";
import LoadingUI from "./LoadingUI";
import "../css/LoadingLayout.css";

function LoadingLayout (){

    return(
        <div className="LoadingLayout-root-container">
            <LoadingUI />
        </div>
    )

}

export default LoadingLayout;