import React, { useEffect, useState, useRef } from "react";
import "../css/FilterPopupContainer.css";
import ic_filter from "../img/ic_filter.png";

function FilterPopupContainer(props) {

    const {showHideFilterPopup,filterTheListInParentContainer, filterTheListInParentContainerFromCustomer} = props;
    let timeInterval = null;
    let currentTextInSearchField = useRef("");
    const [showStaffNameDropDown, setShowStaffNameDropDown] = useState(false);
    const [showCompanyNameDropDown, setShowCompanyNameDropDown] = useState(false);
    const [cubixTaskTicketStaffNameArrayState, setCubixTaskTicketStaffNameArray] = useState(null);
    const [cubixTaskTicketCompanyNameArrayState, setCubixTaskTicketCompanyNameArray] = useState(null);
    const initialFilterState = { ticket_status: 0, staff_name: 0, company_name: 0 };
    const [filterState, setFilterState] = useState(initialFilterState);
    let copyOfAllCompanyList = useRef([]);
    const [companySearchTextState, setCompanySearchTextState] = useState("");

    const [startCompanyNameFiltering, setStartCompanyNameFiltering] = useState(false);

    const getFromLocalStorageStaffArray = () => {
        let cubixTaskTicketStaffNameArray = sessionStorage.getItem("cubix_task_ticket_staff_name_array");
        setCubixTaskTicketStaffNameArray(JSON.parse(cubixTaskTicketStaffNameArray))
    }

    const getFromLocalStorageCompanyNameArray = () => {
        let cubixTaskTicketCompanyNameArray = sessionStorage.getItem("cubix_task_ticket_company_name_array");
        copyOfAllCompanyList.current = JSON.parse(cubixTaskTicketCompanyNameArray);
        setCubixTaskTicketCompanyNameArray(JSON.parse(cubixTaskTicketCompanyNameArray))
    }

    const handleCompanySearchInput = (e) => {

        console.log(" searchtext company changed is", e.target.value);

        setCompanySearchTextState(e.target.value);
        currentTextInSearchField.current = e.target.value;

        clearInterval(timeInterval);

        timeInterval = setTimeout(() => {
            if (e.target.value.length > 0) {
                if (timeInterval) {
                    console.log("e.target.value.length  > 0timer is not null we are clearing it")
                    clearInterval(timeInterval);
                }
                setStartCompanyNameFiltering(true);
            } else {
                if (timeInterval) {
                    console.log("e.target.value.length == 0 timer is not null we are clearing it")
                    clearInterval(timeInterval);
                }
                setStartCompanyNameFiltering(false);
                setCubixTaskTicketCompanyNameArray(copyOfAllCompanyList.current);
            }
        }, 1000)
    }
    
    const filterTheList = ()=>{
        filterTheListInParentContainer(filterState);
        filterTheListInParentContainerFromCustomer(filterState)
        showHideFilterPopup();
    }

    useEffect(() => {

        if (startCompanyNameFiltering) {

            setStartCompanyNameFiltering(false);

            console.log("CreateTaskPopupContainer >>>>>>> ", "startCompanyNameFiltering true");

            if (currentTextInSearchField.current.length > 0) {
                console.log("CreateTaskPopupContainer >>>>>>> ", currentTextInSearchField.current);

                setCubixTaskTicketCompanyNameArray(
                    copyOfAllCompanyList.current.filter((item) => {
                        return item.company_name.toLowerCase().includes(currentTextInSearchField.current.toLowerCase())
                    })
                )
            }

        } else {
            console.log("CreateTaskPopupContainer >>>>>>> ", "startCompanyNameFiltering false");
        }

    }, [startCompanyNameFiltering])

    useEffect(() => {
        getFromLocalStorageStaffArray();
        getFromLocalStorageCompanyNameArray();
    }, [])


    return (
        <div className="FilterPopupContainer-root-container">

            <div className="FilterPopupContainer-inner-card">

                <div className="FilterPopupContainer-header-panel">
                    <img src={ic_filter} /> <h6 className="ms-2">Filter </h6>
                </div>

                <label className="mt-3">Ticket Status</label>

                <div className="mt-2">
                    <div className="CreateTaskPopupContainer-status-selection-option FilterPopupContainer-ticket-status">
                        <div className={filterState.ticket_status === 0 ? " active_status_button" : ""} onClick={(e) => setFilterState({ ...filterState, ticket_status: 0 })}>
                            <span></span>
                            <label className="mx-2">All</label>
                        </div>
                        <div className={filterState.ticket_status === 1 ? "active_status_button ms-2" : "ms-2"} onClick={(e) => setFilterState({ ...filterState, ticket_status: 1 })}>
                            <span></span>
                            <label className="mx-2">Opened</label>
                        </div>
                        <div className={filterState.ticket_status === 4 ? "active_status_button ms-2" : "ms-2"} onClick={(e) => setFilterState({ ...filterState, ticket_status: 4 })}>
                            <span></span>
                            <label className="mx-2">Escalated</label>
                        </div>
                    </div>
                </div>

                <label className="mt-4">Staff Name </label>
                <div className="FilterPopupContainer-select-staff-name-container">
                    <div className="CreateTaskPopupContainer-dropdown-picker  mt-2" onClick={() => setShowStaffNameDropDown(prev => !prev)} >

                        {
                            cubixTaskTicketStaffNameArrayState != null ?
                                cubixTaskTicketStaffNameArrayState.length > 0 ?
                                    cubixTaskTicketStaffNameArrayState.filter((item) => {
                                        return (
                                            item.id == filterState.staff_name
                                        )
                                    }).length > 0 ?

                                        cubixTaskTicketStaffNameArrayState.filter((item) => {
                                            return (
                                                item.id == filterState.staff_name
                                            )
                                        })[0].staff_name
                                        :
                                        null
                                    :
                                    null
                                :
                                null
                        }
                    </div>

                    {showStaffNameDropDown &&
                        <div className="CreateTaskPopupContainer_staff_name_dropdown_item_container">
                            {
                                cubixTaskTicketStaffNameArrayState != null ?
                                    cubixTaskTicketStaffNameArrayState.length > 0 ?
                                        cubixTaskTicketStaffNameArrayState.map((item) => {
                                            return (
                                                <span key={item.id} className="CreateTaskPopupContainer_staff_name_dropdown_item" id={item.id} onClick={() => { setFilterState({ ...filterState, staff_name: item.id }); setShowStaffNameDropDown(prev => !prev) }}>{item.staff_name}</span>
                                            )
                                        })
                                        :
                                        null
                                    :
                                    null
                            }

                        </div>
                    }
                </div>

                <label className="mt-4">Company Name </label>
                <div className="FilterPopupContainer-select-staff-name-container">
                    <div className="CreateTaskPopupContainer-dropdown-picker mt-2" onClick={() => setShowCompanyNameDropDown(prev => !prev)} >

                        {
                            cubixTaskTicketCompanyNameArrayState != null ?
                                cubixTaskTicketCompanyNameArrayState.length > 0 ?
                                    cubixTaskTicketCompanyNameArrayState.filter((item) => {
                                        return (
                                            item.id == filterState.company_name
                                        )
                                    }).length > 0 ?

                                        cubixTaskTicketCompanyNameArrayState.filter((item) => {
                                            return (
                                                item.id == filterState.company_name
                                            )
                                        })[0].company_name
                                        :
                                        null
                                    :
                                    null
                                :
                                null
                        }
                    </div>

                    {showCompanyNameDropDown &&
                        <div className="CreateTaskPopupContainer_staff_name_dropdown_item_container">

                            <input type="text" className="CreateTicketPopupContainer-company-search-box" placeholder="Search company name" value={companySearchTextState} onChange={handleCompanySearchInput} />

                            {
                                cubixTaskTicketCompanyNameArrayState != null ?
                                    cubixTaskTicketCompanyNameArrayState.length > 0 ?
                                        cubixTaskTicketCompanyNameArrayState.map((item) => {
                                            return (
                                                <span key={item.id} className="CreateTaskPopupContainer_staff_name_dropdown_item" id={item.id} onClick={() => { setFilterState({ ...filterState, company_name: item.id }); setShowCompanyNameDropDown(prev => !prev) }}>{item.company_name}</span>
                                            )
                                        })
                                        :
                                        null
                                    :
                                    null
                            }

                        </div>
                    }
                </div>

                <div className="CompletionPopup-cancel-save-container mt-4">
                    <button className="CompletionPopup-cancel-button me-4" onClick={showHideFilterPopup}>Cancel</button>
                    <button className="CompletionPopup-save-button" onClick={filterTheList}>Filter</button>
                </div>


            </div>
        </div>
    )
}
export default FilterPopupContainer;