import axios from "axios";
import React, { useEffect, useState } from "react";
import {useNavigate, createSearchParams} from "react-router-dom";
import logo_cubix from "../img/logo_cubix.png";
import ic_admin_icon from "../img/ic_admin_icon.png";
import ic_logout from "../img/ic_person_logout.png";
import "../css/Companies.css";

function Companies(){

    const base_url = "http://185.140.249.224:3001";
    //const base_url = "http://localhost:3001";
    const navigate = useNavigate();
    const [companyListState, setCompanyListState] = useState(null);

    const getCompaniesList = ()=>{

        axios.get(`${base_url}/get-companies-list`).
        then((res)=>{
            console.log("company list is " , res);
            setCompanyListState(res.data.companyList);
        }).catch((err)=>console.log("error while getting company list"))

    }
    const logOutUser = ()=>{
        // console.log("log out admin clicked ");
        localStorage.setItem("cubix_admin_isAuthenticated", false);
        navigate("/admin")
    }
const verifyCompany = (verifyText,  company_id)=>{
    axios.post(`${base_url}/verify-company`,{ verifyCompany: verifyText, companyId: company_id}).then((res)=>{
        console.log("res is ", res);
        window.location.reload(false);
    }).catch((err)=>{console.log("error while verify company")})
}
    useEffect(()=>{
        getCompaniesList();
    }, [])

    return(
        <div className="Admin-Companies-root-container">
            <div className="AdminHome-logo-container">
                <div>
                    <a href="/admindashboard" >
                            <img src={logo_cubix} className="AdminHome_company_logo" />
                    </a>
                    <img src={ic_admin_icon} className="ms-4 AdminHome_admin_logo"/>
                    <label className="ms-2">Admin</label>
                </div>

                <div className="me-4 AdminHome-logout-container" onClick={logOutUser}>
                    <img src={ic_logout} className="me-2"/> <span>Logout</span>
                </div>
          </div>
            <div className="AdminHome-companies-list">
                { companyListState != null ? companyListState.map((item)=>{
                    return(
                    <div key={item.id} className="m-4 card-item">
                        <div>
                            <div className="card-item-name"><span>{item.company_name}</span></div>
                            <span className="card-item-bottom">
                                <label>is Account verified</label>
                                <label className="ms-4">{item.is_account_verified}</label>
                                {item.is_account_verified == "no" ? <button className="ms-4 AdminHome-green-button" onClick={()=>verifyCompany("yes", item.id)}>Verify</button> : <button className="ms-4 AdminHome-red-button" onClick={()=>verifyCompany("no", item.id)}>Block</button>}
                            </span>
                            <button onClick={()=> navigate({pathname:"/admindashboard/reports", search:`?${createSearchParams({company_id:item.id})}`})}>View Details</button>
                        </div>
                    </div>

                    )
                }) : ""}
                
            </div>
        </div>
    )

}

export default Companies;