import React, { useState } from "react";
import Header from "./Header";
import "../css/Checklist.css";
import ic_checkbox from "../img/ic_check_mark.png";
import ic_normal_checkbox from "../img/ic_normal_checkbox.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function Checklist() {

    const navigate = useNavigate();
    const [resultAfterTask, setResultAfterTask] = useState("")
    const [nameOfStaff, setNameOfStaff] = useState("")
    const [nameOfCompany, setNameOfCompany] = useState("")

    const handleOnChangeNameOfStaff = (value) => {
        setNameOfStaff(value)
    }
    const handleOnChangeNameOfCompany = (value) => {
        setNameOfCompany(value)
    }

    const arrayOfCheckList = [
        {
            node_code: "1",
            node_name: "Preparation",
            node_items: [
                { node_item_code: "1-1", name: "Backup of existing db/software", checkbox_status: false, remarks: "" },
                { node_item_code: "1-2", name: "Network connections/ VPN/DynDns Connection", checkbox_status: false, remarks: "" },
                { node_item_code: "1-3", name: "Database connectivity & Functions", checkbox_status: false, remarks: "" },
                { node_item_code: "1-4", name: "Delete all the data by executing Support SP", checkbox_status: false, remarks: "" },
                { node_item_code: "1-5", name: "Compatible Framework", checkbox_status: false, remarks: "" },
                { node_item_code: "1-6", name: "Crystal Report Runtime", checkbox_status: false, remarks: "" },

            ]
        },
        {
            node_code: "2",
            node_name: "Basic information setup",
            node_items: [
                { node_item_code: "2-1", name: "Company Name and Company Code", checkbox_status: false, remarks: "" },
                { node_item_code: "2-2", name: "Generate License", checkbox_status: false, remarks: "" },
                { node_item_code: "2-3", name: "Support Portal Connectivity Verification", checkbox_status: false, remarks: "" },
                { node_item_code: "2-4", name: "Company Address and Logo Setting", checkbox_status: false, remarks: "" },
                { node_item_code: "2-5", name: "Departments Creation and Settings", checkbox_status: false, remarks: "" },
                { node_item_code: "2-6", name: "Inventory Method Confirmation- Perpetual or periodic", checkbox_status: false, remarks: "" },

            ]
        },
        {
            node_code: "3",
            node_name: "Reports",
            node_items: [
                { node_item_code: "3-1", name: "Report Heading", checkbox_status: false, remarks: "" },
                { node_item_code: "3-2", name: "Report Alignment", checkbox_status: false, remarks: "" },
                { node_item_code: "3-3", name: "Report Data Accuracy", checkbox_status: false, remarks: "" },

            ]
        },
        {
            node_code: "4",
            node_name: "User /menu",
            node_items: [
                { node_item_code: "4-1", name: "Role Creation", checkbox_status: false, remarks: "" },
                { node_item_code: "4-2", name: "Menu Setting as per Role", checkbox_status: false, remarks: "" },
                { node_item_code: "4-3", name: "User Creation", checkbox_status: false, remarks: "" },
                { node_item_code: "4-4", name: "Sales Person Creation", checkbox_status: false, remarks: "" },
                { node_item_code: "4-5", name: "User Privillege Setting", checkbox_status: false, remarks: "" },

            ]
        },
        {
            node_code: "5",
            node_name: "Data migration (If Applicable)",
            node_items: [
                { node_item_code: "5-1", name: "Master information Accuracy", checkbox_status: false, remarks: "" },
                { node_item_code: "5-2", name: "Inventory Transaction information Accuracu", checkbox_status: false, remarks: "" },
                { node_item_code: "5-3", name: "Accounts informaton accuracy", checkbox_status: false, remarks: "" },


            ]
        },
        {
            node_code: "6",
            node_name: "Voucher settings",
            node_items: [
                { node_item_code: "6-1", name: "Voucher number setting by department", checkbox_status: false, remarks: "" },
                { node_item_code: "6-2", name: "Basic Accounts Setting", checkbox_status: false, remarks: "" },
                { node_item_code: "6-3", name: "Confirmation of Accounts as per Inventory Method", checkbox_status: false, remarks: "" },
                { node_item_code: "6-4", name: "Testing of All Screens Entry Level", checkbox_status: false, remarks: "" },
                { node_item_code: "6-5", name: "Testing of All Screens Edit/Delete Level", checkbox_status: false, remarks: "" },
                { node_item_code: "6-6", name: "Testing of All Documents Printing Level", checkbox_status: false, remarks: "" },
                { node_item_code: "6-7", name: "Hardcopies of Printed documents for approval and documentation", checkbox_status: false, remarks: "" },
                { node_item_code: "6-8", name: "User Level System cycle testing and confirmation", checkbox_status: false, remarks: "" },


            ]
        },
    ]

    const [arrayOfNodes, setArrayOfNodes] = useState(arrayOfCheckList)

    const clickedCheckboxOnItem = (nodeCode, nodeItemCode) => {

        const new_array = arrayOfNodes.map((item) => {

            if (item.node_code == nodeCode) {

                let newItemsArrayInthisNode = item.node_items.map((itemInside) => {

                    if (itemInside.node_item_code == nodeItemCode) {

                        console.log("itemInside if ", itemInside)
                        return { ...itemInside, checkbox_status: !itemInside.checkbox_status }

                    } else {

                        console.log("itemInside else ", itemInside)
                        return itemInside
                    }

                })

                return { ...item, node_items: newItemsArrayInthisNode }

            } else {
                return item
            }
        })

        console.log("new array ", new_array)
        setArrayOfNodes(new_array)

    }

    const typedRemark = (nodeCode, nodeItemCode, typedText) => {

        const new_array = arrayOfNodes.map((item) => {

            if (item.node_code == nodeCode) {

                let newItemsArrayInthisNode = item.node_items.map((itemInside) => {

                    if (itemInside.node_item_code == nodeItemCode) {

                        console.log("itemInside if ", itemInside)
                        return { ...itemInside, remarks: typedText }

                    } else {

                        console.log("itemInside else ", itemInside)
                        return itemInside
                    }

                })

                return { ...item, node_items: newItemsArrayInthisNode }

            } else {
                return item
            }
        })

        console.log("new array ", new_array)
        setArrayOfNodes(new_array)
    }

    const callAPI = () => {

        const serializedJSON = JSON.stringify(arrayOfNodes);

        let objectToSend = {
            name_of_staff: nameOfStaff,
            name_of_company: nameOfCompany,
            checklist_json_string: serializedJSON
        }

        axios.post(`${process.env.REACT_APP_BASE_URL}/checklist_creation.php`, objectToSend, {
            headers: {
                // Overwrite Axios's automatically set Content-Type
                'Content-Type': 'application/json'
            }
        }).then((res) => {
            console.log("res is ", res);

            if (res.data.status == "task_creation_success") {

                console.log("saved ")

                setResultAfterTask("success")
            } else {

                console.log("something went wrong ")
                setResultAfterTask("failure")
            }
        }).catch(err => {
            // setResultofRollReg("failure")

            console.log("error is ", err)
        });

    }

    return (
        <div>

            <Header />

            <div className="container d-flex justify-content-between my-2">
                <h4 className="mt-2">Checklist</h4>
                <button className="btn btn-primary" onClick={() => navigate("/admindashboard/previous_checklist")}>View Previous checklist</button>
            </div>

            <div className="container checklist_table_container">
                <table className="checklist_table">
                    {
                        arrayOfNodes.map((item) => {
                            return (

                                <>
                                    <tbody >
                                        <tr className="node_name mt-3">
                                            <td colSpan="3" className="checklist_table_subheading">
                                                {item.node_name}
                                            </td>
                                        </tr>
                                    </tbody>

                                    {
                                        item.node_items.map((itemInside) => {

                                            return (
                                                <tbody>
                                                    <tr className="checklist_table_single_row">
                                                        <td onClick={() => clickedCheckboxOnItem(item.node_code, itemInside.node_item_code)}>
                                                            {itemInside.checkbox_status ? <img className="checkbox_image" src={ic_checkbox} /> : <img className="checkbox_image" src={ic_normal_checkbox} />}
                                                        </td>
                                                        <td>
                                                            {itemInside.name}
                                                        </td>
                                                        <td>
                                                            <input type="text" placeholder="remarks" onChange={(e) => typedRemark(item.node_code, itemInside.node_item_code, e.target.value)} />
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            )

                                        })
                                    }

                                </>

                            )
                        })
                    }
                </table>
            </div>

            <div className="container mt-2 p-4 checklist_bottom_container">
                <input type="text" placeholder="enter staff name" onChange={(e) => handleOnChangeNameOfStaff(e.target.value)} />
                <input type="text" placeholder="enter company name" onChange={(e) => handleOnChangeNameOfCompany(e.target.value)} />
                <button className="btn btn-warning" onClick={() => callAPI()}>Save </button>
            </div>

            {
                resultAfterTask.length > 0 ?
                    resultAfterTask == "success" ?
                        <div className="popup_header_banner">
                            <div className="w-100 bg-success p-2 d-flex justify-content-center">
                                <label>Checklist Saved Successfully</label>
                                <button className="btn btn-warning py-0 ms-2" onClick={() => setResultAfterTask("")}>OK</button>
                            </div>
                        </div>
                        :
                        <div className="popup_header_banner">
                            <div className="w-100 bg-danger p-2 d-flex justify-content-center">
                                <label>Something went wrong, please try later</label>
                                <button className="btn btn-warning py-0 ms-2" onClick={() => setResultAfterTask("")}>OK</button>
                            </div>
                        </div>
                    :
                    null
            }

        </div>
    )
}

export default Checklist;