import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import "../css/TicketDetailsSupport.css";
import ic_assigned_to_staff from "../img/ic_assigned_to_staff.png";
import ic_check_mark from "../img/ic_check_mark.png";
import ic_escalated from "../img/ic_escalated.png";
import ic_opened from "../img/ic_opened.png";
import Header from "./Header";
import ChatBox from "./ChatBox";
import ic_time_taken from "../img/ic_time_taken.png"

function TicketDetailsSupport() {

    const [ticketMasterTableState, setTicketMasterTableState] = useState(null);
    const [ticketActivityState, setTicketActivityState] = useState(null);

    let { id } = useParams();

    const getTicketMasterTableDetails = () => {
        axios.post(`${process.env.REACT_APP_BASE_URL}/get-ticket-master-table-details.php`, { ticket_number: id }).then((res) => {

            console.log("res master  is  ", res);
            setTicketMasterTableState(res.data);

        }).catch((err) => {
            console.log("err is ", err);
        })
    }

    const getLogOfParticularTicket = () => {
        axios.post(`${process.env.REACT_APP_BASE_URL}/get-details-of-a-customer-ticket.php`, { ticket_number: id }).then((res) => {

            console.log("res activity list is  ", res);
            setTicketActivityState(res.data);

        }).catch((err) => {
            console.log("err is ", err);
        })
    }

    useEffect(() => {
        getTicketMasterTableDetails();
        getLogOfParticularTicket();
    }, [])

    return (
        <div className="TicketDetailsSupport-root-container">

            <Header />
            <div className="TicketDetailsSupport-main-container">

                {
                    ticketMasterTableState != null ?
                        ticketMasterTableState.status = "success" ?
                            <div>
                                <div className="TicketDetailsSupport-panel">
                                    <label className="TicketDetailsSupport-panel-label">Ticket Number </label>
                                    <label className="TicketDetailsSupport-panel-value">{id}</label>
                                </div>
                                <div className="TicketDetailsSupport-panel">
                                    <label className="TicketDetailsSupport-panel-label">Issue Description </label>
                                    <label className="TicketDetailsSupport-panel-value">{ticketMasterTableState.activity_list_array[0].ticket_description}</label>
                                </div>
                                <div className="TicketDetailsSupport-panel">
                                    <label className="TicketDetailsSupport-panel-label">Company code </label>
                                    <label className="TicketDetailsSupport-panel-value">{ticketMasterTableState.activity_list_array[0].company_code}</label>
                                </div>
                                {
                                    ticketMasterTableState.activity_list_array[0].ticket_remarks == "ticket from cubix staff" ?
                                        <div className="TicketDetailsSupport-panel">
                                            <label className="TicketDetailsSupport-panel-label">Ticket Created By </label>
                                            <label className="TicketDetailsSupport-panel-value">By Cubix Staff</label>
                                        </div>
                                        :
                                        <div className="TicketDetailsSupport-panel">
                                            <label className="TicketDetailsSupport-panel-label">Ticket Created By</label>

                                            <label className="TicketDetailsSupport-panel-value">{ticketMasterTableState.activity_list_array[0].ticket_person_name}</label>
                                            <label className="TicketDetailsSupport-panel-value">{ticketMasterTableState.activity_list_array[0].ticket_assigned_by}</label>
                                        </div>
                                }

                                <div className="TicketDetailsSupport-panel">
                                    <label className="TicketDetailsSupport-panel-label">Mobile Number </label>
                                    <label className="TicketDetailsSupport-panel-value">{ticketMasterTableState.activity_list_array[0].mobile_number}</label>
                                </div>

                                <div className="TicketDetailsSupport-panel">
                                    <label>Attached Images </label>
                                </div>
                                <div className="mt-2">

                                    {ticketMasterTableState.activity_list_array[0].names_of_files_uploaded.length != 0 ?
                                        ticketMasterTableState.activity_list_array[0].names_of_files_uploaded.split(',').map((item) => {
                                            return (
                                                <a href={`${process.env.REACT_APP_BASE_URL}/uploads/ticket_images/${item}`}>
                                                    <label className="TicketDetailsSupport-attached-file-name-bg me-4">{item}</label>
                                                </a>
                                            )
                                        })
                                        :
                                        <p>No Files Attached</p>
                                    }
                                </div>
                            </div>
                            :
                            <p>No details found</p>
                        :
                        <div>
                            <p>------ </p>
                        </div>
                }




                <label className="TicketDetailsSupport-ticket-log-details mt-4">Ticket Log Details </label>
                <span className="TicketDetailsSupport-ticket-log-details-underline mt-2"></span>

                {
                    ticketMasterTableState != null ?
                        ticketMasterTableState.status = "success" ?
                            <div className="TicketDetails-activity-list-single-item mt-4">

                                {new Date(ticketMasterTableState.activity_list_array[0].ticket_created_on).getDate() +
                                    " / " + (new Date(ticketMasterTableState.activity_list_array[0].ticket_created_on).getMonth() + 1) +
                                    " / " + (new Date(ticketMasterTableState.activity_list_array[0].ticket_created_on).getFullYear()) +
                                    " -- " + (new Date(ticketMasterTableState.activity_list_array[0].ticket_created_on).getHours()) +
                                    " : " + (new Date(ticketMasterTableState.activity_list_array[0].ticket_created_on).getMinutes())
                                }

                                <div className="TicketDetailsSupport-ticket-opened-panel">
                                    <img className="TicketDetails-activity-list-icon" src={ic_opened} />
                                    <label>OPENED</label>
                                </div>

                            </div>
                            :
                            null
                        :
                        null
                }

                {
                    ticketActivityState != null ?
                        <div className="TicketDetails-activity-list-container">
                            {
                                ticketActivityState.status == "get_activity_list_success" ?
                                    <div>
                                        {ticketActivityState.activity_list_array.map((item) => {
                                            return (
                                                <div>
                                                    <div className="TicketDetails-activity-list-single-item mt-4">

                                                        <div className="TicketDetails-date-remark-container">

                                                            <div className="TicketDetails-datetime-and-timetaken">

                                                                <label>
                                                                    {new Date(item.ticket_status_changed_on).getDate() +
                                                                        " / " + (new Date(item.ticket_status_changed_on).getMonth() + 1) +
                                                                        " / " + (new Date(item.ticket_status_changed_on).getFullYear()) +
                                                                        " -- " + (new Date(item.ticket_status_changed_on).getHours()) +
                                                                        " : " + (new Date(item.ticket_status_changed_on).getMinutes())
                                                                    }

                                                                </label>
                                                                <label className="TicketDetails-time-taken-label-container">
                                                                    <img src={ic_time_taken} className="me-2"/>
                                                                    {
                                                                        item.time_taken_in_minutes + " min"
                                                                    }
                                                                    
                                                                </label>
                                                            </div>

                                                            <span className="TicketDetails-single-activity-bottom-border"></span>

                                                            <div><label className="me-4 mt-3">Support Agent : </label> {item.staff_name}</div>


                                                            {
                                                                item.remarks.length != 0 ?
                                                                    <div>
                                                                        <div className="mt-3"><label>Remarks</label></div>
                                                                        <label className="mt-2 Ticket-remark-label">{item.remarks}</label>
                                                                    </div>
                                                                    :
                                                                    null
                                                            }

                                                        </div>



                                                        {item.ticket_current_status == 2 ? <div className="TicketDetails-current-staus-image-container"><img className="TicketDetails-activity-list-icon" src={ic_check_mark} /><label>CLOSED</label></div> : ""}
                                                        {item.ticket_current_status == 3 ? <div className="TicketDetails-current-staus-image-container"><img className="TicketDetails-activity-list-icon" src={ic_assigned_to_staff} /><label>ASSIGNED</label></div> : ""}
                                                        {item.ticket_current_status == 4 ? <div className="TicketDetails-current-staus-image-container"><img className="TicketDetails-activity-list-icon" src={ic_escalated} /><label>ESCALATED</label></div> : ""}


                                                    </div>

                                                </div>
                                            )
                                        })}
                                    </div>
                                    :
                                    <div>
                                    </div>
                            }
                        </div>
                        :
                        <div>
                        </div>
                }


            </div>

            <ChatBox ticketId={id} senderType={"support_agent"} />

        </div>
    )
}

export default TicketDetailsSupport;