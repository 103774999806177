import axios from "axios";
import React, { useEffect, useState , useRef} from "react";
import "../css/CustomerPreviousTickets.css";
import side_banner from "../img/side_banner_cubix_login.png";
import ic_check_mark from "../img/ic_check_mark.png"; 

function CustomerPreviousTickets(){

    const ref = useRef({
        currentPageNumberRef: 1
    });
    
    
    const base_url = "http://185.140.249.224:3001";
     //const base_url = "http://localhost:3001";

    const [currentSelectedItem , setCurrentSelectedItem] = useState(0);
    const idOfClient =  localStorage.getItem("cubix_support_idOfClient") ;

    const [arrayOfTickets, setArrayOfTickets] = useState([]);
    const [arrayOfTicketImages, setArrayOfTicketImages] = useState([]);

    // states needed for pagination
    const [currentPageNumberState, setCurrentPageNumberState] = useState(1);
    const [totalPageNumberState, setTotalPageNumberState] = useState(0);
    const [ticketStatusTextArrayState, setTicketStatusTextArrayState] = useState([]);
    const [ticketStatusFilterState, setTicketStatusFilterState] = useState([]);

    const getPreviousTicketsFromServer = ()=>{

        console.log("typeof idOClient is ", typeof(idOfClient));

        axios.post(`${base_url}/get-previous-tickets`, {idOfClient:idOfClient, currentPageNumberState: currentPageNumberState }).
        then((res)=>{

            console.log("front end success ", res);

            if(res.data.isPreviousTicketCollected){
                

            // select first aitem automatically on every new ticket list ie every new api call
            // so set currentSelectedItem to zero
            // when i put this statement below setArrayOfTickets(res.data.resultData); it showed error
            // when selecting 76th, 75, 74 and clicking next
            // because then currentselect index is 2, 3, 4 ie greater than 1 by the time new array came with size 2
            // so state also changed , then array gets looped still if index is greater than 1 index is not found
            // then array index not found exception occurs
            setCurrentSelectedItem(0);

            setArrayOfTickets(res.data.resultData);
           
            setArrayOfTicketImages(res.data.imagePathArray);

            if(currentPageNumberState == 1){
                let tct = res.data.countOfRows[0].row_count_of_tickets;
                let tpp = res.data.itemsPerPage;
                let totalPageNumber = Math.ceil(tct/tpp);

                setTotalPageNumberState(totalPageNumber);

                setTicketStatusTextArrayState(res.data.ticketStatusTextArray);

                console.log("numberOfRows.....itemsPerPage.....totalPageNumber", tct,tpp,totalPageNumber);
            }else{
                console.log(" currentPageNumberState not equalt to zero.. we are currently on this page index ",currentPageNumberState );
            }

            
        }else{
            console.log("couldn't get previous tickets");
        }
           
        }).catch(err => console.log("fron end error ", err));
        

    }

    const selectListItem=(index)=>{
        setCurrentSelectedItem(index);
    }

    useEffect(()=>{
        getPreviousTicketsFromServer();
        console.log("Component Mounted ->>>>>>>>>>");
    },[]);

    const getItemsAfterFirstCall = (buttonClickText)=>{

        let movementDirection = buttonClickText;
        let side_list_item_ticket_number;

        if( movementDirection == "next"){
            side_list_item_ticket_number = arrayOfTickets[4].ticket_number;
        }else{
            side_list_item_ticket_number = arrayOfTickets[0].ticket_number;
        }
        
        axios.post(`${base_url}/get-previous-tickets`, {idOfClient:idOfClient, currentPageNumberState: ref.current.currentPageNumberRef, last_item_ticket_number: side_list_item_ticket_number, movementDirection: movementDirection}).
        then((res)=>{

            console.log("front end success getItemsAfterFirstCall ", res);
            // select first aitem automatically on every new ticket list ie every new api call
            // so set currentSelectedItem to zero
            setCurrentSelectedItem(0);

            setArrayOfTickets(res.data.resultData);
            
            setArrayOfTicketImages(res.data.imagePathArray);
           
        }).catch(err => console.log("fron end error ", err));

    }

    const getPreviousItems =()=>{

        if(currentPageNumberState - 1 > 0 ) {
            // call api
            console.log("getPreviousItems clicked currentPageNumberState - 1 > 0 ", currentPageNumberState);
            
            ref.current.currentPageNumberRef = currentPageNumberState - 1;

            setCurrentPageNumberState(prev => prev - 1 );

            getItemsAfterFirstCall("previous");
        }else{
            console.log("getPreviousItems clicked currentPageNumberState - 1 = 0,  currentPageNumberState ",  currentPageNumberState);
        }
        
    }

    const getNextItems = ()=>{
        if( currentPageNumberState != 0){
            if(currentPageNumberState + 1 <= totalPageNumberState){
                console.log("getNextItems clicked less than 3");

                ref.current.currentPageNumberRef = currentPageNumberState +1;
                setCurrentPageNumberState(prev => prev + 1);
                getItemsAfterFirstCall("next");
            }else{
                console.log("getNextItems clicked else xxxxx greater than 3")
            }
        }else{
            console.log("clicked getNextItems currentPageNumberState is 0");
        }
    }

    return(
        
        <div className="CustomerPreviousTickets-root-container">
        
        {console.log("page re rendered") } 
        
        <div className="CustomerPreviousTickets-left-right-container">

        {/* left section */}

            <div className="CustomerPreviousTickets-left-container">

                <ul className="CustomerPreviousTickets-list-container">

                {arrayOfTickets.length > 0 ?  arrayOfTickets.map((item, index)=>{

                    return(
                    <li key={index} className={  currentSelectedItem == index ? "selected" :  "not-selected"} onClick={()=> selectListItem(index)}>
                        <div className="CustomerPreviousTickets-list-ticket-number">
                           
                            <div className="d-flex flex-column">
                                <label>Ticket Number</label>
                                <p className="mt-2">{item.ticket_number}</p>
                            </div>
                            <div className="d-flex CustomerPreviousTickets-list-ticket-number-date">  
                                <label className="d-block">{new Date(item.created_at).getDate() + "/" + (new Date(item.created_at).getMonth() + 1)  +  "/" + new Date(item.created_at).getFullYear() + "  " + new Date(item.created_at).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}</label>
                            </div>
                        </div>
                    </li>
                    )
                }) : "no data"}
                    
                </ul>

            <div className="CustomerPreviousTickets-left-pagination-container">

                {currentPageNumberState == 1 ? null : <button onClick={getPreviousItems}>&lt;&lt; Previous  </button>}
                
                <label>Page {currentPageNumberState} of <span>{totalPageNumberState}</span></label>

                {currentPageNumberState == totalPageNumberState ? null : <button onClick={getNextItems}>Next &gt;&gt;</button>}
                
            </div>
                
            </div>

            {/* right section */}
            <div className="CustomerPreviousTickets-right-container">

                <div className="CustomerPreviousTickets-right-container-status-board">
                    <label>Ticket Status</label>
                    { console.log("moment before crash currentSelectedItem is ", currentSelectedItem)}
                    <span>
                        
                        {
                        arrayOfTickets.length > 0 ? 

                        ticketStatusTextArrayState.length > 0 ? 
                        ticketStatusTextArrayState.filter(item => item.id == arrayOfTickets[currentSelectedItem].ticket_status)[0].work_status_text
                        : ""
                         
                        :""
                        }
                    </span>
                </div>

                    <div className="CustomerPreviousTickets-full-details">
                        <div className="mt-2">
                            <label>Name</label><span>{arrayOfTickets.length > 0 ? arrayOfTickets[currentSelectedItem].person_name : ""}</span>
                        </div>
                        <div className="mt-2">
                            <label>Email</label><span>{arrayOfTickets.length > 0 ? arrayOfTickets[currentSelectedItem].person_email :""}</span>
                        </div>
                        <div className="mt-2">
                            <label>Mobile</label><span>{arrayOfTickets.length > 0 ? arrayOfTickets[currentSelectedItem].person_mobile: ""}</span>
                        </div>
                        <div className="mt-2">
                            <label>Issue</label><span>{arrayOfTickets.length > 0 ? arrayOfTickets[currentSelectedItem].issue :""}</span>
                        </div>
                    </div>
                <hr></hr>
                <div className="CustomerPreviousTickets-full-details-image">
                    <label>Attached Images</label>
                    <div className="d-flex CustomerPreviousTickets-img-container">

                    {
                        
                        arrayOfTickets.length > 0 ? 
                        arrayOfTicketImages.filter((item) =>item.ticket_number == arrayOfTickets[currentSelectedItem].ticket_number).length > 0 ? 
                        arrayOfTicketImages.filter((item) =>item.ticket_number == arrayOfTickets[currentSelectedItem].ticket_number).map((item,index)=>{
                            return(
                            <img key={index} src={base_url + item.image_path} />
                            )
                        }) : <p className="CustomerPreviousTickets-no-image-uploaded">No image uploaded </p>  
                        : 
                        <p>Loading... </p> 
                        
                     }

                    </div>
                </div>
            </div>

        </div>
        
        </div>
    )
    

}

export default CustomerPreviousTickets;