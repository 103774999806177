import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ClientLogin from './components/ClientLogin';
import ProtectedRoute from './components/ProtectedRoute';
import TabHeader from './components/TabHeader';
import CustomerCreateTickets from './components/CustomerCreateTickets';
import CustomerPreviousTickets from './components/CustomerPreviousTickets';
import AdminLogin from './components/AdminLogin';
import ClientRegistration from './components/ClientRegistration';
import ProtectedRouteAdmin from './components/ProtectedRouteAdmin';
import AdminCompanyReport from './components/AdminCompanyReport';
import AdminCompanyFullReport from './components/AdminCompanyFullReport';
import Companies from './components/Companies';
import AllTasks from './components/AllTasks';
import AllTickets from './components/AllTickets';
import Sales from './components/Sales';
import CustomerDetails from './components/CustomerDetails';
import ProjectProductManager from './components/ProjectProductManager';
import WebSocketDemo from './components/WebSocketDemo';
import ReportIssue from './components/ReportIssue';
import AllTicketsFromCustomer from './components/AllTicketsFromCustomer';
import AdminDashboardSupport from './components/AdminDashboardSupport';
import TicketDetailsSupport from './components/TicketDetailsSupport';
import ViewDetailsOfIssue from './components/ViewDetailsOfIssue';
import ViewClosedTicketsOfACompany from './components/ViewClosedTicketsOfACompany';
import ViewReports from './components/ViewReports';
import AmcOfCompanies from './components/AmcOfComapnies';
import ReportIssueByCubixStaff from './components/ReportIssueByCubixStaff';
import AnalyticsPageAdmin from './components/AnalyticsPageAdmin';
import AdminDashboardTvMonitor from './components/AdminDashboardTvMonitor';
import Checklist from './components/Checklist';
import PreviousChecklist from './components/PreviuosChecklist';

function App() {

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route exact path="/" element={<ClientLogin />} />
        <Route path="/registration" element={<ClientRegistration />} />

        <Route path="/report_issue/:idOfCompany/:nameOfUser" element={<ReportIssue />} />

        <Route path="/report_issue/:idOfCompany/by_cubix_staff" element={<ReportIssueByCubixStaff />} />

        <Route path="/view_details_of_issue/:idOfTicket" element={<ViewDetailsOfIssue />} />
        <Route path="/view_details_of_issue/:idOfTicket/:actionToDo" element={<ViewDetailsOfIssue />} />

        <Route path="/view_closed_tickets/:idOfCompany" element={<ViewClosedTicketsOfACompany />} />

        <Route path="/admin_login" element={<AdminLogin />} />

        <Route
          path="/admindashboard"
          element={
            <ProtectedRouteAdmin>
              <AdminDashboardSupport />
            </ProtectedRouteAdmin>
          }
        />

        <Route
          path="/admindashboard/checklist"
          element={
            <ProtectedRouteAdmin>
              <Checklist />
            </ProtectedRouteAdmin>
          }
        />

        <Route
          path="/admindashboard/previous_checklist"
          element={
            <ProtectedRouteAdmin>
              <PreviousChecklist />
            </ProtectedRouteAdmin>
          }
        />


        <Route
          path="/admindashboard_tv_monitor"
          element={
            <ProtectedRouteAdmin>
              <AdminDashboardTvMonitor />
            </ProtectedRouteAdmin>
          }
        />

        <Route
          path="/admindashboard/ticket-details/:id"
          element={
            <ProtectedRouteAdmin >
              <TicketDetailsSupport />
            </ProtectedRouteAdmin>
          }
        />

        <Route
          path="/admindashboard/view_reports"
          element={
            <ProtectedRouteAdmin >
              <ViewReports />
            </ProtectedRouteAdmin>
          }
        />

        <Route
          path="/admindashboard/analytics"
          element={
            <ProtectedRouteAdmin>
              <AnalyticsPageAdmin />
            </ProtectedRouteAdmin>
          }
        />

        <Route
          path="/admindashboard/customer-details"
          element={
            <ProtectedRouteAdmin>
              <CustomerDetails />
            </ProtectedRouteAdmin>
          }
        />

        <Route
          path="/admindashboard/amc-details"
          element={
            <ProtectedRouteAdmin>
              <AmcOfCompanies />
            </ProtectedRouteAdmin>
          }
        />

        <Route
          path="/admindashboard/sales_and_marketing"
          element={
            <ProtectedRouteAdmin>
              <Sales />
            </ProtectedRouteAdmin>
          }
        />


        <Route
          path="/projects_and_products"
          element={
            <ProtectedRouteAdmin>
              <ProjectProductManager />
            </ProtectedRouteAdmin>
          }
        />

        <Route
          path="/admindashboard/alltasks"
          element={
            <ProtectedRouteAdmin>
              <AllTasks />
            </ProtectedRouteAdmin>
          }
        />

        <Route
          path="/admindashboard/alltickets"
          element={
            <ProtectedRouteAdmin>
              <AllTickets />
            </ProtectedRouteAdmin>
          }
        />

        <Route
          path="/admindashboard/allticketsfromcustomers"
          element={
            <ProtectedRouteAdmin>
              <AllTicketsFromCustomer />
            </ProtectedRouteAdmin>
          }
        />

        <Route
          path="/admindashboard/allticketsfromcustomers/:company_code"
          element={
            <ProtectedRouteAdmin>
              <AllTicketsFromCustomer />
            </ProtectedRouteAdmin>
          }
        />
        <Route
          path="/admindashboard/reports"
          element={
            <ProtectedRouteAdmin>
              <AdminCompanyReport />
            </ProtectedRouteAdmin>
          } />

        <Route
          path="/admindashboard/fullreports"
          element={
            <ProtectedRouteAdmin>
              <AdminCompanyFullReport />
            </ProtectedRouteAdmin>
          } />

        <Route
          path="/dashboard/:idOfClient/createticket"
          element={
            <ProtectedRoute>
              <TabHeader />
              <CustomerCreateTickets />
            </ProtectedRoute>
          }
        />

        <Route
          path="/dashboard/:uname/previoustickets"
          element={
            <ProtectedRoute>
              <TabHeader />
              <CustomerPreviousTickets />
            </ProtectedRoute>
          }
        />

        <Route path="/admindashboard/companies"
          element={
            <ProtectedRouteAdmin>
              <Companies />
            </ProtectedRouteAdmin>} />

        <Route path="/websocketdemo"
          element={
            <ProtectedRouteAdmin>
              <WebSocketDemo />
            </ProtectedRouteAdmin>} />

      </Routes>
    </Router>
  )
}

export default App;
