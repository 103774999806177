import React, { useEffect, useRef } from "react";
import "../css/CreateTaskPopupContainer.css";
import ic_calendar from "../img/ic_calendar.png";
import ic_time from "../img/ic_time.png";
import ic_daily_task from "../img/ic_daily_task.png";
import { useState } from "react";
import axios from "axios";
import LoadingLayout from "./LoadingLayout";

function CreateTaskPopupContainer(props) {

    const [showLoadingLayout,setShowLoadingLayout] = useState(false);
    let currentTextInSearchField = useRef("");
    const [startCompanyNameFiltering, setStartCompanyNameFiltering] = useState(false);
    let timeInterval = null;
    let copyOfAllCompanyList = useRef([]);
    const [showCompanyNameDropDown, setShowCompanyNameDropDown] = useState(false);
    //const [showAssignToDropDown, setShowAssignToDropDown] = useState(false);
    const [showAssignedByDropDown, setShowAssignedByDropDown] = useState(false);
    const [cubixTaskTicketStaffNameArrayState, setCubixTaskTicketStaffNameArray] = useState(null);
    const [cubixTaskTicketCompanyNameArrayState, setCubixTaskTicketCompanyNameArray] = useState(null);
    const [errorMessageState, setErrorMessageState] = useState("");

    const [companySearchTextState, setCompanySearchTextState] = useState("");

    const { showHideCreateNewTaskPopup } = props;
    const initialInputState = {
        input_task_description: "",
        input_task_remarks: "",
        input_task_company_name: 0,
        input_task_assigned_to: 0,
        input_task_assigned_by: 0,
        input_task_should_be_completed_on: "",
        input_task_completion_status: 1,
    }
    const [taskInputState, setTaskInputState] = useState(initialInputState);

    const handleInput = (e) => {
        setErrorMessageState("");
        setTaskInputState({ ...taskInputState, [e.target.name]: e.target.value });
    }

    useEffect(() => {

        if (startCompanyNameFiltering) {

            setStartCompanyNameFiltering(false);

            console.log("CreateTaskPopupContainer >>>>>>> ", "startCompanyNameFiltering true");

            if (currentTextInSearchField.current.length > 0) {
                console.log("CreateTaskPopupContainer >>>>>>> ", currentTextInSearchField.current);
               
                setCubixTaskTicketCompanyNameArray(
                    copyOfAllCompanyList.current.filter((item) => {
                        return item.company_name.toLowerCase().includes(currentTextInSearchField.current.toLowerCase())
                    })
                )
            }
            
        }else{
            console.log("CreateTaskPopupContainer >>>>>>> ", "startCompanyNameFiltering false");
        }

    }, [startCompanyNameFiltering])

    const handleCompanySearchInput = (e) => {

        console.log(" searchtext company changed is", e.target.value);

        setCompanySearchTextState(e.target.value);
        currentTextInSearchField.current = e.target.value;

        clearInterval(timeInterval);

        timeInterval = setTimeout(() => {
            if (e.target.value.length > 0) {
                if (timeInterval) {
                    console.log("e.target.value.length  > 0timer is not null we are clearing it")
                    clearInterval(timeInterval);
                }
                setStartCompanyNameFiltering(true);
            } else {
                if (timeInterval) {
                    console.log("e.target.value.length == 0 timer is not null we are clearing it")
                    clearInterval(timeInterval);
                }
                setStartCompanyNameFiltering(false);
                setCubixTaskTicketCompanyNameArray(copyOfAllCompanyList.current);
            }
        }, 1000)
    }

    const sendDataToApi = () => {
        console.log("taskInputState ", taskInputState);

        if (taskInputState.input_task_description.length > 0 && taskInputState.input_task_assigned_by > 0) {
            
            setShowLoadingLayout(true);

            axios.post(`${process.env.REACT_APP_BASE_URL}/create-task.php`, taskInputState).
                then((res) => {
                    console.log(" task creation res is ", res);
                    setShowLoadingLayout(false);
                    if (res.data.status = "task_creation_success") {
                        window.location.reload();
                        showHideCreateNewTaskPopup();
                    }
                }).
                catch((err) => {
                    setShowLoadingLayout(false);
                    console.log("err is ", err);
                })
        } else {
            setErrorMessageState("Either Description or assigned by not filled")
        }

    }

    const getFromLocalStorageStaffArray = () => {
        let cubixTaskTicketStaffNameArray = sessionStorage.getItem("cubix_task_ticket_staff_name_array");
        setCubixTaskTicketStaffNameArray(JSON.parse(cubixTaskTicketStaffNameArray))
    }

    const getFromLocalStorageCompanyNameArray = () => {
        let cubixTaskTicketCompanyNameArray = sessionStorage.getItem("cubix_task_ticket_company_name_array");
        
        // for future filtering so we need an original source of company name array
        // because after filtering we set filtered company array to state 
        copyOfAllCompanyList.current = JSON.parse(cubixTaskTicketCompanyNameArray);

        setCubixTaskTicketCompanyNameArray(JSON.parse(cubixTaskTicketCompanyNameArray))
    }

    useEffect(() => {
        getFromLocalStorageStaffArray();
        getFromLocalStorageCompanyNameArray();
    }, [])



    return (
        <div className="CreateTaskPopupContainer-root-container">

            <img className="CreateTaskPopupContainer-side-clip-image" src={ic_daily_task} />
            <div className="CreateTaskPopupContainer-inner-card">
                <h6>Create Task</h6>

                <label className="mt-4">Enter task description</label>
                <input className="mt-2" type="text" name="input_task_description" value={taskInputState.input_task_description} onChange={handleInput} />

                <label className="mt-4">Enter remarks</label>
                <input className="mt-2" type="text" name="input_task_remarks" value={taskInputState.input_task_remarks} onChange={handleInput} />


                <div className="CreateTaskPopupContainer-staff-dropdown-anchorand-list-container ">
                    <label className="mt-4">Select company</label>

                    <div className="CreateTaskPopupContainer-dropdown-picker mt-2" onClick={() => setShowCompanyNameDropDown(prev => !prev)} >

                        {
                            cubixTaskTicketCompanyNameArrayState != null ?
                                cubixTaskTicketCompanyNameArrayState.length > 0 ?

                                    cubixTaskTicketCompanyNameArrayState.filter((item) => {
                                        return (
                                            item.id == taskInputState.input_task_company_name
                                        )
                                    }).length > 0 ?
                                        cubixTaskTicketCompanyNameArrayState.filter((item) => {
                                            return (
                                                item.id == taskInputState.input_task_company_name
                                            )
                                        })[0].company_name
                                        :
                                        null
                                    :
                                    null
                                :
                                null
                        }
                    </div>
                    {showCompanyNameDropDown &&
                        <div className="CreateTaskPopupContainer_staff_name_dropdown_item_container">

                            <input type="text" className="CreateTaskPopupContainer-company-search-box"placeholder="Search company name" value={companySearchTextState} onChange={handleCompanySearchInput} />

                            {
                                cubixTaskTicketCompanyNameArrayState != null ?
                                    cubixTaskTicketCompanyNameArrayState.length > 0 ?
                                        cubixTaskTicketCompanyNameArrayState.map((item) => {
                                            return (
                                                <span key={item.id} className="CreateTaskPopupContainer_staff_name_dropdown_item" id={item.id} onClick={() => { setTaskInputState({ ...taskInputState, input_task_company_name: item.id }); setShowCompanyNameDropDown(prev => !prev) }}>{item.company_name}</span>
                                            )
                                        })
                                        :
                                        null
                                    :
                                    null
                            }

                        </div>
                    }
                </div>

                <div className="CreateTaskPopupContainer-staff-dropdown-anchorand-list-container ">
                    <label className="mt-4">Assigned By</label>
                    <div className="CreateTaskPopupContainer-dropdown-picker mt-2" onClick={() => setShowAssignedByDropDown(prev => !prev)} >

                        {
                            cubixTaskTicketStaffNameArrayState != null ?
                                cubixTaskTicketStaffNameArrayState.length > 0 ?
                                    cubixTaskTicketStaffNameArrayState.filter((item) => {
                                        return (
                                            item.id == taskInputState.input_task_assigned_by
                                        )
                                    }).length > 0 ?

                                        cubixTaskTicketStaffNameArrayState.filter((item) => {
                                            return (
                                                item.id == taskInputState.input_task_assigned_by
                                            )
                                        })[0].staff_name
                                        :
                                        null
                                    :
                                    null
                                :
                                null
                        }
                    </div>

                    {showAssignedByDropDown &&
                        <div className="CreateTaskPopupContainer_staff_name_dropdown_item_container">
                            {
                                cubixTaskTicketStaffNameArrayState != null ?
                                    cubixTaskTicketStaffNameArrayState.length > 0 ?
                                        cubixTaskTicketStaffNameArrayState.map((item) => {
                                            return (
                                                <span key={item.id} className="CreateTaskPopupContainer_staff_name_dropdown_item" id={item.id} onClick={() => { setErrorMessageState(""); setTaskInputState({ ...taskInputState, input_task_assigned_by: item.id }); setShowAssignedByDropDown(prev => !prev) }}>{item.staff_name}</span>
                                            )
                                        })
                                        :
                                        null
                                    :
                                    null
                            }

                        </div>
                    }
                </div>

                {/* <label className="mt-4">Expected Completion Date and Time</label>
                <div className="CreateTaskPopupContainer-Expected-Completion-Date-Time-container mt-2">
                    <input type="datetime-local" name="input_task_should_be_completed_on" value={taskInputState.input_task_should_be_completed_on} onChange={handleInput}></input>
                </div> */}

                <div className="CreateTaskPopupContainer-error-message-container">
                    <p className="text-danger ">{errorMessageState}</p>
                </div>
                <div className="CreateTaskPopupContainer-cancel-save-container">
                    <button className="CreateTaskPopupContainer-cancel-button me-4" onClick={showHideCreateNewTaskPopup}>Cancel</button>
                    <button className="CreateTaskPopupContainer-save-button" onClick={sendDataToApi}>Save</button>
                </div>

            </div>

            
            {
                showLoadingLayout && <LoadingLayout />
            }

        </div>
    )

}

export default CreateTaskPopupContainer;