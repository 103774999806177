import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "../css/ReportIssue.css";
import "../css/AllTickets.css";
import Header from "./Header";
import ic_ticket from "../img/ic_ticket.png";
import axios from "axios";

function AllTicketsFromCustomer() {

    let { company_code } = useParams();

    const [allIssuesByACompanyState, setAllIssuesByACompanyState] = useState(null);

    const getAllClosedTicketsOfASingleCustomer = () => {
        axios.get(`${process.env.REACT_APP_BASE_URL}/get-all-completed-issues-by-a-company.php?id=${company_code}`).
            then((res) => {
                console.log(" all closed tickets res is ", res);

                setAllIssuesByACompanyState(res.data.opened_tickets_array);

            }).
            catch((err) => {
                console.log("err is ", err);
            })
    }

    const getAllClosedTickets = () => {
        axios.get(`${process.env.REACT_APP_BASE_URL}/get-all-completed-issues-by-all-companies.php`).
            then((res) => {
                console.log(" all closed tickets res is ", res);

                setAllIssuesByACompanyState(res.data.opened_tickets_array);

            }).
            catch((err) => {
                console.log("err is ", err);
            })
    }


    useEffect(() => {

        if (company_code != null) {
            getAllClosedTicketsOfASingleCustomer();
        } else {
            getAllClosedTickets();
        }


    }, [])

    return (
        <div className="AllTickets-root-container">
            <Header filterCompany={"allticketsfromcustomer"}/>
            <div className="AllTickets-root-container-top-panel">
                <img src={ic_ticket} />
                <h4 className="mt-2">Closed Tickets</h4>
            </div>


            <div className="AllTickets-closed-tickets-table-container mt-0 pt-0">


                <table className="AllTickets-closed-tickets-table-admin-view">
                    <thead>
                        <tr>
                            <th>Ticket No.</th>
                            <th>Action</th>
                            <th>Customer</th>
                            <th>Description</th>
                            <th>Mob No.</th>
                            <th>Created On</th>
                            <th>Closed On</th>
                            <th>Closed By</th>
                            <th>Time Taken (min)</th>
                            <th>Closing Remarks</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allIssuesByACompanyState != null ?

                            allIssuesByACompanyState.length != 0 ?
                                allIssuesByACompanyState.map((item) => {
                                    return (

                                        // < div className="ReportIssue-previous-list-single-item ">
                                        //     <div className="AllTickets-from-customer-panel-container-admin-view">
                                        //         <div className="ReportIssue-single-item-left-child-top-panel-company AllTickets-from-customer-company-name-and-view-details">
                                        //             <p className="Previous-Tickets-Container-company-name">{item.company_name}</p>
                                        //             <p>
                                        //                 <a className="" href={`/view_details_of_issue/${item.id}`}>View Details</a>
                                        //             </p>
                                        //         </div>
                                        //         <div className="AllTickets-single-item-left-child-top-panel">
                                        //             <div className="ReportIssue-single-item-left-child-top-panel-child">
                                        //                 <label className="Previous-Tickets-Container-label">Ticket Number</label>
                                        //                 <label className="Previous-Tickets-Container-value">{item.id}</label>
                                        //             </div>
                                        //             <div className="ReportIssue-single-item-left-child-top-panel-child">
                                        //                 <label className="Previous-Tickets-Container-label">Mobile Number</label>
                                        //                 <label className="Previous-Tickets-Container-value">{item.mobile_number}</label>
                                        //             </div>
                                        //             <div className="ReportIssue-single-item-left-child-top-panel-child">
                                        //                 <label className="Previous-Tickets-Container-label">Created On</label>
                                        //                 <label className="Previous-Tickets-Container-value">
                                        //                     {new Date(item.ticket_created_on).getDate() +
                                        //                         " / " + (new Date(item.ticket_created_on).getMonth() + 1) +
                                        //                         " / " + (new Date(item.ticket_created_on).getFullYear()) +
                                        //                         " -- " + (new Date(item.ticket_created_on).getHours()) +
                                        //                         " : " + (new Date(item.ticket_created_on).getMinutes())
                                        //                     }

                                        //                 </label>
                                        //             </div>
                                        //             <div className="ReportIssue-single-item-left-child-top-panel-child">
                                        //                 <label className="Previous-Tickets-Container-label">Support Agent</label>
                                        //                 <label className="Previous-Tickets-Container-value">{item.staff_name}</label>
                                        //             </div>
                                        //         </div>

                                        //         <div className="ReportIssue-single-item-left-child-bottom-panel mt-4">
                                        //             <label className="Previous-Tickets-Container-label">Ticket Description</label>
                                        //             <label className="Previous-Tickets-Container-value">{item.ticket_description}</label>
                                        //         </div>

                                        //         {
                                        //             item.remarks ?

                                        //                 <div className="ReportIssue-single-item-left-child-bottom-panel mt-4">
                                        //                     <label className="Previous-Tickets-Container-label">Closing Remarks</label>
                                        //                     <label className="Previous-Tickets-Container-value">{item.remarks}</label>
                                        //                 </div>
                                        //                 :
                                        //                 null
                                        //         }
                                        //     </div>
                                        //     {/* <div className="ReportIssue-single-item-current-status">
                                        //         <label className="Previous-Tickets-Container-label">Current Status</label>
                                        //         <label className="Previous-Tickets-Container-value">

                                        //             {item.ticket_current_status == 1 ? <label>OPENED</label> : ""}
                                        //             {item.ticket_current_status == 2 ? <label>CLOSED</label> : ""}
                                        //             {item.ticket_current_status == 3 ? <label>ASSIGNED</label> : ""}
                                        //             {item.ticket_current_status == 4 ? <label>ESCALATED</label> : ""}
                                        //         </label>
                                        //     </div> */}

                                        // </div>

                                        <tr>
                                            <td>
                                                <label className="Previous-Tickets-Container-value">{item.id}</label>
                                            </td>
                                            <td>
                                                <a className="AllTickets-closed-tickets-table-admin-view-details-button" href={`/admindashboard/ticket-details/${item.id}`}>View</a>
                                            </td>
                                            <td>
                                                <p className="AllTickets-closed-tickets-table-company-name">{item.company_name}</p>
                                            </td>
                                            <td>
                                                <label className="AllTickets-closed-tickets-table-date">{item.ticket_description}</label>
                                            </td>
                                            <td>
                                                <label className="AllTickets-closed-tickets-table-contact-number">{item.mobile_number}</label>
                                            </td>
                                            <td>
                                                <label className="AllTickets-closed-tickets-table-date">
                                                    {new Date(item.ticket_created_on).getDate() +
                                                        " / " + (new Date(item.ticket_created_on).getMonth() + 1) +
                                                        " / " + (new Date(item.ticket_created_on).getFullYear()) +
                                                        " -- " + (new Date(item.ticket_created_on).getHours()) +
                                                        " : " + (new Date(item.ticket_created_on).getMinutes())
                                                    }

                                                </label>
                                            </td>
                                            <td>
                                                <label className="AllTickets-closed-tickets-table-date">
                                                    {new Date(item.ticket_status_changed_on).getDate() +
                                                        " / " + (new Date(item.ticket_status_changed_on).getMonth() + 1) +
                                                        " / " + (new Date(item.ticket_status_changed_on).getFullYear()) +
                                                        " -- " + (new Date(item.ticket_status_changed_on).getHours()) +
                                                        " : " + (new Date(item.ticket_status_changed_on).getMinutes())
                                                    }

                                                </label>
                                            </td>
                                            <td>
                                                <label className="AllTickets-closed-tickets-table-date">{item.staff_name}</label>
                                            </td>
                                            
                                            <td>
                                                <label>{item.time_taken_in_minutes}</label>
                                            </td>

                                            <td>
                                                {
                                                    item.remarks ?

                                                        <label className="AllTickets-closed-tickets-table-date">{item.remarks}</label>
                                                        :
                                                        null
                                                }
                                            </td>

                                        </tr>


                                    )
                                })
                                :
                                <p className="mt-4">No Tickets To Show... </p>

                            :
                            <p className="mt-4">No Data To Show </p>

                        }
                    </tbody>

                </table>


            </div>

        </div>
    )
}

export default AllTicketsFromCustomer;