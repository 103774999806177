import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "../css/ReportIssue.css";
import "../css/ViewClosedTicketsOfACompany.css";
import axios from "axios";
import logo_cubix from "../img/logo_cubix.png";
import ic_search from "../img/ic_search_customer.png";

function ViewClosedTicketsOfACompany() {

    const [isCallingApi, setIsCallingApi] = useState(false);
    const [arrayOfSearchResults, setArrayOfSearchResults] = useState(null)
    const [showHideSearchListContainer, setShowHideSearchListContainer] = useState(false);
    let timeInterval = null;
    let { idOfCompany, nameOfUser } = useParams();
    const [allIssuesByACompanyState, setAllIssuesByACompanyState] = useState(null);

    const getAllClosedTicketsByACustomer = () => {

        axios.get(`${process.env.REACT_APP_BASE_URL}/get-all-completed-issues-by-a-company.php?id=${idOfCompany}`).then((res) => {

            console.log("res is issues by a customer ", res);

            setAllIssuesByACompanyState(res.data.opened_tickets_array)

        }).catch((err) => {
            console.log("err is ", err);
        })

    }

    const callApi = (searchingText) => {

        axios.post(`${process.env.REACT_APP_BASE_URL}/search-in-tickets.php`, { typed_text: searchingText, company_code: idOfCompany}).then((res) => {

            console.log("companys search res is ", res.data);
            setIsCallingApi(false);

            if (res.status == 204) {
                setArrayOfSearchResults([]);
            } else {
                setArrayOfSearchResults(res.data.search_results_array);
            }

        }).catch(err => {
            setIsCallingApi(false);
            console.log("error is ", err)
        })

    }


    const searchInputField = (e) => {

        clearInterval(timeInterval);

        if (e.target.value.length > 0) {

            setShowHideSearchListContainer(true);

            timeInterval = setTimeout(() => {

                console.log(" searchtext changed is", e.target.value);
                if (timeInterval) {
                    console.log("timer is not null we are clearing it")
                    clearInterval(timeInterval);
                }

                callApi(e.target.value);
                setIsCallingApi(true);


            }, 2000)

        } else {
            setShowHideSearchListContainer(false)
        }
    }

    const clickedOnHeaderSearchBoxListItem = (indexOnCompanyArray) => {

        console.log("clicked on ", arrayOfSearchResults[indexOnCompanyArray]);
        window.open(`${process.env.REACT_APP_FRONT_END_BASE_URL}/view_details_of_issue/${arrayOfSearchResults[indexOnCompanyArray].id}`);
        setShowHideSearchListContainer(false)
    }

    useEffect(() => {
        getAllClosedTicketsByACustomer();
    }, [])

    return (
        <div className="ViewClosedTicketsOfACompany-root-container pb-4">

            <img src={logo_cubix} className="ReportIssue-top-layer-logo" />

            <div className="ReportIssue-top-layer-form Previous-Tickets-Container mt-0 pt-4 mb-4">

                <div className="ViewClosedTicketsOfACompany-ticket-search-container pe-2">
                    <img src={ic_search}/>
                    <input className="ViewClosedTicketsOfACompany-search-input" onChange={(e) => searchInputField(e)} type="text" placeholder="Search ticket" />
                    {
                        showHideSearchListContainer &&
                        <div className="Header-company-search-result-list-container ViewClosedTicketsOfACompany-search-result-list-container">

                            <table  className="Header-company-table-after-search ViewClosedTicketsOfACompany-table">
                                <thead>
                                    <tr>
                                        <th className="ViewClosedTicketsOfACompany-table-first-column"><div>Description</div></th>
                                        <th><div>Person Name </div></th>
                                        <th><div>User</div></th>
                                        <th><div>Mobile Number</div></th>
                                        {/*<th><div>Balance</div></th> */}
                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        isCallingApi &&

                                        <tr>
                                            <td>

                                                <div className="Header-searching-text-container">

                                                    <div className="spinner-border" role="status">
                                                    </div>

                                                    <label className="ms-4">Searching ....</label>

                                                </div>
                                            </td>
                                        </tr>
                                    }

                                    {
                                        arrayOfSearchResults != null ?

                                            arrayOfSearchResults.length == 0 ?

                                                <tr>
                                                    <td>
                                                        <div className="Header-searching-text-container">
                                                            <label> No results found</label>
                                                        </div>
                                                    </td>
                                                </tr>

                                                :

                                                arrayOfSearchResults.map((item, index) => {
                                                    return (
                                                        <tr className="Header-company-search-result-list-single-row" onClick={() => clickedOnHeaderSearchBoxListItem(index)}>
                                                            <td>
                                                                <div className="Header-company-search-result-list-single-item ViewClosedTicketsOfACompany-ticket-description">
                                                                    <p title={item.ticket_description} >{item.ticket_description}</p>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="Header-company-search-result-list-single-item">
                                                                   <p title={item.ticket_person_name}>{item.ticket_person_name}</p> 
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="Header-company-search-result-list-single-item">
                                                                   <p title={item.ticket_assigned_by == "undefined" ? "": item.ticket_assigned_by}>{item.ticket_assigned_by == "undefined" ? "": item.ticket_assigned_by}</p> 
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="Header-company-search-result-list-single-item">
                                                                   <p title={item.mobile_number == "undefined" ? "" : item.mobile_number}> {item.mobile_number == "undefined" ? "" : item.mobile_number}</p>
                                                                </div>
                                                            </td>
                                                            {/* <td>
                                                                    <div className="Header-company-search-result-list-single-item">{item.balance}</div>
                                                                </td> */}
                                                        </tr>
                                                    )
                                                })

                                            :
                                            null
                                    }

                                </tbody>

                            </table>
                        </div>
                    }
                </div>
                <h4 className="mt-4">Closed Tickets</h4>

                {allIssuesByACompanyState != null ?

                    allIssuesByACompanyState.length != 0 ?
                        allIssuesByACompanyState.map((item) => {
                            return (
                                < div className="ReportIssue-previous-list-single-item">

                                    <div className="View-closed-tickets-of-a-company-left-child">
                                       
                                        <div className="ReportIssue-single-item-left-child-top-panel mt-2">
                                            <div className="ReportIssue-single-item-left-child-top-panel-child">
                                                <label className="Previous-Tickets-Container-label">Ticket Number</label>
                                                <label className="Previous-Tickets-Container-value">{item.id}</label>
                                            </div>
                                            <div className="ReportIssue-single-item-left-child-top-panel-child">
                                                <label className="Previous-Tickets-Container-label">Mobile Number</label>
                                                <label className="Previous-Tickets-Container-value">{item.mobile_number}</label>
                                            </div>
                                            <div className="ReportIssue-single-item-left-child-top-panel-child">
                                                <label className="Previous-Tickets-Container-label">Created On</label>
                                                <label className="Previous-Tickets-Container-value">
                                                    {new Date(item.ticket_created_on).getDate() +
                                                        " / " + (new Date(item.ticket_created_on).getMonth() + 1) +
                                                        " / " + (new Date(item.ticket_created_on).getFullYear()) +
                                                        " -- " + (new Date(item.ticket_created_on).getHours()) +
                                                        " : " + (new Date(item.ticket_created_on).getMinutes())
                                                    }

                                                </label>
                                            </div>

                                            <div className="ReportIssue-single-item-left-child-top-panel-child">
                                                <label className="Previous-Tickets-Container-label">Closed On</label>
                                                <label className="Previous-Tickets-Container-value">
                                                    {new Date(item.ticket_status_changed_on).getDate() +
                                                        " / " + (new Date(item.ticket_status_changed_on).getMonth() + 1) +
                                                        " / " + (new Date(item.ticket_status_changed_on).getFullYear()) +
                                                        " -- " + (new Date(item.ticket_status_changed_on).getHours()) +
                                                        " : " + (new Date(item.ticket_status_changed_on).getMinutes())
                                                    }

                                                </label>
                                            </div>

                                        </div>

                                        <div className="ReportIssue-single-item-left-child-bottom-panel mt-4">
                                            <label className="Previous-Tickets-Container-label">Ticket Description</label>
                                            <label className="Previous-Tickets-Container-value">{item.ticket_description}</label>
                                        </div>
                                        <div className="ReportIssue-single-item-left-child-bottom-panel mt-4">
                                            <label className="Previous-Tickets-Container-label">Latest Remarks</label>
                                            <label className="Previous-Tickets-Container-value">{item.remarks}</label>
                                        </div>

                                        <div className="View-closed-tickets-of-a-company-view-details-link-container">
                                            <a className="View-closed-tickets-of-a-company-view-details-link" href={`/view_details_of_issue/${item.id}`}>View Details</a>
                                        </div>
                                    </div>
                                    {/* <div className="ReportIssue-single-item-current-status">
                                        <label className="Previous-Tickets-Container-label">Current Status</label>
                                        <label className="Previous-Tickets-Container-value">

                                            {item.ticket_current_status == 1 ? <label>OPENED</label> : ""}
                                            {item.ticket_current_status == 2 ? <label>CLOSED</label> : ""}
                                            {item.ticket_current_status == 3 ? <label>ASSIGNED</label> : ""}
                                            {item.ticket_current_status == 4 ? <label>ESCALATED</label> : ""}
                                        </label>
                                    </div> */}

                                </div>
                            )
                        })
                        :
                        <p className="mt-4">No Tickets To Show... </p>

                    :
                    <p className="mt-4">No Data To Show </p>

                }


            </div>

        </div>
    )
}

export default ViewClosedTicketsOfACompany;