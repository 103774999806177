import React, { useEffect, useState } from "react";
import ic_sales from "../img/ic_sales_marketing.png";
import "../css/DailyTasksInHomeComponent.css";
import DailyTaskStaffCard from "./DailyTaskStaffCard";
import CreateTaskPopupContainer from "./CreateTaskPopupContainer";
import axios from "axios";
import LoadingUI from "./LoadingUI";
import Header from "./Header";
import SideSmallDrawer from "./SideSmallDrawer";

function Sales() {

    const [showLoadingUiState, setShowLoadingUiState] = useState(false);
    const [cubixTaskTicketCompanyNameArrayState, setCubixTaskTicketCompanyNameArray] = useState(null);
    const [cubixTaskTicketStaffNameArrayState, setCubixTaskTicketStaffNameArray] = useState(null);

    const [showAppFailurelabel, setShowAppFailurelabel] = useState(false);
    const [showCreateTaskPopup, setShowCreateTaskPopup] = useState(false);
    const [openedTaskArrayState, setOpenedTaskArrayState] = useState(null);
    const [errorMessage, setErrorMessageState] = useState("");
    const [successMessage, setSuccessMessageState] = useState("");

    const showHideCreateNewTaskPopup = () => {
        setShowCreateTaskPopup(prev => !prev)
    }

    const clearErrorMessageAfterDelay = () => {
        setTimeout(() => {

            setErrorMessageState("");
            setShowAppFailurelabel(false);
        }, 5000);
    }

    const getAllOpenedTasks = () => {

        setShowLoadingUiState(true);

        axios.get(`${process.env.REACT_APP_BASE_URL}/get-all-opened-task.php`).
            then((res) => {

                setShowLoadingUiState(false);
                console.log(" all opened tasks res is ", res);
                if (res.data.status == "get_opened_tasks_success") {
                    setOpenedTaskArrayState(res.data.opened_tasks_array);
                } else if (res.data.status == "get_opened_tasks_failure") {

                    setShowAppFailurelabel(true);
                    setErrorMessageState("Database error, contact admin");
                    clearErrorMessageAfterDelay();
                }
            }).
            catch((err) => {

                setShowLoadingUiState(false);

                console.log("err is ", err);
                setShowAppFailurelabel(true);
                setErrorMessageState("Network error, contact admin");
                clearErrorMessageAfterDelay();
            })
    }

    const getFromLocalStorageStaffArray = () => {
        let cubixTaskTicketStaffNameArray = sessionStorage.getItem("cubix_task_ticket_staff_name_array");
        setCubixTaskTicketStaffNameArray(JSON.parse(cubixTaskTicketStaffNameArray))
    }

    const getFromLocalStorageCompanyNameArray = () => {
        let cubixTaskTicketCompanyNameArray = sessionStorage.getItem("cubix_task_ticket_company_name_array");
        setCubixTaskTicketCompanyNameArray(JSON.parse(cubixTaskTicketCompanyNameArray))
    }

    useEffect(() => {
        getAllOpenedTasks();
        getFromLocalStorageStaffArray();
        getFromLocalStorageCompanyNameArray();
    }, [])

    return (
        <div className="DailyTasksInHomeComponent-root-container">

            <Header />

            <div className="DailyTasksInHomeComponent-left-right-container">

                <SideSmallDrawer />

                <div className="DailyTasksInHomeComponent-inner-container">

                    <div className="DailyTasksInHomeComponent-header mt-3">
                        
                        <div className="DailyTasksInHomeComponent-header-right-section ms-2 me-4">
                            <h4 className="mt-2">Sales &amp; Management</h4>
                            <div className="mt-2">
                                <button type="button" onClick={showHideCreateNewTaskPopup}>
                                    Create New Task
                                </button>
                                <a href="admindashboard/alltasks" className="ms-3">
                                    Completed Tasks
                                </a>
                            </div>
                        </div>
                    </div>

                    {showLoadingUiState && <LoadingUI />}

                    <div className="DailyTasksInHomeComponent-task-list-container">

                        {showAppFailurelabel &&
                            <div className="App-failure-label"><label>{errorMessage}</label></div>
                        }
                        {
                            openedTaskArrayState != null ?
                                openedTaskArrayState.length > 0 ?
                                    <DailyTaskStaffCard openedTaskArrayState={openedTaskArrayState} cubixTaskTicketStaffNameArrayState={cubixTaskTicketStaffNameArrayState} cubixTaskTicketCompanyNameArrayState={cubixTaskTicketCompanyNameArrayState} />
                                    : <label className="ms-4">No Opened Tasks</label>
                                : null
                        }

                    </div>

                </div>

                {
                    showCreateTaskPopup &&
                    <CreateTaskPopupContainer showHideCreateNewTaskPopup={showHideCreateNewTaskPopup} />
                }

            </div>

        </div>
    )
}

export default Sales;