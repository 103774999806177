import React, { useState, useRef, useEffect } from "react";
import ic_daily_task_small_icon from "../img/ic_daily_task_small_icon.png";
import ic_ticket_small_icon from "../img/ic_ticket_small_icon.png";
import ic_check_mark from "../img/ic_checkmark_complete.png";
import "../css/DailyTaskStaffCard.css";
import CompletionPopup from "./CompletionPopup";

function DailyTaskStaffCard(props) {

    const { openedTaskArrayState, cubixTaskTicketStaffNameArrayState, cubixTaskTicketCompanyNameArrayState } = props;
    const [showCompletionPopup, setShowCompletionPopup] = useState(false);

    const initialCompletionDetailsRef = { "completion_type": "", "completion_number": 0 }
    const completionDetailsRef = useRef(initialCompletionDetailsRef);

    const showHideCompletionPopup = () => {
        setShowCompletionPopup(prev => !prev);
    }

    const markAsComplete = (completionType, completionNumber) => {
        completionDetailsRef.current.completion_type = completionType;
        completionDetailsRef.current.completion_number = completionNumber;

        console.log(" completionDetailsRef details ", completionDetailsRef.current.completion_type, completionDetailsRef.current.completion_number);
        setShowCompletionPopup(true);
    }



    return (
        <div className="DailyTaskStaffCard-root-container">

            {openedTaskArrayState != null ?

                openedTaskArrayState.length > 0 ?

                    <table className="DailyTaskStaffCard-table">

                        <thead>
                            <tr>
                                <th><div>Task No.</div></th>
                                <th><div>Change Status</div></th>
                                <th><div>Description</div></th>
                                <th><div>Company Name</div></th>
                                <th><div>Remarks</div></th>
                                <th><div>Created By</div></th>
                                <th><div>Created On</div></th>
                                <th><div>Current Status</div></th>
                                <th><div>Status Changed By</div></th>
                                <th><div>Status Changed On</div></th>
                            </tr>
                        </thead>
                        <tbody>

                            {
                                openedTaskArrayState.map((item) => {
                                    return (
                                        <tr key={item.id} className="DailyTaskStaffCard-task-list-single-item mt-2">

                                            <td>
                                                <div>
                                                    <label className="DailyTaskStaffCard-task-number-bg">
                                                        {item.id}
                                                    </label>
                                                </div>
                                            </td>

                                            <td>
                                                <div>
                                                    <button className="DailyTaskStaffCard-task-list-single-item-mark-complete me-2" onClick={() => markAsComplete("task", item.id)}>Change</button>
                                                </div>
                                            </td>

                                            <td>
                                                <div className="DailyTaskStaffCard-table-description-column">
                                                    <label>
                                                        {item.task_description}
                                                    </label>
                                                </div>
                                            </td>

                                            <td>
                                                <div className="DailyTaskStaffCard-table-company-name-column">
                                                    <label>
                                                        {
                                                            cubixTaskTicketCompanyNameArrayState != null ?
                                                                cubixTaskTicketCompanyNameArrayState.length > 0 ?
                                                                    cubixTaskTicketCompanyNameArrayState.filter((array_item) => {
                                                                        return (array_item.id == item.company_name);
                                                                    }).length > 0 ?
                                                                        cubixTaskTicketCompanyNameArrayState.filter((array_item) => {
                                                                            return (array_item.id == item.company_name);
                                                                        })[0].company_name
                                                                        :
                                                                        "--xx--"
                                                                    :
                                                                    "-----"
                                                                :
                                                                "--"
                                                        }
                                                    </label>
                                                </div>
                                            </td>

                                            <td>
                                                <div className="DailyTaskStaffCard-table-remarks-column">
                                                    <p>
                                                        {item.task_remarks}
                                                    </p>
                                                </div>
                                            </td>

                                            <td>
                                                <div>
                                                    <p>{
                                                        cubixTaskTicketStaffNameArrayState != null ?
                                                            cubixTaskTicketStaffNameArrayState.length > 0 ?
                                                                cubixTaskTicketStaffNameArrayState.filter((array_item) => {
                                                                    return (array_item.id == item.assigned_by);

                                                                })[0].staff_name
                                                                :
                                                                "-----"
                                                            :
                                                            "--"
                                                    }</p>
                                                </div>
                                            </td>

                                            <td>
                                                <div>
                                                    <p>
                                                        {new Date(item.task_created_on).getDate() +
                                                            " / " + (new Date(item.task_created_on).getMonth() + 1) +
                                                            " / " + (new Date(item.task_created_on).getFullYear())
                                                        } </p>
                                                </div>
                                            </td>

                                            <td>
                                                <div>
                                                    {item.task_current_status == 1 ? <p>OPENED</p> : ""}
                                                    {item.task_current_status == 3 ? <p>ASSIGNED</p> : ""}
                                                    {item.task_current_status == 4 ? <p>ESCALATED</p> : ""}

                                                </div>
                                            </td>

                                            <td>
                                                <div>
                                                    <p>{
                                                        cubixTaskTicketStaffNameArrayState != null ?
                                                            cubixTaskTicketStaffNameArrayState.length > 0 ?
                                                                cubixTaskTicketStaffNameArrayState.filter((array_item) => {
                                                                    return (array_item.id == item.task_status_changed_by);

                                                                }).length > 0 ?
                                                                    cubixTaskTicketStaffNameArrayState.filter((array_item) => {
                                                                        return (array_item.id == item.task_status_changed_by);

                                                                    })[0].staff_name
                                                                    :
                                                                    "--xx--"
                                                                :
                                                                "-----"
                                                            :
                                                            "--"
                                                    }</p>
                                                </div>
                                            </td>

                                            <td>
                                                <div>
                                                    <p>
                                                        {item.task_status_changed_on != "0000-00-00 00:00:00" ?
                                                            <p>
                                                                {new Date(item.task_status_changed_on).getDate() +
                                                                    " / " + (new Date(item.task_status_changed_on).getMonth() + 1) +
                                                                    " / " + (new Date(item.task_status_changed_on).getFullYear())
                                                                }

                                                            </p>
                                                            :
                                                            <p>
                                                                --xx--
                                                            </p>
                                                        }
                                                    </p>

                                                </div>

                                            </td>



                                        </tr>
                                    )
                                })
                            }

                        </tbody>
                    </table>
                    :
                    <label className="ms-4">No Data to show</label>
                :
                null

            }

            {
                showCompletionPopup &&
                <CompletionPopup showHideCompletionPopup={showHideCompletionPopup} completionDetails={completionDetailsRef} />
            }


        </div>
    )

}

export default DailyTaskStaffCard;