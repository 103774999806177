import React from "react";
import "../css/ReportIssuePopup.css";
import ic_sent_issue from "../img/ic_warning.png";



function ReportIssuePopupFailure(props) {

    return (
        <div className="ReportIssuePopup-root-container">

            <div className="ReportIssuePopup-inner-container">

                <div className="ReportIssuePopup-success-container">

                    <img className="ReportIssuePopup-success-container-sent-gif" src={ic_sent_issue} />

                    <h5 className="ReportIssuePopup-success-container-sent-text">
                        Couldn't Sent Issue
                    </h5>
                    <p>
                        Some technical error happened. Please try again later. We regret for the inconvenience caused.
                    </p>

                    <button className="ReportIssuePopup-inner-container-ok-button" onClick={props.showHideFailurePopup}>OK</button>

                </div>

                <div className="ReportIssuePopup-failure-container">

                </div>

            </div>

        </div>
    )

}

export default ReportIssuePopupFailure;