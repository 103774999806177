import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import "../css/AmcOfCompanies.css";
import axios from "axios";
import "../css/CustomerDetails.css";
import SideSmallDrawer from "../components/SideSmallDrawer";

function AmcOfCompanies() {

    const copyOfAllTickets = useRef();

    const [showHideMonthDropDown, setShowHideMonthDropDown] = useState(false);

    const [currentSlectedMonth, setCurrentSlectedMonth] = useState(0);

    const [apiResultArrayReceivedState, setApiResultArrayReceivedState] = useState(false);

    const [apiResultArrayState, setApiResultArrayState] = useState(null);

    const initialMonthAndCompaniesListState = [
        { id: 1, month: "january", companies: [] },
        { id: 2, month: "february", companies: [] },
        { id: 3, month: "march", companies: [] },
        { id: 4, month: "april", companies: [] },
        { id: 5, month: "may", companies: [] },
        { id: 6, month: "june", companies: [] },
        { id: 7, month: "july", companies: [] },
        { id: 8, month: "august", companies: [] },
        { id: 9, month: "september", companies: [] },
        { id: 10, month: "october", companies: [] },
        { id: 11, month: "november", companies: [] },
        { id: 12, month: "december", companies: [] },
    ]

    const [monthAndCompaniesListState, setMonthAndCompaniesListState] = useState(initialMonthAndCompaniesListState);

    const updateMonthAndCompaniesList = async () => {

        monthAndCompaniesListState.map((itemMonth, index) => {

            let filteredArrayOfMonth = copyOfAllTickets.current.filter((itemAMC) => {
                return itemMonth.id == (new Date(itemAMC.amcdate).getMonth() + 1)
            })

            //console.log("current month and filtered array ", index + 1, filteredArrayOfMonth)

            let newArray = [...monthAndCompaniesListState];

            monthAndCompaniesListState[index].companies = filteredArrayOfMonth;

            setMonthAndCompaniesListState(newArray)
        })
    }

    useEffect(() => {

        console.log("currentSlectedMonth is ", currentSlectedMonth);

        if (currentSlectedMonth == 0) {
            if (copyOfAllTickets.current != null) {
                setApiResultArrayState(copyOfAllTickets.current)
            }
        } else {

            console.log("currentSlectedMonth is inisde else company [] ", monthAndCompaniesListState[currentSlectedMonth - 1]);
            console.log("currentSlectedMonth is inisde else company list", monthAndCompaniesListState[currentSlectedMonth - 1].companies);
            setApiResultArrayState(monthAndCompaniesListState[currentSlectedMonth - 1].companies)
        }

    }, [currentSlectedMonth])



    const getAmcOfCompanies = () => {
        axios.get(`http://185.140.249.224:112/api/CustomerDetails`).then((res) => {

            console.log("res", res);
            setApiResultArrayState(res.data.jsont);
            setApiResultArrayReceivedState(true);

            copyOfAllTickets.current = res.data.jsont;

            updateMonthAndCompaniesList();

        }).catch((err) => {
            console.log("error is ", err)
        });
    }

    useEffect(() => {

        getAmcOfCompanies();

    }, [])

    return (
        <div className="AmcOfCompanies-root-container">

            <Header />

            <div className="AmcOfCompanies-left-right-container">

                <SideSmallDrawer/>

                {/* start of main content */}
                <div className="AmcOfCompanies-inner-container">

                    <h4>AMC Details</h4>

                    <div className="AmcOfCompanies-select-amc-container my-4">

                        {currentSlectedMonth == 0 ? <div>All AMC list </div> : <div>AMC list in <span className="AmcOfCompanies-current-month"> {monthAndCompaniesListState[currentSlectedMonth - 1].month}</span> <span className="AmcOfCompanies-show-all-amc-list" onClick={() => setCurrentSlectedMonth(0)}>Show All</span> </div>}

                        <span className="AmcOfCompanies-select-month" onClick={() => setShowHideMonthDropDown(prev => !prev)}>Select month</span>

                        {
                            showHideMonthDropDown ?

                                monthAndCompaniesListState != null ?

                                    <div className="AmcOfCompanies-dropdown-month-container">
                                        {
                                            monthAndCompaniesListState.map((item, index) => {
                                                return (
                                                    <div key={item.id} className="AmcOfCompanies-single-list-item" onClick={() => { setCurrentSlectedMonth(index + 1); setShowHideMonthDropDown(prev => !prev); }}>{item.month}</div>
                                                )
                                            })
                                        }
                                    </div>

                                    :
                                    null
                                :
                                null
                        }
                    </div>

                    <div className="AmcOfCompanies-table-container">
                        <table className="AmcOfCompanies-table">
                            <thead>
                                <tr>
                                    <th><div>Sl No.</div></th>
                                    <th><div>Company name </div></th>
                                    <th><div>Balance </div></th>
                                    <th><div>AMC Date </div> </th>
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    apiResultArrayState != null ?

                                        apiResultArrayState.length > 0 ?
                                            apiResultArrayState.map((item, index) => {
                                                return (

                                                    <tr key={index}>
                                                        <td className="CustomerDetails-company-sl-no-column">
                                                            <div>
                                                                {index + 1}
                                                            </div>
                                                        </td>
                                                        <td className="CustomerDetails-company-name-column">
                                                            <div>

                                                                {item.accdesc}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>

                                                                {item.Balance}
                                                            </div>
                                                        </td>
                                                        <td className="CustomerDetails-company-date-column">
                                                            <div>
                                                                {new Date(item.amcdate).getDate() +
                                                                    " / " + (new Date(item.amcdate).getMonth() + 1) +
                                                                    " / " + (new Date(item.amcdate).getFullYear())
                                                                }
                                                            </div>

                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            :
                                            <tr>
                                                <td>
                                                    <div>No results to show </div>
                                                </td>
                                            </tr>
                                        :
                                        <tr>
                                            <td>
                                                <div>Loading ...</div>
                                            </td>
                                        </tr>
                                }

                            </tbody>
                        </table>
                    </div>


                </div>
                {/* end of main content */}

            </div>

        </div>
    )
}

export default AmcOfCompanies;