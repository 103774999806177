import React, { useState } from "react";
import logo_cubix from "../img/logo_cubix.png";
import { useLocation } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import { useNavigate, NavLink } from "react-router-dom";
import "../css/TabHeader.css";
import ic_logout from "../img/ic_person_logout.png";
import ic_menu from "../img/ic_menu.png";

function TabHeader(){
  
  const location = useLocation();
  console.log("location url ", location);

    const navigate = useNavigate();  
    const idOfClient =localStorage.getItem("cubix_support_idOfClient"); 
    const nameOfCompany =localStorage.getItem("cubix_support_nameOfCompany"); 
    const [showAccountPopup, setShowAccountPopup] = useState(false);

    const logOutUser = ()=>{
      console.log("log out user clicked ");
      localStorage.setItem("cubix_support_isAuthenticated", false);
      localStorage.setItem("cubix_support_idOfClient", 0);
      navigate("/");
    }

    const onClickOfMenu = ()=>{
      setShowAccountPopup(prev => !prev);
    }

    const clickedTabItem = (tabName) => {

      if(tabName == "create_ticket"){
          navigate(`/dashboard/${idOfClient}/createticket`);
      }else{
        navigate(`/dashboard/${idOfClient}/previoustickets`);
      }
    }

    return(

          <div className="TabHeader-root-container">
            <div className="TabHeader-container-top-panel">
              <img src={logo_cubix} className="TabHeader_company_logo" />
              <button onClick={onClickOfMenu} className="TabHeader-top-panel-button"><img src={ic_menu} className="TabHeader-img-menu-hamburger me-2"/><label>Menu</label></button>

              {showAccountPopup  ? 
                    <div className="TabHeader-right-side-logout-column">
                      <div className="TabHeader-company-name-container">
                        <h3>{nameOfCompany}</h3>
                      </div>
                      <div className="TabHeader-logout-container" onClick={logOutUser}>
                            <img src={ic_logout}/> <span>Logout</span>
                      </div>
                    </div>
                : 
                null
                }

            </div>
            <div className="TabHeader-container-bottom-panel">
              
                <ul className="nav nav-pills py-1" id="pills-tab" role="tablist"  >
                            <li className="nav-item" role="presentation" onClick={()=>clickedTabItem("create_ticket")}>
                                <button className={location.pathname.includes('/createticket') ? "nav-link active":"nav-link" } id="pills-open-tab" data-bs-toggle="pill" data-bs-target="#pills-open" type="button" role="tab" aria-controls="pills-open" aria-selected={location.pathname.includes('/createticket') ? "true":"false"}>Create Ticket</button>
                            </li>
                            <li className="nav-item" role="presentation" onClick={()=>clickedTabItem("previous_tickets")}>
                                <button className={location.pathname.includes('/previoustickets') ? "nav-link active":"nav-link"} id="pills-closed-tab" data-bs-toggle="pill" data-bs-target="#pills-closed" type="button" role="tab" aria-controls="pills-closed" aria-selected={location.pathname.includes('/previoustickets') ? "true":"false"}>Previous Tickets</button>
                            </li>
                </ul>
              
            </div>
          </div>
          
    )

}

export default TabHeader;