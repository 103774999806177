import axios from "axios";
import React, { useEffect, useState } from "react";
import {useNavigate} from "react-router-dom";
import "../css/LoginUser.css";
import logo_cubix from "../img/logo_cubix.png";
import side_banner from "../img/side_banner_cubix_login.png";
import bottom_circle from "../img/bottom_circle_cubix.png";
import bottom_circle_2 from "../img/bottom_circle_2_cubix.png";
import bottom_circle_3 from "../img/side_dot_panel.png";

function LoginUser(){

     const base_url = "http://185.140.249.224:3001";
    //const base_url = "http://localhost:3001";

    const navigate = useNavigate();

    const [isAlreadyLoggedIn, setIsAlreadyLoggedIn] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const initialInput = { company_email: "", password: ""};
    const [inputUserLoginState, setInputUserLoginState] = useState(initialInput);

    const handleInput = (event)=>{
        setErrorMsg("");
        setInputUserLoginState({...inputUserLoginState, [event.target.name]: event.target.value});
    }

    const emailIsValid = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
      }
      
    const validateInput = () => {
        if (inputUserLoginState.company_email.length > 0 && inputUserLoginState.password.length > 0) {
            if(emailIsValid(inputUserLoginState.company_email)){
                setErrorMsg("");
                return true;
            }else{
                setErrorMsg("Please enter valid email");
                return false;
            }
        
        } else {

          console.log("username password empty");
          setErrorMsg("Please enter valid username and password");
          return false;

        }
      };

     const callLoginAPI = ()=>{
        
    //after validation send to api 
    if(validateInput()){
        setErrorMsg("");
        console.log("values before sending", inputUserLoginState);
        axios.post(`${base_url}/client-login`, inputUserLoginState).
        then((res)=>{
            console.log("login result is ", res);
            const idOfClient = res.data.client_id;
            const nameOfCompany = res.data.company_name;
            if(res.data.isAuthenticated){
                setErrorMsg("");
                localStorage.setItem("cubix_support_isAuthenticated", true);
                localStorage.setItem("cubix_support_idOfClient", idOfClient);
                localStorage.setItem("cubix_support_nameOfCompany", nameOfCompany);
                navigate(`/dashboard/${idOfClient}/createticket`);

            }else{
                console.log("login failed reason ", res.data);
                setErrorMsg(res.data.errorMessage);
            }
        }).catch(err => setErrorMsg("Login Request failed ... Please contact admin " ) );
        }
     }

     useEffect(()=>{

        const authed = localStorage.getItem("cubix_support_isAuthenticated");
        if(JSON.parse(authed)){
            setIsAlreadyLoggedIn(true);
            navigate(`/dashboard/${localStorage.getItem("cubix_support_idOfClient")}/createticket`);
        }else{
            setIsAlreadyLoggedIn(false);
        }
     },[])

    return(
        <div>

            {isAlreadyLoggedIn ? <p>Please wait...</p> :
            
            <div  className="LoginUser-root-container">
                <div className="LoginUser-left-container hidden-in-mobile">
                    <img src={logo_cubix} className="LoginUser-cubix-logo"/>
                    <img src={side_banner} className="LoginUser-side-banner-img" />
                </div>

                <div className="LoginUser-top-layer-container">
                    <div>
                        <h1 className="LoginUser-top-layer-container-heading">Cubix Support Center</h1>
                        <p className="text-center">Support portal for CUBIX ERP users. Login to raise tickets.</p>
                    </div>
                    
                    <div>
                    
                        <div className="mt-4 LoginUser-form-container">
                            <label>Email</label>
                            <input className="mt-2" type="email" name="company_email"  value={inputUserLoginState.company_email} onChange={(event)=>{handleInput(event)}}/>
                            <label className="mt-2">Password</label>
                            <input className="mt-2" type="password" name="password" value={inputUserLoginState.password} onChange={(event)=>{handleInput(event)}}/>
                            <button className="mt-3 btn btn-primary LoginUser-login-button " onClick={callLoginAPI} >Login</button>
                            <div className="LoginUser-registration-error-container">{errorMsg}</div>
                        </div>
                        <div className="LoginUser_register_now_panel"><label>Not yet Registered?</label> <a href="registration/" >Register Now</a></div>
                    </div>

                    <img className="LoginUser-side-dot-panel hidden-in-mobile" src={bottom_circle_3}/>
                    <img className="LoginUser-top-dot hidden-in-mobile" src={bottom_circle_2}/>
                    <img className="LoginUser-bottom-dot hidden-in-mobile" src={bottom_circle}/>
                    
                </div>
            </div>
            }

        </div>
    )
}

export default LoginUser;