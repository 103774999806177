import React from "react";
import { Navigate } from "react-router-dom";

    function ProtectedRoute({ children }) {

        const authed = localStorage.getItem("cubix_support_isAuthenticated");

        return JSON.parse(authed) ? children : <Navigate to="/" />;
    }

  export default ProtectedRoute;