import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "./Header";
import "../css/CustomerDetails.css";
import SideSmallDrawer from "./SideSmallDrawer";

function CustomerDetails() {

    const [apiResultArrayState, setApiResultArrayState] = useState(null)
    const [upcomingCountState, setUpcomingCountState] = useState(7);

    const getCustomerDetails = () => {

        axios.get(`http://185.140.249.224:112/api/CustomerDetails`).then((res) => {

            console.log("res", res);
            setApiResultArrayState(res.data.jsont);

        }).catch((err) => {
            console.log("error is ", err)
        });
    }

    const changeNumberOfCounts = (e) => {
        setUpcomingCountState(e.target.value)
    }

    useEffect(() => {

        getCustomerDetails();

    }, [])

    return (
        <div className="CustomerDetails-root-container">

            <Header />

            <div className="CustomerDetails-left-right-container">

                <SideSmallDrawer />
                <div className="CustomerDetails-inner-container">
                    <h5>Customer Details</h5>

                    <div className="CustomerDetails-table-container">

                        <table className="CustomerDetails-table mt-0">
                            <thead>
                                <tr>
                                    <th><div>Sl No.</div></th>
                                    <th><div>Company Name</div></th>
                                    <th><div>Balance</div></th>
                                    <th><div>AMC date (dd-mm-yyyy)</div> </th>
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    apiResultArrayState != null ?

                                        apiResultArrayState.length > 0 ?
                                            apiResultArrayState.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td className="CustomerDetails-company-sl-no-column">
                                                            <div>
                                                                {index + 1}
                                                            </div>
                                                        </td>
                                                        <td className="CustomerDetails-company-name-column">
                                                            <div>

                                                                {item.accdesc}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>

                                                                {item.Balance}
                                                            </div>
                                                        </td>
                                                        <td className="CustomerDetails-company-date-column">
                                                            <div>
                                                                {new Date(item.amcdate).getDate() +
                                                                    " / " + (new Date(item.amcdate).getMonth() + 1) +
                                                                    " / " + (new Date(item.amcdate).getFullYear())
                                                                }
                                                            </div>

                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            :
                                            <p>No results to show </p>
                                        :
                                        <p>
                                            No data to show
                                        </p>
                                }

                            </tbody>
                        </table>

                    </div>
                </div>

            </div>

        </div>
    )

}

export default CustomerDetails;