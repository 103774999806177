import React from "react";
import logo_cubix from "../img/logo_cubix.png";
import { useNavigate} from "react-router-dom";
import ic_admin_icon from "../img/ic_admin_icon.png";
import ic_logout from "../img/ic_person_logout.png";
import "../css/AdminCompanyReport.css";
import "../css/AdminCompanyFullReport.css";

function AdminCompanyFullReport(){

   const navigate = useNavigate();

    const logOutUser = ()=>{
        // console.log("log out admin clicked ");
        localStorage.setItem("cubix_admin_isAuthenticated", false);
        navigate("/admin")
      }

    return(
        <div className="AdminCompanyFullReport-root-container">
          
          <div className="AdminHome-logo-container">
                <div>
                    <a href="/admindashboard" >
                            <img src={logo_cubix} className="AdminHome_company_logo" />
                    </a>
                    <img src={ic_admin_icon} className="ms-4 AdminHome_admin_logo"/>
                    <label className="ms-2">Admin</label>
                </div>

                <div className="me-4 AdminHome-logout-container" onClick={logOutUser}>
                    <img src={ic_logout} className="me-2"/> <span>Logout</span>
                </div>
          </div>

          <div className="AdminCompanyFullReport-body-container mt-4">
            <div className="AdminCompanyFullReport-summary-container">
                <div>
                    <label>Most supported company till now</label>
                    <div>ABC Company</div>
                    <span>Total ticket count 30</span>
                    <span>AMC 30</span>
                </div>
            </div>
            <div className="AdminCompanyFullReport-summary-container mt-4">
                <div>
                    <label>Most supported company between dates</label>
                    <label>Select start date </label>
                    <label>Select end date </label>
                    <div>ABC Company</div>
                    <span>Total ticket count 30</span>
                    <span>AMC 30</span>
                </div>
            </div>
          </div>

        </div>
    )

}

export default AdminCompanyFullReport;