import React, { useState, useRef } from "react";
import PickDate from "../utility/DatePicker";
import formatDateHyphen from "../utility/DateFormatterHyphen";
import Header from "./Header";
import "../css/ViewReports.css";
import axios from "axios";
import ic_excel_download from "../img/ic_excel_download.png";
import SideSmallDrawer from "./SideSmallDrawer";

function ViewReports() {

    let timeInterval = null;

    const [selectedCompanyFromSearch, setSelectedCompanyFromSearch] = useState(null);

    const [isCallingApi, setIsCallingApi] = useState(false);

    const [showHideSearchListContainer, setShowHideSearchListContainer] = useState(false);

    const [arrayOfSearchResults, setArrayOfSearchResults] = useState(null)

    const companyNameInputRef = useRef();

    let xlsx = require("json-as-xlsx");

    const dummyCompanyList = [
        {
            company_name: "Al Arab Auto Parts LLC",
            company_code: "67891",
        },
        {
            company_name: "Al Barsha Auto Parts LLC",
            company_code: "54321",
        },
        {
            company_name: "Al Rashid Auto parts",
            company_code: "12345",
        }
    ]

    const [emptyCustomerError, setEmptyCustomerError] = useState("");

    const [allIssuesByACompanyState, setAllIssuesByACompanyState] = useState(null);

    const [companyListShowHideState, setCompanyListShowHideState] = useState(false);
    const [emptyCustomerErrorPopupShowHideState, setEmptyCustomerErrorPopupShowHideState] = useState(false);

    const initial_company_state = {
        company_name: "",
        company_code: ""
    }

    const [companyState, setCompanyState] = useState(initial_company_state)

    const initialObject = {
        date_from: "",
        date_to: "",
    }

    const [filterParameterState, setFilterParameterState] = useState(initialObject);

    const datePickerFromParent = (keyName, pickedDate) => {
        console.log("keyName is pickedDate ", keyName, pickedDate);
        setFilterParameterState(filterParameterState => ({ ...filterParameterState, [keyName]: formatDateHyphen(pickedDate) }))
    }


    const listItemClicked = (idOfItem) => {

        let filteredItemArray = dummyCompanyList.filter((item) => {

            if (item.company_code == idOfItem) {
                return item;
            }

        })

        if (filteredItemArray.length > 0) {
            setCompanyState(filteredItemArray[0]);
            companyNameInputRef.current.value = filteredItemArray[0].company_name;
        }

        setCompanyListShowHideState(false);

    }

    const handleInput = (e) => {

        setEmptyCustomerError("");

        clearInterval(timeInterval);

        if (e.target.value.length > 0) {

            setShowHideSearchListContainer(true);

            timeInterval = setTimeout(() => {

                console.log(" searchtext changed is", e.target.value);
                if (timeInterval) {
                    console.log("timer is not null we are clearing it")
                    clearInterval(timeInterval);
                }

                callApi(e.target.value);
                setIsCallingApi(true);


            }, 500)

        } else {
            setShowHideSearchListContainer(false)
        }

    }

    const clickedOnHeaderSearchBoxListItem = (indexOnCompanyArray) => {

        console.log("clicked on ", arrayOfSearchResults[indexOnCompanyArray]);
        setSelectedCompanyFromSearch(arrayOfSearchResults[indexOnCompanyArray]);
        setShowHideSearchListContainer(false)
    }


    const callApi = (searchingText) => {

        axios.get(`http://185.241.124.171:127:122/api/CustomerDetails/CUST_SER/${searchingText}`).then((res) => {

            console.log("companys search res is ", res.data);
            setIsCallingApi(false);

            if (res.status == 204) {
                setArrayOfSearchResults([]);
            } else {
                setArrayOfSearchResults(res.data);
            }

        }).catch(err => {
            setIsCallingApi(false);
            console.log("error is ", err)
        })

    }

    const getAllIssuesByACustomer = () => {

        console.log(" clicked on getAllIssuesByACustomer")
        if (selectedCompanyFromSearch != null) {


            if (selectedCompanyFromSearch.account.length != 0) {

                axios.get(`${process.env.REACT_APP_BASE_URL}/view_issues_for_download_report.php?id=${selectedCompanyFromSearch.cmpcode}&start_date=${filterParameterState.date_from}&end_date=${filterParameterState.date_to}`).then((res) => {

                    console.log("res is issues by a customer ", res);

                    setAllIssuesByACompanyState(res.data.opened_tickets_array)

                }).catch((err) => {
                    console.log("err is ", err);
                })

            } else {
                setEmptyCustomerError("please enter a customer name ");
                setEmptyCustomerErrorPopupShowHideState(prev => !prev);
            }

        } else {
            setEmptyCustomerError("please enter a customer name ");
            setEmptyCustomerErrorPopupShowHideState(prev => !prev);
        }
    }

    const downloadToExcel = () => {

        if (allIssuesByACompanyState.length > 0) {

            let data = [
                {
                    sheet: "View Enquiry",
                    columns: [
                        // eg "user" in column should be same as user in content else that column will be blank
                        // Top level data // value : "user" should be same as content key double quotes required
                        { label: "Description", value: "ticket_description" }, // Run functions
                        { label: "Mobile Number", value: "mobile_number" },
                        { label: "Created On", value: "ticket_created_on" },
                        { label: "Support Agent", value: "staff_name" },
                        { label: "Remarks", value: "remarks" },
                        { label: "Current Status", value: "completion_status_name" },
                        // current staus is in number format , check what to do for that to show as text like closed ,opened etc
                        // either join the completion_status_table with ticket table from backend then return


                    ],
                    content: allIssuesByACompanyState,
                },
            ];


            let settings = {
                fileName: selectedCompanyFromSearch.account, // Name of the spreadsheet
                extraLength: 3, // A bigger number means that columns will be wider
                writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
            };

            xlsx(data, settings); // Will download the excel file

        }
    }

    return (
        <div className="ViewReports-root-container">

            <Header />

            <div className="ViewReports-left-right-container">
                <SideSmallDrawer />
                <div className="ViewReports-main-container mt-4">

                    <h4>View Reports</h4>

                    <div className="ViewReports-name-date-and-button-container">
                        <div className="ViewReports-company-container">
                            <label className="mt-4">Select customer</label>

                            <div className="ViewReports-dropdown-holder">

                                <input ref={companyNameInputRef} onChange={(e) => handleInput(e)} className="ViewReports-search-company-input" type="text" placeholder="enter company name " />
                                {
                                    showHideSearchListContainer &&

                                    <div className="Header-company-search-result-list-container ViewReports-company-search-result-list-container">

                                        <table className="Header-company-table-after-search">
                                            <thead>
                                                <tr>
                                                    <th><div>Name</div></th>
                                                    <th><div>Category</div></th>
                                                    <th><div>Remarks</div></th>
                                                    <th><div>AMC Date</div></th>
                                                    <th><div>Balance</div></th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {
                                                    isCallingApi &&

                                                    <tr>
                                                        <td>

                                                            <div className="Header-searching-text-container">

                                                                <div class="spinner-border" role="status">
                                                                </div>

                                                                <label className="ms-4">Searching ....</label>

                                                            </div>
                                                        </td>
                                                    </tr>
                                                }

                                                {
                                                    arrayOfSearchResults != null ?

                                                        arrayOfSearchResults.length == 0 ?

                                                            <tr>
                                                                <td>
                                                                    <div className="Header-searching-text-container">
                                                                        <label> No results found</label>
                                                                    </div>
                                                                </td>
                                                            </tr>

                                                            :

                                                            arrayOfSearchResults.map((item, index) => {
                                                                return (
                                                                    <tr className="Header-company-search-result-list-single-row" onClick={() => clickedOnHeaderSearchBoxListItem(index)}>
                                                                        <td>
                                                                            <div className="Header-company-search-result-list-single-item">{item.accdesc}</div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="Header-company-search-result-list-single-item">{item.category}</div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="Header-company-search-result-list-single-item">{item.remarks}</div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="Header-company-search-result-list-single-item">{item.amcdate}</div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="Header-company-search-result-list-single-item">{item.balance}</div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })

                                                        :
                                                        null
                                                }

                                            </tbody>

                                        </table>
                                    </div>
                                    // end of showhide list
                                }

                            </div>

                        </div>

                        <div className="ViewReports-datecontainer mt-4">
                            <div>
                                <label>From</label>
                                <PickDate datePickerFromParent={datePickerFromParent} keyName={"date_from"} />
                            </div>
                            <div>
                                <label>To</label>
                                <PickDate datePickerFromParent={datePickerFromParent} keyName={"date_to"} />
                            </div>
                        </div>
                        <div className="mt-4">
                            <button className="ViewReports-view-button" onClick={getAllIssuesByACustomer}>View</button>
                        </div>
                    </div>



                    {
                        selectedCompanyFromSearch != null ?
                            <div className="ViewReports-company-details-after-selection mt-2">
                                <div>
                                    <label>Name</label>
                                    <p>{selectedCompanyFromSearch.accdesc}</p>
                                </div>
                                <div>
                                    <label>Code</label>
                                    <p>{selectedCompanyFromSearch.cmpcode}</p>
                                </div>
                                <div>
                                    <label>Account</label>
                                    <p>{selectedCompanyFromSearch.account}</p>
                                </div>
                                <div>
                                    <label>Category</label>
                                    <p>{selectedCompanyFromSearch.category}</p>
                                </div>
                                <div>
                                    <label>Remarks</label>
                                    <p>{selectedCompanyFromSearch.remarks}</p>
                                </div>
                                <div>
                                    <label>AMC Date</label>
                                    <p>

                                        {new Date(selectedCompanyFromSearch.amcdate).getDate() +
                                            " / " + (new Date(selectedCompanyFromSearch.amcdate).getMonth() + 1) +
                                            " / " + (new Date(selectedCompanyFromSearch.amcdate).getFullYear())
                                        }
                                    </p>
                                </div>
                                <div>
                                    <label>Balance</label>
                                    <p>{selectedCompanyFromSearch.balance}</p>
                                </div>


                            </div>
                            : null
                    }

                    <div className="mt-4">
                        <button className="Download-excel-button " onClick={downloadToExcel}> <img className="Download-excel-button-img" src={ic_excel_download} /> Download As Excel</button>

                    </div>

                    {

                        <div className="ViewReports-table-container mt-4 mb-4">
                            <table className="TicketSingleCard-table ViewReports-table ">

                                <thead>
                                    <tr>

                                        <th><div>Sl. No.</div></th>

                                        <th><div>Description</div></th>
                                        <th><div>Company Name</div></th>
                                        <th><div>Mobile Number</div></th>
                                        <th><div>Created On</div></th>
                                        <th><div>Support Agent</div></th>
                                        <th><div>Remarks</div></th>
                                        <th><div>Current Status</div></th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        allIssuesByACompanyState != null ?
                                            allIssuesByACompanyState.map((item, index) => {
                                                return (

                                                    <tr key={item.id} className={item.ticket_remarks == "ticket from customer" ? "TicketSingleCard-list-container-single-item-direct-from-customer" : "TicketSingleCard-list-container-single-item"} >


                                                        <td className="Ticket-number-total-border">
                                                            <div >

                                                                <label className="TicketSingleCard-ticket-number-text">
                                                                    {index + 1}
                                                                </label>

                                                            </div>
                                                        </td>


                                                        <td>
                                                            <div className="TicketSingleCard-table-description-column">
                                                                <p title={item.ticket_description} className="from-customer-value-text mt-3">
                                                                    {item.ticket_description}
                                                                </p>
                                                            </div>
                                                        </td>

                                                        {/* actually we get company id and save it to company name , may be we can call intermediate API by abhilash sir
            to get original id and name of company then return it to front end ie to here,*/}
                                                        <td>
                                                            <div className="TicketSingleCard-table-company-name-column">
                                                                <p title={item.company_name} className="from-customer-value-text  mt-3">

                                                                    {item.company_name}

                                                                </p>
                                                            </div>
                                                        </td>

                                                        <td>
                                                            <div >
                                                                <p className="from-customer-value-text  mt-3">

                                                                    {item.mobile_number}

                                                                </p>
                                                            </div>
                                                        </td>

                                                        <td>
                                                            <div>

                                                                <p className="from-customer-value-text  mt-3">
                                                                    {new Date(item.ticket_created_on).getDate() +
                                                                        " / " + (new Date(item.ticket_created_on).getMonth() + 1) +
                                                                        " / " + (new Date(item.ticket_created_on).getFullYear())
                                                                    }</p>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div >
                                                                <p className="from-customer-value-text  mt-3">

                                                                    {item.staff_name}

                                                                </p>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div >
                                                                <p className="from-customer-value-text  mt-3">

                                                                    {item.remarks}

                                                                </p>
                                                            </div>
                                                        </td>
                                                        <td >
                                                            <div className="from-customer-value-text mt-3">
                                                                <p>{item.completion_status_name}</p>
                                                            </div>
                                                        </td>



                                                    </tr>
                                                )
                                            })
                                            :
                                            null
                                    }




                                </tbody>
                            </table>
                        </div>
                    }


                </div>

            </div>

            {
                emptyCustomerErrorPopupShowHideState &&

                <div className="ViewReports-popup-outer-container">
                    <div className="ViewReports-popup-inner-container">
                        <label className="ViewReports-empty-error">{emptyCustomerError}</label>
                        <button className="ViewReports-view-button mt-4" onClick={() => setEmptyCustomerErrorPopupShowHideState(prev => !prev)}>OK</button>
                    </div>
                </div>
            }

        </div>
    )
}

export default ViewReports;