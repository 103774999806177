import React, { useState, useEffect } from "react";
import Header from "./Header";
import "../css/AllTasks.css";
import ic_daily_task from "../img/ic_daily_task.png";
import DailyTaskStaffCard from "./DailyTaskStaffCard";
import axios from "axios";
import LoadingUI from "./LoadingUI";

function AllTasks() {

    const [showClosedItemLoadingUiState, setShowClosedItemLoadingUiState] = useState(false);
    const [showLoadingUiState, setShowLoadingUiState] = useState(false);
    const [cubixTaskTicketCompanyNameArrayState, setCubixTaskTicketCompanyNameArray] = useState(null);
    const [cubixTaskTicketStaffNameArrayState, setCubixTaskTicketStaffNameArray] = useState(null);
    const [closedTaskArrayState, setClosedTaskArrayState] = useState(null);
    const [errorMessage, setErrorMessageState] = useState("");


    const getAllClosedTasks = () => {

        setShowClosedItemLoadingUiState(true);

        axios.get(`${process.env.REACT_APP_BASE_URL}/get-all-closed-task.php`).
            then((res) => {

                setShowClosedItemLoadingUiState(false);
                console.log(" all tasks res is ", res);
                if (res.data.status == "get_closed_tasks_success") {
                    setClosedTaskArrayState(res.data.closed_tasks_array);

                } else if (res.data.status == "get_closed_tasks_failure") {
                    setErrorMessageState(res.data.failure_reason);
                }
            }).
            catch((err) => {

                setShowClosedItemLoadingUiState(false);
                console.log("err is ", err);
            })
    }

    const getFromLocalStorageCompanyNameArray = () => {
        let cubixTaskTicketCompanyNameArray = sessionStorage.getItem("cubix_task_ticket_company_name_array");
        setCubixTaskTicketCompanyNameArray(JSON.parse(cubixTaskTicketCompanyNameArray))
    }

    const getFromLocalStorageStaffArray = () => {
        let cubixTaskTicketStaffNameArray = sessionStorage.getItem("cubix_task_ticket_staff_name_array");
        setCubixTaskTicketStaffNameArray(JSON.parse(cubixTaskTicketStaffNameArray))
    }

    useEffect(() => {

        getAllClosedTasks();
        getFromLocalStorageStaffArray();
        getFromLocalStorageCompanyNameArray();
    }, [])

    return (
        <div className="AllTasks-root-container">
            <Header />
            <div className="AllTasks-root-container-top-panel">
                <img src={ic_daily_task} />
                <h4 className="mt-2">Closed Tasks</h4>
            </div>

            <div className="AllTasks-closed-container">

                {showClosedItemLoadingUiState && <LoadingUI />}

                <div className="AllTasks-right-child-list-container">

                    {/* single child  */}
                    {closedTaskArrayState != null ?

                        closedTaskArrayState.length > 0 ?

                            closedTaskArrayState.map((item) => {

                                return (

                                    <div className="AllTasks-right-child-list-single-item mt-2">

                                        <div className="AllTasks-right-child-list-single-item-date-mark-container">
                                            <label className="AllTasks-task-number-bg">
                                                Task {item.id}
                                            </label>
                                        </div>

                                        <div className="AllTasks-right-child-list-single-item-full-description-container">

                                            <label>
                                                Task Description
                                            </label>
                                            <p>
                                                {item.task_description}
                                            </p>

                                            <label>
                                                Company name
                                            </label>
                                            <p>
                                                {
                                                    cubixTaskTicketCompanyNameArrayState != null ?
                                                        cubixTaskTicketCompanyNameArrayState.length > 0 ?
                                                            cubixTaskTicketCompanyNameArrayState.filter((array_item) => {
                                                                return (array_item.id == item.company_name);
                                                            }).length > 0 ?
                                                                cubixTaskTicketCompanyNameArrayState.filter((array_item) => {
                                                                    return (array_item.id == item.company_name);
                                                                })[0].company_name
                                                                :
                                                                "--xx--"
                                                            :
                                                            "-----"
                                                        :
                                                        "--"
                                                }
                                            </p>

                                            <label>
                                                Task Remarks
                                            </label>
                                            <p>
                                                {item.task_remarks.length > 0 ? item.task_remarks : "--xx--"}
                                            </p>

                                            <div className="AllTasks-right-child-list-single-assign-details">
                                                {/* <div>
                                                    <label>
                                                        Assigned To
                                                    </label>
                                                    <p>
                                                        {
                                                            cubixTaskTicketStaffNameArrayState != null ?
                                                                cubixTaskTicketStaffNameArrayState.length > 0 ?
                                                                    cubixTaskTicketStaffNameArrayState.filter((array_item) => {
                                                                        return (array_item.id == item.assign_to);
                                                                    }).length > 0 ?
                                                                        cubixTaskTicketStaffNameArrayState.filter((array_item) => {
                                                                            return (array_item.id == item.assign_to);
                                                                        })[0].staff_name
                                                                        :
                                                                        "ALL"
                                                                    :
                                                                    "-----"
                                                                :
                                                                "--"
                                                        }
                                                    </p>
                                                </div> */}
                                                <div>
                                                    <label>
                                                        Created By
                                                    </label>
                                                    <p>
                                                        {
                                                            cubixTaskTicketStaffNameArrayState != null ?
                                                                cubixTaskTicketStaffNameArrayState.length > 0 ?
                                                                    cubixTaskTicketStaffNameArrayState.filter((array_item) => {
                                                                        return (array_item.id == item.assigned_by);
                                                                    }).length > 0 ?
                                                                        cubixTaskTicketStaffNameArrayState.filter((array_item) => {
                                                                            return (array_item.id == item.assigned_by);
                                                                        })[0].staff_name
                                                                        :
                                                                        "--xx--"
                                                                    :
                                                                    "-----"
                                                                :
                                                                "--"
                                                        }
                                                    </p>
                                                </div>
                                                <div>
                                                    <label>
                                                        Created On
                                                    </label>
                                                    <p>
                                                        {new Date(item.task_created_on).getDate() +
                                                            " / " + (new Date(item.task_created_on).getMonth() + 1) +
                                                            " / " + (new Date(item.task_created_on).getFullYear())
                                                        }
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="AllTasks-right-child-list-single-time-details">

                                                <div>
                                                    <label>
                                                        Task Status Changed On
                                                    </label>
                                                    <p>

                                                        {new Date(item.task_status_changed_on).getDate() +
                                                            " / " + (new Date(item.task_status_changed_on).getMonth() + 1) +
                                                            " / " + (new Date(item.task_status_changed_on).getFullYear())
                                                        }
                                                    </p>
                                                </div>

                                                <div>
                                                <label>
                                                    Task Status Changed by
                                                </label>
                                                <p>
                                                    {
                                                        cubixTaskTicketStaffNameArrayState != null ?
                                                            cubixTaskTicketStaffNameArrayState.length > 0 ?
                                                                cubixTaskTicketStaffNameArrayState.filter((array_item) => {
                                                                    return (array_item.id == item.task_status_changed_by);
                                                                }).length > 0 ?
                                                                    cubixTaskTicketStaffNameArrayState.filter((array_item) => {
                                                                        return (array_item.id == item.task_status_changed_by);
                                                                    })[0].staff_name
                                                                    :
                                                                    "--xx--"
                                                                :
                                                                "-----"
                                                            :
                                                            "--"
                                                    }
                                                </p>
                                            </div>

                                            </div>

                                            
                                        </div>
                                    </div>
                                )
                            })
                            :
                            <label className="ms-4 text-white">No Data to show</label>
                        :
                        null
                    }
                    {/* single child */}


                </div>

            </div>
        </div>
    )
}

export default AllTasks;