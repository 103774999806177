import React, { useState, useEffect, useRef } from "react";
import "../css/CreateTicketPopupContainer.css";
import ic_calendar from "../img/ic_calendar.png";
import ic_time from "../img/ic_time.png";
import ic_ticket from "../img/ic_ticket.png";
import axios from "axios";
import LoadingLayout from "./LoadingLayout";

function CreateTicketPopupContainer(props) {

    const [showLoadingLayout, setShowLoadingLayout] = useState(false);
    let timeInterval = null;
    let currentTextInSearchField = useRef("");
    let copyOfAllCompanyList = useRef([]);
    const [errorMessageState, setErrorMessageState] = useState("");
    const [showCompanyNameDropDown, setShowCompanyNameDropDown] = useState(false);
    const [showTicketTypeDropDown, setShowTicketTypeDropDown] = useState(false);
    const [showAssignToDropDown, setShowAssignToDropDown] = useState(false);
    const [showAssignedByDropDown, setShowAssignedByDropDown] = useState(false);
    const [cubixTaskTicketStaffNameArrayState, setCubixTaskTicketStaffNameArray] = useState(null);
    const [cubixTaskTicketCompanyNameArrayState, setCubixTaskTicketCompanyNameArray] = useState(null);
    const [cubixTaskTicketTicketTypeArrayState, setCubixTaskTicketTicketTypeArray] = useState(null);

    const [companySearchTextState, setCompanySearchTextState] = useState("");
    const [startCompanyNameFiltering, setStartCompanyNameFiltering] = useState(false);

    const { showHideCreateNewTicketPopup } = props;

    const initialInputState = {
        input_ticket_description: "",
        input_ticket_remarks: "",
        input_ticket_type: 0,
        input_ticket_company_name: 0,
        input_ticket_company_full_name: "",
        input_ticket_assigned_to: "",
        input_ticket_assigned_by: "",
        input_ticket_should_be_completed_on: "",
        input_ticket_completion_status: 1,
    }
    const [ticketInputState, setTicketInputState] = useState(initialInputState);

    const handleInput = (e) => {
        setErrorMessageState("");
        setTicketInputState({ ...ticketInputState, [e.target.name]: e.target.value });
    }

    const handleCompanySearchInput = (e) => {

        console.log(" searchtext company changed is", e.target.value);

        setCompanySearchTextState(e.target.value);
        currentTextInSearchField.current = e.target.value;

        clearInterval(timeInterval);

        timeInterval = setTimeout(() => {
            if (e.target.value.length > 0) {
                if (timeInterval) {
                    console.log("e.target.value.length  > 0timer is not null we are clearing it")
                    clearInterval(timeInterval);
                }
                setStartCompanyNameFiltering(true);
            } else {
                if (timeInterval) {
                    console.log("e.target.value.length == 0 timer is not null we are clearing it")
                    clearInterval(timeInterval);
                }
                setStartCompanyNameFiltering(false);
                setCubixTaskTicketCompanyNameArray(copyOfAllCompanyList.current);
            }
        }, 1000)
    }

    const sendDataToApi = (ticket_status_from_front_end) => {

        console.log("ticketInputState ", { inputdata: ticketInputState, ticketStatusFromFrontEnd: ticket_status_from_front_end });

        if (ticketInputState.input_ticket_description.length > 0 && ticketInputState.input_ticket_assigned_by > 0) {

            setShowLoadingLayout(true);

            axios.post(`${process.env.REACT_APP_BASE_URL}/create-ticket.php`, { inputdata: ticketInputState, ticketStatusFromFrontEnd: ticket_status_from_front_end }).
                then((res) => {
                    console.log(" task creation res is ", res);
                    setShowLoadingLayout(false);

                    if (res.data.status = "ticket_creation_success") {

                        window.location.reload();
                        showHideCreateNewTicketPopup();
                    }
                }).
                catch((err) => {
                    setShowLoadingLayout(false);
                    console.log("err is ", err);
                })

        } else {
            setErrorMessageState("Either Description or assigned by not filled")
        }

    }

    const getFromLocalStorageStaffArray = () => {
        let cubixTaskTicketStaffNameArray = sessionStorage.getItem("cubix_task_ticket_staff_name_array");
        setCubixTaskTicketStaffNameArray(JSON.parse(cubixTaskTicketStaffNameArray))
    }

    const getFromLocalStorageTicketTypeArray = () => {
        let cubixTaskTicketTicketTypeArray = sessionStorage.getItem("cubix_task_ticket_type_of_ticket_array");
        setCubixTaskTicketTicketTypeArray(JSON.parse(cubixTaskTicketTicketTypeArray))
    }

    useEffect(() => {

        if (startCompanyNameFiltering) {

            setStartCompanyNameFiltering(false);

            console.log("CreateTaskPopupContainer >>>>>>> ", "startCompanyNameFiltering true");

            if (currentTextInSearchField.current.length > 0) {
                console.log("CreateTaskPopupContainer >>>>>>> ", currentTextInSearchField.current);

                setCubixTaskTicketCompanyNameArray(
                    copyOfAllCompanyList.current.filter((item) => {
                        return item.accdesc.toLowerCase().includes(currentTextInSearchField.current.toLowerCase())
                    })
                )
            }

        } else {
            console.log("CreateTaskPopupContainer >>>>>>> ", "startCompanyNameFiltering false");
        }

    }, [startCompanyNameFiltering])

    const getAllCustomersFromCubixDb = () => {

        setShowLoadingLayout(true);

        axios.get('http://185.140.249.224:112/api/CustomerDetails').
            then((res) => {

                setShowLoadingLayout(false);

                console.log("res is ", res);

                copyOfAllCompanyList.current = res.data.jsont;

                setCubixTaskTicketCompanyNameArray(res.data.jsont)

            }).
            catch((err) => {

                setShowLoadingLayout(false);

            })

    }

    useEffect(() => {
        getFromLocalStorageStaffArray();

        getFromLocalStorageTicketTypeArray();

        getAllCustomersFromCubixDb();

    }, [])

    return (
        <div className="CreateTicketPopupContainer-root-container">

            <img className="CreateTicketPopupContainer-side-clip-image" src={ic_ticket} />
            <div className="CreateTicketPopupContainer-inner-card">
                <h6>Create New Ticket</h6>

                <label className="mt-4">Enter ticket description</label>
                <input className="mt-2" type="text" name="input_ticket_description" onChange={handleInput} />

                <label className="mt-4">Enter remarks</label>
                <input className="mt-2" type="text" name="input_ticket_remarks" onChange={handleInput} />

                <div className="CreateTaskPopupContainer-staff-dropdown-anchorand-list-container ">
                    <label className="mt-4">Select company</label>

                    <div className="FilterPopupContainer-select-staff-name-container">
                        <div className="CreateTaskPopupContainer-dropdown-picker mt-2" onClick={() => setShowCompanyNameDropDown(prev => !prev)} >

                            {
                                cubixTaskTicketCompanyNameArrayState != null ?
                                    cubixTaskTicketCompanyNameArrayState.length > 0 ?

                                        cubixTaskTicketCompanyNameArrayState.filter((item) => {
                                            return (
                                                parseInt(item.account) == ticketInputState.input_ticket_company_name
                                            )
                                        }).length > 0 ?
                                            cubixTaskTicketCompanyNameArrayState.filter((item) => {
                                                return (
                                                    parseInt(item.account) == ticketInputState.input_ticket_company_name
                                                )
                                            })[0].accdesc
                                            :
                                            null
                                        :
                                        null
                                    :
                                    null
                            }
                        </div>
                        {showCompanyNameDropDown &&
                            <div className="CreateTaskPopupContainer_staff_name_dropdown_item_container">

                                <input type="text" className="CreateTicketPopupContainer-company-search-box" placeholder="Search company name" value={companySearchTextState} onChange={handleCompanySearchInput} />

                                {
                                    cubixTaskTicketCompanyNameArrayState != null ?
                                        cubixTaskTicketCompanyNameArrayState.length > 0 ?
                                            cubixTaskTicketCompanyNameArrayState.map((item) => {
                                                return (
                                                    <span key={item.account} className="CreateTaskPopupContainer_staff_name_dropdown_item" id={item.account} onClick={() => { setTicketInputState({ ...ticketInputState, input_ticket_company_name: parseInt(item.account) , input_ticket_company_full_name: item.accdesc }); setShowCompanyNameDropDown(prev => !prev) }}>{item.accdesc}</span>
                                                )
                                            })
                                            :
                                            null
                                        :
                                        null
                                }

                            </div>
                        }
                    </div>
                </div>

                <div className="CreateTaskPopupContainer-staff-dropdown-anchorand-list-container ">
                    <label className="mt-4">Select ticket type</label>

                    <div className="FilterPopupContainer-select-staff-name-container">

                        <div className="CreateTaskPopupContainer-dropdown-picker mt-2" onClick={() => setShowTicketTypeDropDown(prev => !prev)} >

                            {
                                cubixTaskTicketTicketTypeArrayState != null ?
                                    cubixTaskTicketTicketTypeArrayState.length > 0 ?

                                        cubixTaskTicketTicketTypeArrayState.filter((item) => {
                                            return (
                                                item.id == ticketInputState.input_ticket_type
                                            )
                                        }).length > 0 ?
                                            cubixTaskTicketTicketTypeArrayState.filter((item) => {
                                                return (
                                                    item.id == ticketInputState.input_ticket_type
                                                )
                                            })[0].ticket_type_name
                                            :
                                            null
                                        :
                                        null
                                    :
                                    null
                            }
                        </div>
                        {showTicketTypeDropDown &&
                            <div className="CreateTaskPopupContainer_staff_name_dropdown_item_container">

                                {
                                    cubixTaskTicketTicketTypeArrayState != null ?
                                        cubixTaskTicketTicketTypeArrayState.length > 0 ?
                                            cubixTaskTicketTicketTypeArrayState.map((item) => {
                                                return (
                                                    <span key={item.id} className="CreateTaskPopupContainer_staff_name_dropdown_item" id={item.id} onClick={() => { setTicketInputState({ ...ticketInputState, input_ticket_type: item.id }); setShowTicketTypeDropDown(prev => !prev) }}>{item.ticket_type_name}</span>
                                                )
                                            })
                                            :
                                            null
                                        :
                                        null
                                }

                            </div>
                        }
                    </div>
                </div>

                <div className="CreateTaskPopupContainer-staff-dropdown-anchorand-list-container ">
                    <label className="mt-4">Assigned By</label>

                    <div className="FilterPopupContainer-select-staff-name-container">

                        <div className="CreateTaskPopupContainer-dropdown-picker mt-2" onClick={() => setShowAssignedByDropDown(prev => !prev)} >

                            {
                                cubixTaskTicketStaffNameArrayState != null ?
                                    cubixTaskTicketStaffNameArrayState.length > 0 ?
                                        cubixTaskTicketStaffNameArrayState.filter((item) => {
                                            return (
                                                item.id == ticketInputState.input_ticket_assigned_by
                                            )
                                        }).length > 0 ?

                                            cubixTaskTicketStaffNameArrayState.filter((item) => {
                                                return (
                                                    item.id == ticketInputState.input_ticket_assigned_by
                                                )
                                            })[0].staff_name
                                            :
                                            null
                                        :
                                        null
                                    :
                                    null
                            }
                        </div>

                        {showAssignedByDropDown &&
                            <div className="CreateTaskPopupContainer_staff_name_dropdown_item_container">
                                {
                                    cubixTaskTicketStaffNameArrayState != null ?
                                        cubixTaskTicketStaffNameArrayState.length > 0 ?
                                            cubixTaskTicketStaffNameArrayState.map((item) => {
                                                return (
                                                    <span key={item.id} className="CreateTaskPopupContainer_staff_name_dropdown_item" id={item.id} onClick={() => { setErrorMessageState(""); setTicketInputState({ ...ticketInputState, input_ticket_assigned_by: item.id }); setShowAssignedByDropDown(prev => !prev) }}>{item.staff_name}</span>
                                                )
                                            })
                                            :
                                            null
                                        :
                                        null
                                }

                            </div>
                        }
                    </div>

                </div>

                <div className="CreateTaskPopupContainer-error-message-container">
                    <p className="text-danger ">{errorMessageState}</p>
                </div>

                <div className="CreateTicketPopupContainer-cancel-save-container">
                    <button className="CreateTicketPopupContainer-cancel-button me-4" onClick={showHideCreateNewTicketPopup}>Cancel</button>
                    <button className="CreateTicketPopupContainer-completed-button me-4" onClick={() => sendDataToApi(2)}>Completed</button>
                    <button className="CreateTicketPopupContainer-save-button" onClick={() => sendDataToApi(1)}>Save</button>
                </div>

            </div>

            {
                showLoadingLayout && <LoadingLayout />
            }

        </div>
    )

}

export default CreateTicketPopupContainer;