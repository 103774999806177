import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import logo_cubix from "../img/logo_cubix.png";
import ic_attach from "../img/ic_attach_images.png";
import ic_support_person_blob_1 from "../img/ic_support_person_blob_1.png";
import ic_support_person_blob from "../img/ic_support_person_blob.png";
import ReportIssuePopup from "./ReportIssuePopup";
import ReportIssuePopupFailure from "../components/ReportIssuePopupFailure"
import axios from "axios";
import LoadingUI from "./LoadingUI";
import 'react-phone-number-input/style.css';
import "../css/ReportIssue.css";
import "../css/LoginUser.css";
import PhoneInput from 'react-phone-number-input';
import ic_high_risk from "../img/ic_high_risk.png";
import ic_review_us from "../img/ic_review_us.png";
import ic_google from "../img/ic_google.png";
import ic_block from "../img/ic_blocked.png";

function ReportIssue() {

    const [amcDate, setAmcDate] = useState();
    const [allLatestChats, setAllLatestChats] = useState(null);
    const [personNameInput, setPersonNameInput] = useState("");
    const [detailsOfCustomer, setDetailsOfCustomer] = useState(null);

    const [boxToHoldInputAndChipText, setBoxToHoldInputAndChipText] = useState("");

    const initialArrayOfIssueChipState = [{ id: 1, name: "print header", selected: false },
    { id: 2, name: "report in PDF format", selected: false }, { id: 3, name: "sales invoice", selected: false },
    { id: 4, name: "user role", selected: false }, { id: 5, name: "report in excel format", selected: false },
    { id: 6, name: "year ending", selected: false },
    { id: 7, name: "not working", selected: false }
    ];

    const [arrayOfIssueChipState, setArrayOfIssueChipState] = useState(initialArrayOfIssueChipState);

    const [allIssuesByACompanyState, setAllIssuesByACompanyState] = useState(null);

    const [mobileNumberValue, setMobileNumber] = useState()
    const [errorMsg, setErrorMsg] = useState("");
    const firstFileRef = useRef(null);
    const [loadingScreenState, setLoadingScreenState] = useState(false);
    const [file, setFile] = useState();
    const initialInput = { file: file, input_item_code: "" };
    const [inputState, setInputState] = useState(initialInput);

    let { idOfCompany, nameOfUser } = useParams();

    const [showLoadingLayout, setShowLoadingLayout] = useState(false);
    const [errorMessageState, setErrorMessageState] = useState("");

    const [showSuccessPopup, setShowSuccessPopup] = useState(false);

    const initialInputState = {
        input_ticket_description: "",
        input_ticket_remarks: "ticket from customer",
        input_ticket_type: 0,
        input_ticket_company_name: "",
        input_ticket_company_account: "",
        input_ticket_company_code: "",
        input_ticket_assigned_to: "--xx--",
        input_ticket_assigned_by: nameOfUser,
        input_ticket_should_be_completed_on: "--xx--",
        input_ticket_completion_status: 1,

    }
    const [ticketInputState, setTicketInputState] = useState(initialInputState);

    const [showReportIssuePopupState, setShowReportIssuePopupState] = useState(false);
    const [showReportFailurePopupState, setShowReportFailurePopupState] = useState(false);

    const showHideSuccessPopup = () => {
        setShowReportIssuePopupState(prev => !prev);
    }


    const showHideFailurePopup = () => {
        setShowReportFailurePopupState(prev => !prev);
    }

    const sendDataToApi = () => {

        console.log("ticketInputState on calling API ", ticketInputState);
        const ticketFormData = new FormData();

        for (let i = 0; i < firstFileRef.current.files.length; i++) {
            ticketFormData.append("file[]", firstFileRef.current.files[i]);
        }


        if (boxToHoldInputAndChipText.length) {

            if (mobileNumberValue) {

                setShowLoadingLayout(true);

                ticketFormData.append("input_ticket_description", boxToHoldInputAndChipText);
                ticketFormData.append("input_ticket_remarks", ticketInputState.input_ticket_remarks);
                ticketFormData.append("input_ticket_type", ticketInputState.input_ticket_type);
                ticketFormData.append("input_ticket_company_name", ticketInputState.input_ticket_company_name);
                ticketFormData.append("input_ticket_assigned_to", ticketInputState.input_ticket_assigned_to);
                ticketFormData.append("input_ticket_assigned_by", ticketInputState.input_ticket_assigned_by);
                ticketFormData.append("input_ticket_should_be_completed_on", ticketInputState.input_ticket_should_be_completed_on);
                ticketFormData.append("input_ticket_completion_status", ticketInputState.input_ticket_completion_status);
                ticketFormData.append("input_ticket_mobile_number", mobileNumberValue);
                ticketFormData.append("input_ticket_company_account", ticketInputState.input_ticket_company_account);
                ticketFormData.append("input_ticket_company_code", idOfCompany);
                ticketFormData.append("input_ticket_person_name", personNameInput);


                console.log("ticketFormData ", ticketFormData);


                axios({
                    method: "post",
                    url: `${process.env.REACT_APP_BASE_URL}/report-issue-by-client.php`,
                    data: ticketFormData,
                    headers: { "Content-Type": "multipart/form-data" }
                }).
                    then((res) => {
                        console.log(" task creation res is ", res);
                        setShowLoadingLayout(false);

                        if (res.data.status == "ticket_creation_success") {
                            showHideSuccessPopup();
                            setTicketInputState(initialInputState);
                            setMobileNumber("");
                        } else {
                            showHideFailurePopup();
                        }
                    }).
                    catch((err) => {
                        setShowLoadingLayout(false);
                        showHideFailurePopup();
                        console.log("err is ", err);
                    })

            } else {
                setErrorMessageState("please enter mobile number")
            }

        } else {
            setErrorMessageState("please enter your issue")
        }

    }

    const validateInputFields = () => {

        if (file == null) {
            return false;
        } else {
            return true;
        }
    }

    const onFileSelected = (e) => {
        setErrorMsg("");
        setFile(e.target.files);
    }


    const handleInput = (e) => {
        setErrorMessageState("");
        setTicketInputState({ ...ticketInputState, [e.target.name]: e.target.value });

        setBoxToHoldInputAndChipText(e.target.value);
    }

    const handlePersonNameInput = (e) => {
        setPersonNameInput(e.target.value);
    }

    const getAllIssuesByACustomer = (account_number) => {

        if (account_number == "0") {

            setAllIssuesByACompanyState([])

        } else {
            axios.get(`${process.env.REACT_APP_BASE_URL}/get-all-issues-by-a-company.php?id=${idOfCompany}`).then((res) => {

                console.log("res is issues by a customer ", res);

                setAllIssuesByACompanyState(res.data.opened_tickets_array)

            }).catch((err) => {
                console.log("err is ", err);
            })

            axios.get(`${process.env.REACT_APP_BASE_URL}/get-latest-chat-between-support-and-customer.php?id=${idOfCompany}`).then((res) => {

                console.log("res is latest chat ", res);

                setAllLatestChats(res.data.latest_chat_array)

            }).catch((err) => {
                console.log("err is ", err);
            })
        }


    }

    const handleChipClick = (idOFClickedChip) => {

        console.log("clicked on ", idOFClickedChip)

        let csvTextAfterClick = "";

        let mappedArrayAfterChipClick = arrayOfIssueChipState.map((item) => {
            if (item.id == idOFClickedChip) {

                if (!item.selected) {
                    csvTextAfterClick = csvTextAfterClick + item.name + " , "
                }

                return { ...item, selected: !item.selected }
            } else {
                if (item.selected) {
                    csvTextAfterClick = csvTextAfterClick + item.name + " , "
                }
                return item;
            }
        })

        console.log("mappedArrayAfterChipClick is ", mappedArrayAfterChipClick);

        setArrayOfIssueChipState(mappedArrayAfterChipClick);

        // append text to text box
        //setTicketInputState({ ...ticketInputState, input_ticket_description: ticketInputState.input_ticket_description + csvTextAfterClick })

        console.log("csvTextAfterClick is ", csvTextAfterClick);
        setBoxToHoldInputAndChipText(ticketInputState.input_ticket_description + csvTextAfterClick);

    }

    const getDetailsOfCustomer = () => {

        console.log("idOfCompany is ", idOfCompany)

        axios.get(`https://cubixweberp.com:200/api/CustomerDetails/CUST_TIC/${idOfCompany}/${nameOfUser}`).then((res) => {

            console.log("res details of customer ", res);

            if (res.status != 204) {
                setDetailsOfCustomer(res.data)
                // next step using company code 
                getAllIssuesByACustomer(res.data[0].account);
                setAmcDate(res.data[0].amcdate)
            } else {
                getAllIssuesByACustomer("0");
            }


        }).catch((err) => {
            console.log("err is ", err);
        })

    }

    useEffect(() => {

        if (detailsOfCustomer != null) {
            setTicketInputState({
                ...ticketInputState,
                input_ticket_company_name: detailsOfCustomer[0].accdesc,
                input_ticket_company_account: detailsOfCustomer[0].account,
                input_ticket_company_code: detailsOfCustomer[0].cmpcode,
            });
        }


    }, [detailsOfCustomer])

    useEffect(() => {

        getDetailsOfCustomer();

    }, [])

    const monthDiff = (d1, d2) => {

        console.log("d1 ", d1, "d2 ", d2)
        let months;
        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth();
        months += d2.getMonth();
        return months <= 0 ? 0 : months;
    }

    return (
        <div className="ReportIssue-root-container">

            <div className="ReportIssue-main-container">

                <div className="ReportIssue-bottom-layer">
                    <img className="ReportIssue-bottom-layer-img-1" src={ic_support_person_blob_1} />
                    <img className="ReportIssue-bottom-layer-img" src={ic_support_person_blob} />
                </div>

                <div className="ReportIssue-top-layer">


                    <img src={logo_cubix} className="ReportIssue-top-layer-logo" />

                    <div className="ReportIssue-top-layer-heading mt-0">
                        <h1 className="ReportIssue-top-layer-heading-h1">Cubix Support Desk</h1>
                        <p className="text-center">Support portal for CUBIX ERP users.</p>
                    </div>
                    <div className="ReportIssue-top-layer-heading-underline">

                    </div>


                    {/* {
                        amcDate != null ?
                            monthDiff(new Date(amcDate), new Date()) > 0 ?
                                <div className="blocked-customer-view container mt-2">
                                    <img src={ic_block} />
                                    <h6>Support expired</h6>
                                </div>
                                :
                                <p>ticket creation and listing will come her</p>
                            :
                            null
                    } */}

                    <div className="supported-cutomer-div">
                        <div className="ReportIssue-top-layer-form mt-4">

                            <div className="m-2">
                                <div className="ReportIssue-alerts-container-bg google-review-container" >
                                    <img src={ic_review_us} />
                                    <label className="ms-3">Hi got a moment, Review us on Google</label>
                                    <a className="ReportIssue-review-button ms-4" href="https://search.google.com/local/writereview?placeid=ChIJ-cm5rjlcXz4RwYKill8MQOU" target="_blank"><img src={ic_google} className="ReportIssue-google-icon me-2" /> Review</a>
                                </div>
                            </div>

                            <span className="mt-2 ReportIssue-top-layer-form-label-wrapper">
                                <label>Select the keywords </label>
                            </span>

                            <div className="ReportIssue-chip-container mt-2 mb-1">

                                {arrayOfIssueChipState.map((item) => {
                                    return (
                                        <label className="ReportIssue-usual-issue-chip-bg" onClick={() => handleChipClick(item.id)}>
                                            {
                                                item.selected ?
                                                    <span className="ReportIssue_selected_chip_bg me-2">x</span>
                                                    :
                                                    null
                                            }

                                            {item.name}
                                        </label>
                                    )
                                })}

                            </div>

                            <span className="mt-2 ReportIssue-top-layer-form-input-wrapper">
                                <textarea placeholder="Or type the issue in this box" maxLength={2000} type="text" value={boxToHoldInputAndChipText} name="input_ticket_description" onChange={(e) => handleInput(e)} >
                                </textarea>
                            </span>
                            <label className="ReportIssue-max-limit-text">Max Character limit : 2000</label>

                            <span className="mt-4 ReportIssue-top-layer-form-label-wrapper">
                                <label>Enter Your Name</label>
                                <div>
                                    <input name="ticket_person_name" type="text" onChange={(e) => handlePersonNameInput(e)} />
                                </div>
                            </span>

                            <span className="mt-4 ReportIssue-top-layer-form-label-wrapper">

                                <label className="mb-2">Enter your mobile number</label>
                                {/* <input type="text" className="ms-4 mobile-number-input" onChange={(e) => handleInput(e)} /> */}
                                <PhoneInput
                                    defaultCountry="AE"
                                    value={mobileNumberValue}
                                    onChange={(e) => {
                                        setMobileNumber(e);
                                        setErrorMessageState("");
                                    }
                                    } />
                            </span>

                            <span className="attach-images-container mt-4"><img src={ic_attach} className="image-attach" /> <label>Attach files if required (Maximum 2)</label> </span>
                            <form className="form-container mt-2">
                                <input type="file" id="customFile" onChange={onFileSelected} ref={firstFileRef} multiple></input>
                            </form>
                            <button className="my-4" onClick={sendDataToApi}>
                                Send
                            </button>

                            {
                                <p className="ReportIssue-error-text-before-sending">
                                    {errorMessageState}
                                </p>
                            }

                        </div>

                        <div className="ReportIssue-top-layer-form Previous-Tickets-Container mt-0 pt-4">

                            <div className="m-2">
                                <div className="ReportIssue-alerts-container-bg">
                                    <img src={ic_high_risk} /> <label className="ms-3">Backups are important. Copy (back up) your data regularly in case something happens to the original - do backup your computer and data frequently.</label>
                                </div>
                            </div>

                            <h4 className="mt-4">Pending Tickets</h4>

                            <a className="ReportIssue-view-closed-tickets-link" href={`/view_closed_tickets/${idOfCompany}`}>Closed Tickets</a>

                            {allIssuesByACompanyState != null ?

                                allIssuesByACompanyState.length != 0 ?
                                    allIssuesByACompanyState.map((item) => {
                                        return (
                                            < div className="ReportIssue-previous-list-single-item">
                                                <div className="ReportIssue-single-item-left-child">

                                                    <div className="mt-2">
                                                        <a className="ReportIssue-single-item-view-details-link" href={`/view_details_of_issue/${item.id}`}>View Details</a>
                                                    </div>

                                                    <div className="ReportIssue-single-item-left-child-top-panel mt-3">
                                                        <div className="ReportIssue-single-item-left-child-top-panel-child">
                                                            <label className="Previous-Tickets-Container-label">Ticket Number</label>
                                                            <label className="Previous-Tickets-Container-value">{item.id}</label>
                                                        </div>
                                                        <div className="ReportIssue-single-item-left-child-top-panel-child">
                                                            <label className="Previous-Tickets-Container-label">Mobile Number</label>
                                                            <label className="Previous-Tickets-Container-value">{item.mobile_number}</label>
                                                        </div>
                                                        <div className="ReportIssue-single-item-left-child-top-panel-child">
                                                            <label className="Previous-Tickets-Container-label">Created On</label>
                                                            <label className="Previous-Tickets-Container-value">
                                                                {new Date(item.ticket_created_on).getDate() +
                                                                    " / " + (new Date(item.ticket_created_on).getMonth() + 1) +
                                                                    " / " + (new Date(item.ticket_created_on).getFullYear()) +
                                                                    " -- " + (new Date(item.ticket_created_on).getHours()) +
                                                                    " : " + (new Date(item.ticket_created_on).getMinutes())
                                                                }

                                                            </label>
                                                        </div>

                                                        <div className="ReportIssue-single-item-left-child-top-panel-child">
                                                            <label className="Previous-Tickets-Container-label">Current Status</label>
                                                            <label className="Previous-Tickets-Container-value">

                                                                {item.ticket_current_status == 1 ? <label>OPENED</label> : ""}
                                                                {item.ticket_current_status == 2 ? <label>CLOSED</label> : ""}
                                                                {item.ticket_current_status == 3 ? <label>ASSIGNED</label> : ""}
                                                                {item.ticket_current_status == 4 ? <label>ESCALATED</label> : ""}
                                                            </label>
                                                        </div>

                                                    </div>

                                                    <div className="mt-2 ReportIssue-ticket-person-name">
                                                        <label className="Previous-Tickets-Container-label">Created By</label><label className="Previous-Tickets-Container-value">{item.ticket_person_name}</label>
                                                    </div>

                                                    <div className="ReportIssue-single-item-left-child-bottom-panel mt-4">
                                                        <label className="Previous-Tickets-Container-label"><span className="ReportIssue-single-item-chatbox-ticket-description">Ticket Description</span></label>
                                                        <label className="Previous-Tickets-Container-value">{item.ticket_description}</label>
                                                    </div>
                                                    {item.remarks != null ?
                                                        item.remarks.length > 0 ?
                                                            <div className="ReportIssue-single-item-left-child-bottom-panel mt-4">
                                                                <label className="Previous-Tickets-Container-label"><span className="ReportIssue-single-item-chatbox-label-support-admin">Support Agent Remarks</span></label>
                                                                <label className="Previous-Tickets-Container-value">{item.remarks}</label>
                                                            </div>
                                                            :
                                                            null
                                                        :
                                                        null
                                                    }

                                                    {

                                                        allLatestChats != null ?
                                                            allLatestChats.filter((itemChat) => {
                                                                return item.id == itemChat.ticket_id_master
                                                            }).length > 0 ?
                                                                <div className="mt-2">
                                                                    <p className="ReportIssue-single-item-chatbox-label">Latest chat</p>
                                                                    <div className="ReportIssue-single-item-chatbox-mini-and-reply">
                                                                        <div className="ReportIssue-single-item-chatbox-mini-box">

                                                                            {
                                                                                allLatestChats.filter((itemChat) => {
                                                                                    return item.id == itemChat.ticket_id_master && itemChat.message_send_by == "support_agent"
                                                                                }).map((itemChatFilter) => {
                                                                                    return (

                                                                                        <div className="bubble-left">
                                                                                            <div className="bubble-left-inner-container">{itemChatFilter.chat_message}</div>
                                                                                        </div>

                                                                                    )
                                                                                })
                                                                            }


                                                                            {
                                                                                allLatestChats.filter((itemChat) => {
                                                                                    return item.id == itemChat.ticket_id_master && itemChat.message_send_by == "customer"
                                                                                }).map((itemChatFilter) => {
                                                                                    return (
                                                                                        <div className="bubble-right-outer">
                                                                                            <div className="bubble-right">
                                                                                                <div className="bubble-right-inner-container">
                                                                                                    <div>
                                                                                                        {itemChatFilter.chat_message}
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                        <div>
                                                                            <button className="ReportIssue-chat-reply-bg" onClick={() => window.open(`/view_details_of_issue/${item.id}/reply`, "_self")}>Reply</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                null

                                                            :
                                                            null
                                                    }

                                                </div>
                                            </div>
                                        )
                                    })
                                    :
                                    <p className="mt-4">No Tickets To Show... </p>

                                :
                                <p className="mt-4">No Data To Show </p>

                            }


                        </div>
                    </div>

                </div>

            </div>



            {
                showReportIssuePopupState &&

                <ReportIssuePopup showHideSuccessPopup={showHideSuccessPopup} />
            }

            {
                showReportFailurePopupState &&

                <ReportIssuePopupFailure showHideFailurePopup={showHideFailurePopup} />
            }

            {showLoadingLayout && <LoadingUI />}


        </div >
    )
}

export default ReportIssue;