import React, { useState, useRef } from "react";
import ic_check_mark from "../img/ic_checkmark_complete.png";
import "../css/TicketSingleCard.css";
import CompletionPopup from "./CompletionPopup";
import { Link } from "react-router-dom";
import ic_view_details from "../img/ic_view_details.png";
import ic_change_status from "../img/ic_edit_status.png";

function TicketSingleCardSupport(props) {

    const { openedTicketFromCustomerArrayState, cubixTaskTicketStaffNameArrayState,
        cubixTaskTicketCompanyNameArrayState,
        cubixTaskTicketTicketTypeArrayState } = props;

    const [showCompletionPopup, setShowCompletionPopup] = useState(false);

    const [showCompletionPopupFromCustomer, setShowCompletionPopupFromCustomer] = useState(false);

    const initialCompletionDetailsRef = { "completion_type": "", "completion_number": 0 }
    const completionDetailsRef = useRef(initialCompletionDetailsRef);

    const showHideCompletionPopup = () => {
        setShowCompletionPopup(prev => !prev);
    }


    const showHideCompletionPopupFromCustomer = () => {
        setShowCompletionPopupFromCustomer(prev => !prev);
    }

    const markAsComplete = (completionType, completionNumber) => {
        completionDetailsRef.current.completion_type = completionType;
        completionDetailsRef.current.completion_number = completionNumber;

        console.log(" completionDetailsRef details ", completionDetailsRef.current.completion_type, completionDetailsRef.current.completion_number);

        setShowCompletionPopup(true);
    }

    const markAsCompleteFromCustomer = (completionType, completionNumber) => {
        completionDetailsRef.current.completion_type = completionType;
        completionDetailsRef.current.completion_number = completionNumber;

        console.log(" completionDetailsRef details ", completionDetailsRef.current.completion_type, completionDetailsRef.current.completion_number);

        setShowCompletionPopupFromCustomer(true);
    }

    const switchCase = (databaseId) => {

        switch (databaseId) {
            case 1: return "TicketSingleCard-opened-bg";

            case 3: return "TicketSingleCard-assigned-bg";

            case 4: return "TicketSingleCard-escalated-bg";

            default: return "";
        }



    }


    return (

        <div className="TicketSingleCard-root-container ms-3 me-3">

            {
                <table className="TicketSingleCard-table">
                    <thead>
                        <tr>
                            <th><div>Actions</div></th>
                            <th><div>Description</div></th>
                            <th><div>Company Name</div></th>
                            <th><div>Mobile Number</div></th>
                            <th><div>Created On</div></th>
                            <th><div>Status</div></th>
                        </tr>
                    </thead>
                    <tbody>

                        {
                            openedTicketFromCustomerArrayState != null ?
                                openedTicketFromCustomerArrayState.map((item, index) => {
                                    return (

                                        <tr key={item.id} className={item.ticket_remarks == "ticket from customer" ? "TicketSingleCard-list-container-single-item-direct-from-customer" : "TicketSingleCard-list-container-single-item"} >

                                            <td>
                                                <div className="Ticket-action-container">

                                                    <Link className="Ticket-Details-link" key={item.id} to={`/admindashboard/ticket-details/${item.id}`} >
                                                        <img title="View Details" className="image-ticket-action" src={ic_view_details} />
                                                    </Link>


                                                    <img title="Change Status" onClick={() => markAsCompleteFromCustomer("ticket", item.id)} className="image-ticket-action Ticket-Change-Status ms-4" src={ic_change_status} />

                                                </div>
                                            </td>

                                            <td>
                                                <div className="TicketSingleCard-table-description-column">
                                                    <p
                                                        onClick={() => window.open(`/admindashboard/ticket-details/${item.id}`)}
                                                        title={item.ticket_description} className="from-customer-value-text mt-3">
                                                        <span className="TicketSingleCard_support_ticket_id_badge me-2">{item.id}</span>
                                                        {item.ticket_description}
                                                    </p>
                                                    {item.remarks ? <p className="TicketSingleCard_support_person_name_paragraph">{item.remarks}</p> : null}
                                                </div>
                                            </td>

                                            {/* actually we get company id and save it to company name , may be we can call intermediate API by abhilash sir
                                            to get original id and name of company then return it to front end ie to here,*/}
                                            <td>
                                                <div className="TicketSingleCard-table-company-name-column">
                                                    <p title={item.company_name} className="from-customer-value-text  mt-3">

                                                        {item.company_name}
                                                        <br></br>
                                                        <span className="TicketSingleCard-company-code-chip">{item.company_code}</span>
                                                    </p>
                                                </div>
                                            </td>

                                            <td>
                                                {
                                                    item.ticket_remarks == "ticket from cubix staff" ?
                                                        <div >
                                                            <p className="from-customer-value-text  mt-3">

                                                                {item.mobile_number} <br />
                                                                by cubix staff
                                                            </p>
                                                        </div>
                                                        :
                                                        <div >
                                                            <p className="from-customer-value-text  mt-3">

                                                                {item.mobile_number} <br />
                                                                {item.ticket_assigned_by}
                                                            </p>
                                                        </div>

                                                }

                                            </td>

                                            <td>
                                                <div>

                                                    <p className="from-customer-value-text  mt-3">
                                                        {new Date(item.ticket_created_on).getDate() +
                                                            " / " + (new Date(item.ticket_created_on).getMonth() + 1) +
                                                            " / " + (new Date(item.ticket_created_on).getFullYear())
                                                        }</p>
                                                </div>
                                            </td>
                                            <td className={

                                                switchCase(item.ticket_current_status)

                                            }>
                                                <div className="from-customer-value-text mt-3">

                                                    {item.ticket_current_status == 1 ? <p className="TicketSingleCard-status-paragraph-text-color">OPENED</p> : ""}
                                                    {item.ticket_current_status == 3 ? <p className="TicketSingleCard-status-paragraph-text-color">ASSIGNED</p> : ""}
                                                    {item.ticket_current_status == 4 ? <p className="TicketSingleCard-status-paragraph-text-color">ESCALATED</p> : ""}

                                                    {item.staff_name ? <p className="TicketSingleCard_support_person_name_paragraph">{item.staff_name}</p> : null}

                                                </div>
                                            </td>



                                        </tr>
                                    )
                                })
                                :
                                null
                        }
                    </tbody>
                </table>
            }

            {
                showCompletionPopup &&
                <CompletionPopup showHideCompletionPopup={showHideCompletionPopup} completionDetails={completionDetailsRef} ticketFromCustomer={false} />
            }

            {
                showCompletionPopupFromCustomer &&
                <CompletionPopup showHideCompletionPopupFromCustomer={showHideCompletionPopupFromCustomer} completionDetails={completionDetailsRef} ticketFromCustomer={true} />
            }
        </div>
    )
}

export default TicketSingleCardSupport;